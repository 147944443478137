import { PropsWithChildren, ReactNode } from "react";

export type AccordionEntryId = string | number;

type CommonAccordionFields = {
  open?: boolean;
  title: ReactNode;
  secondaryContent?: ReactNode;
  uniqueId: AccordionEntryId;
};

type TextAccordionEntry = {
  primaryOpenContent?: undefined;
  primaryClosedContent?: undefined;
  textContent: string;
} & CommonAccordionFields;

type ComplexAccordionEntry = {
  textContent?: undefined;
  primaryOpenContent: ReactNode;
  primaryClosedContent: ReactNode;
} & CommonAccordionFields;

export type AccordionEntry = ComplexAccordionEntry | TextAccordionEntry;

export type AccordionOptions = {
  allowMultipleOpen?: boolean;
  hrBetweenEntries?: boolean;
  openClassName?: string;
  closedClassName?: string;
  className?: string;
  titleWrapperClassName?: string;
};

export type AccordionProps = {
  entries: AccordionEntry[];
  htmlId: string;
} & AccordionOptions;

export type AccordionItemProps = {
  setOpen: (open: boolean) => void;
  htmlId: string;
  uniqueId?: AccordionEntryId;
} & Omit<AccordionEntry, "uniqueId"> &
  PropsWithChildren &
  AccordionOptions;

export type AccordionFilterId = string | number;
export type AccordionFilterFunction = (entry: AccordionEntry) => boolean;

export type AccordionFilter = {
  filterFunction: AccordionFilterFunction;
  id: AccordionFilterId;
  active: boolean;
};

export type AccordionFilters = Map<AccordionFilterId, AccordionFilter>;

export type AccordionValues = {
  options: AccordionOptions;
  filters: AccordionFilters;
} & Pick<AccordionProps, "entries">;

export enum AccordionActionType {
  setIsOpen,
  toggleIsOpen,
  setOptions,
  setEntries,
  addFilter,
  removeFilter,
  enableFilter,
  disableFilter,
  disableAllFilters,
}

export type AccordionAction =
  | {
      type: AccordionActionType.toggleIsOpen;
      itemId: AccordionEntryId;
    }
  | {
      type: AccordionActionType.setIsOpen;
      itemId: AccordionEntryId;
      open: boolean;
      force?: boolean;
    }
  | {
      type: AccordionActionType.setOptions;
      options: AccordionOptions;
    }
  | {
      type: AccordionActionType.setEntries;
      entries: AccordionEntry[];
    }
  | {
      type:
        | AccordionActionType.disableFilter
        | AccordionActionType.removeFilter;
      filterId: AccordionFilterId;
    }
  | {
      type: AccordionActionType.enableFilter;
      filterId: AccordionFilterId;
      disableOthers?: boolean;
    }
  | {
      type: AccordionActionType.addFilter;
      filterId: AccordionFilterId;
      filterFunction: AccordionFilterFunction;
      enable: boolean;
    }
  | { type: AccordionActionType.disableAllFilters };
