import Icon from "@ant-design/icons/lib/components/Icon";
import { FaRunning } from "@react-icons/all-files/fa/FaRunning";
import { WiCloud } from "@react-icons/all-files/wi/WiCloud";
import { WiHumidity } from "@react-icons/all-files/wi/WiHumidity";
import { WiThermometer } from "@react-icons/all-files/wi/WiThermometer";
import { Tooltip } from "antd";
import { useTranslations } from "@properate/translations";
import { ReactComponent as SetPointsSvg } from "../../floorPlanView/icons/tune.svg";
import { FloorPlanMapPinDragHandler } from "./FloorPlanMapDnD";

export function FloorPlanHeaderPinBar() {
  const t = useTranslations();

  return (
    <div className="flex gap-2">
      <Tooltip title={t("floor-plan-v2.sensor-drag-temperature")}>
        <FloorPlanMapPinDragHandler type="floor-sensor-temperature">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <WiThermometer />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-gas")}>
        <FloorPlanMapPinDragHandler type="floor-sensor-gas">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <WiCloud />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-humidity")}>
        <FloorPlanMapPinDragHandler type="floor-sensor-humidity">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <WiHumidity />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-motion")}>
        <FloorPlanMapPinDragHandler type="floor-sensor-motion">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-black bg-white border border-dashed rounded cursor-move border-border">
            <FaRunning />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
      <Tooltip title={t("floor-plan-v2.sensor-drag-set-point")}>
        <FloorPlanMapPinDragHandler type="set-point-general">
          <div className="flex items-center justify-center w-12 h-12 text-2xl text-orange-600 bg-white border border-orange-400 border-dashed rounded cursor-move">
            <Icon component={SetPointsSvg} />
          </div>
        </FloorPlanMapPinDragHandler>
      </Tooltip>
    </div>
  );
}
