import { RefObject, useEffect, useState } from "react";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;
const LEEWAY = 60;

function getModalElement() {
  const selector = ".timeseries-selection-modal.ant-modal";
  const modalElements = document.querySelectorAll(selector);
  if (modalElements.length === 0) {
    throw new Error(
      `No element with the selector "${selector}" was found! 
Are you sure this component is part of a TimeseriesSelectionModal instance?`,
    );
  }
  if (modalElements.length > 1) {
    throw new Error(
      `Multiple elements with the selector ${selector} were found! 
Opening multiple instances of TimeseriesSelectionModal is not supported`,
    );
  }
  return modalElements[0];
}

// This function is very specific and only meant to be used for the TimeseriesSelectionTable component
// This is really just a workaround for not being able to specify a percentage scroll height on AntD's Table component
// Also see the (closed, but unresolved) issue on this: https://github.com/ant-design/ant-design/issues/19705
export function useTableHeight(refTimeseriesFilter: RefObject<HTMLDivElement>) {
  const [filterHeight, setFilterHeight] = useState<number | null>(null);
  const [modalHeight, setModalHeight] = useState<number | null>(null);

  useEffect(() => {
    const modalElement = getModalElement();
    const filterElement = refTimeseriesFilter.current;
    const resizeObserver = new ResizeObserver((entries) => {
      const modalEntry = entries.find((entry) => entry.target === modalElement);
      const filterEntry = entries.find(
        (entry) => entry.target === filterElement,
      );
      if (filterEntry) {
        setFilterHeight(filterEntry.borderBoxSize[0].blockSize);
      }
      if (modalEntry) {
        setModalHeight(modalEntry.borderBoxSize[0].blockSize);
      }
    });
    if (filterElement) {
      resizeObserver.observe(refTimeseriesFilter.current);
      resizeObserver.observe(modalElement);
    }
    return () => {
      if (filterElement) {
        resizeObserver.unobserve(filterElement);
      }
      resizeObserver.unobserve(modalElement);
    };
  }, [refTimeseriesFilter, setFilterHeight, setModalHeight]);

  if (typeof filterHeight === "number" && typeof modalHeight === "number") {
    const modalElement = getModalElement();
    const modalHeader = modalElement.querySelector(".ant-modal-header")!;
    const modalFooter = modalElement.querySelector(".ant-modal-footer")!;
    const totalSpaceUsed = [modalHeader, modalFooter].reduce(
      (totalSpaceAcc, element) => {
        return totalSpaceAcc + element.getBoundingClientRect().height;
      },
      filterHeight + ESTIMATED_TABLE_HEADER_HEIGHT + LEEWAY,
    );
    return modalHeight - totalSpaceUsed;
  }

  return null;
}
