export const contactTypeInputOptions = [
  {
    value: "owner",
    max: 1,
  },
  {
    value: "property-manager",
    max: 1,
  },
  {
    value: "property-management",
    max: 1,
  },
  {
    value: "contact-person",
    max: 1,
  },
  {
    value: "operations-manager",
    max: 1,
  },
  {
    value: "caretaker",
    max: 3,
  },
  {
    value: "key-account-manager",
    max: 1,
  },
  {
    value: "technical-account-manager",
    max: 2,
  },
  {
    value: "technician-ventilation",
    max: 1,
  },
  {
    value: "technician-refrigeration",
    max: 1,
  },
  {
    value: "technician-automation",
    max: 1,
  },
  {
    value: "technician-plumbing",
    max: 1,
  },
  {
    value: "backend",
    max: 10,
  },
  {
    value: "other",
    max: 25,
  },
];
