import { Fragment, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import { useTranslations } from "@properate/translations";
import { useFloorPlan } from "./FloorPlanContext";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";

type Fields = {
  name: string;
};

export function FloorPlanHeaderTitle() {
  const t = useTranslations();
  const floorPlan = useFloorPlan();
  const floorPlanUpdate = useFloorPlanUpdate();
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm<Fields>();

  return (
    <Fragment>
      <div className="flex gap-2">
        <div>{t("floor-plan-v2.title")}</div>
        {" › "}
        <div
          className="flex gap-2 rounded-md cursor-pointer"
          onClick={() => setIsEditing(true)}
          tabIndex={0}
          role="button"
          aria-label={t("floor-plan-v2.name-edit")}
        >
          {floorPlan.name}
          <EditOutlined />
        </div>
      </div>
      <Modal
        open={isEditing}
        onCancel={() => setIsEditing(false)}
        onOk={() => form.submit()}
        confirmLoading={floorPlanUpdate.isMutating}
        title={t("floor-plan-v2.name-edit")}
      >
        <Form<Fields>
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={(fields) =>
            floorPlanUpdate.trigger(fields, {
              onSuccess() {
                setIsEditing(false);
              },
            })
          }
        >
          <Form.Item<Fields>
            label={t("floor-plan-v2.name")}
            name="name"
            initialValue={floorPlan.name}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
}
