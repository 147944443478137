import { useEffect, useState } from "react";
import { Button, Select, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons/lib";
import { useTranslations } from "@properate/translations";
import { useNavigate } from "react-router-dom";
import { SchemaTypes } from "@properate/common";
import { useSchemaByRootAssetId } from "@/services/newSchemas";
import { getSchemaTitle } from "@/pages/common/utils";

type Props = {
  buildingId: number;
  id: string;
  type: SchemaTypes;
};
type NextPreviousSchema = {
  id: string;
  name: string;
};
type DisplayNameProps = {
  data: NextPreviousSchema[];
  index: number;
};

export function NextPrevious({ buildingId, id, type }: Props) {
  const navigate = useNavigate();
  const t = useTranslations();
  const [index, setIndex] = useState(0);
  const data = useSchemaByRootAssetId(type, buildingId);

  const availableSchemas = data?.data
    ?.sort((a, b) => (b.system || "").localeCompare(a.system || ""))
    .map((schema) => ({
      id: schema.snapshotId!,
      name: getSchemaTitle(schema) || "",
    }));
  const currentSchema = availableSchemas?.find((schema) => schema.id === id);

  useEffect(() => {
    const getAndUpdateIndex = (id: string, data: NextPreviousSchema[]) => {
      const index = data.findIndex((item) => item.id === id);
      setIndex(index);
    };
    if (id && availableSchemas) {
      getAndUpdateIndex(id, availableSchemas);
    }
  }, [id, availableSchemas]);

  const next = (value: number) => {
    if (!availableSchemas) return;
    if (availableSchemas && availableSchemas.length <= value) {
      throw Error("Input value outside valid range");
    }
    const next = value + 1 <= availableSchemas?.length - 1 ? value + 1 : 0;
    navigate(`/asset/${buildingId}/${type}/${availableSchemas[next].id}/view`);
  };

  const previous = (value: number) => {
    if (!availableSchemas) return;
    if (value < 0) {
      throw Error("Input value outside valid range");
    }
    const prev = value === 0 ? availableSchemas.length - 1 : value - 1;
    navigate(`/asset/${buildingId}/${type}/${availableSchemas[prev].id}/view`);
  };

  function navigateToTargetSchema(id: string) {
    navigate(`/asset/${buildingId}/${type}/${id}/view`);
  }
  const MAX_NAME_LENGTH = 20;

  const DisplayNext = ({ data, index }: DisplayNameProps) => {
    const nextIndex = index + 1 >= data?.length ? 0 : index + 1;
    return <>{displayName(data[nextIndex]?.name)}</>;
  };

  const DisplayPrevious = ({ data, index }: DisplayNameProps) => {
    const prevIndex = index === 0 ? data?.length - 1 : index - 1;
    return <>{displayName(data[prevIndex]?.name)}</>;
  };
  const displayName = (name?: string) => {
    if (!name) return t("floor-plan.next-previous.untitled");
    return name?.length > MAX_NAME_LENGTH
      ? `${name.slice(0, MAX_NAME_LENGTH)}...`
      : name;
  };

  return availableSchemas && availableSchemas?.length > 1 ? (
    <Space direction={"horizontal"}>
      <Button type="default" onClick={() => previous(index)}>
        <LeftOutlined />
        <DisplayPrevious
          key={`${id}-prev`}
          data={availableSchemas || []}
          index={index}
        />
      </Button>
      <Select
        value={displayName(currentSchema?.name)}
        style={{ width: "150px" }}
        onSelect={navigateToTargetSchema}
        aria-label={t("floor-plan.next-previous.select-form")}
      >
        {availableSchemas &&
          availableSchemas.map((schema) => (
            <Select.Option value={schema.id} key={schema.id}>
              {displayName(schema?.name)}
            </Select.Option>
          ))}
      </Select>
      <Button type="default" onClick={() => next(index)}>
        <DisplayNext
          key={`${id}-next`}
          data={availableSchemas || []}
          index={index}
        />
        <RightOutlined />
      </Button>
    </Space>
  ) : (
    <></>
  );
}
