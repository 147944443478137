import { Timeseries } from "@cognite/sdk";
import { cogniteClient } from "@/services/cognite-client";

type AlarmAssetName =
  | "RT001-max"
  | "RT001-min"
  | "RY001-max"
  | "RY002-max"
  | "RH002-norm";

const assetNameToLabels: Record<AlarmAssetName, string[]> = {
  "RT001-max": ["temperature", "real_value"],
  "RT001-min": ["temperature", "real_value"],
  "RY001-max": ["co2", "real_value"],
  "RY002-max": ["VOC", "real_value"],
  "RH002-norm": [],
};

export interface TimeseriesWithAlarmAssetName extends Omit<Timeseries, "name"> {
  name: AlarmAssetName;
}

export async function getAlarmTimeseries(subBuildingId: number) {
  const assets = await Promise.all(
    Object.entries(assetNameToLabels).map(([assetName, labels]) =>
      cogniteClient.assets
        .list({
          filter: {
            assetSubtreeIds: [{ id: subBuildingId }],
            parentIds: [subBuildingId],
            name: assetName,
            labels:
              labels.length > 0
                ? {
                    containsAll: labels.map((label) => ({
                      externalId: label,
                    })),
                  }
                : undefined,
          },
        })
        .autoPagingToArray({ limit: -1 }),
    ),
  ).then((assets) => assets.flat());
  if (assets.length === 0) {
    return [];
  }
  return cogniteClient.timeseries
    .list({
      filter: {
        assetIds: assets.map(({ id }) => id),
      },
    })
    .autoPagingToArray({ limit: -1 }) as Promise<
    TimeseriesWithAlarmAssetName[]
  >;
}
