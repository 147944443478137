import { CloseOutlined } from "@ant-design/icons";
import { AlarmWithId } from "@properate/common";
import { Button, Popconfirm } from "antd";
import { useTranslations } from "@properate/translations";
import { useUser } from "@properate/auth";
import { useDeleteAlarm } from "../../hooks/useDeleteAlarm";

type Props = {
  alarm: AlarmWithId;
};

export function AlarmsTableActionColumn(props: Props) {
  const user = useUser();
  const t = useTranslations();
  const { trigger: deleteAlarm } = useDeleteAlarm();
  const canBeDeleted = user.isAdmin || props.alarm.owner === user.email;

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className={"w-full h-full flex justify-center items-center"}
    >
      <Popconfirm
        title={t("org-alarms.actions.delete-alarm-confirm")}
        onConfirm={() => deleteAlarm(props.alarm)}
        okText={
          <span
            data-testid={`delete-alarm-from-alarm-table-${props.alarm.description}`}
          >
            {t("org-alarms.actions.ok-text")}
          </span>
        }
        disabled={!canBeDeleted}
      >
        <Button
          type="link"
          icon={<CloseOutlined data-testid="close-confirm" />}
          disabled={!canBeDeleted}
        />
      </Popconfirm>
    </div>
  );
}
