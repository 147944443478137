import { SchemaType } from "@properate/common";
import { SchemaKpis } from "@/pages/common/SchemaKpis/SchemaKpis";
import { useFloorPlan } from "./FloorPlanContext";

export function FloorPlanHeaderKPIs() {
  const floorPlan = useFloorPlan();

  const sensors = floorPlan.pins.reduce(
    (sensors, pin) => {
      sensors[pin.timeseriesId] = {
        timeseriesInfo: [
          {
            id: pin.timeseriesId,
          },
        ],
      };

      return sensors;
    },
    {} as SchemaType["sensors"],
  );

  const schema: Pick<SchemaType, "selectedSensors" | "sensors"> = {
    selectedSensors: floorPlan.selectedSensors,
    sensors,
  };

  return (
    <SchemaKpis
      schema={schema}
      snapshotId={floorPlan.snapshotId}
      type="floor-plans"
    />
  );
}
