import { useUser } from "@properate/auth";
import { Node as ReactFlowNode, useReactFlow } from "reactflow";
import { Checkbox, Form, InputNumber, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useTranslations } from "@properate/translations";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  Node,
  NodeOutput,
  NodeInput,
  Header,
  Body,
  NodeInputLabel,
  NodeContent,
  NodeOutputLabel,
} from "./helpers/NodeComponents";

const FormItem = Form.Item;

type Aggregate = "mean" | "min" | "max" | "sum" | "integral";
type WindowSize = "W" | "D" | "H" | "T";

interface Props {
  operationId: string;
  aggregate: Aggregate;
  windowSizeMultiplier: number;
  windowSize: WindowSize;
}

export const getEmptyRollingWindowNode = (): ReactFlowNode<Props> => {
  return {
    id: getNodeId("rollingWindow"),
    type: "rollingWindow",
    data: {
      operationId: "rolling_window",
      aggregate: "mean",
      windowSizeMultiplier: 1,
      windowSize: "D",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function RollingWindowNode(params: { id: string; data: Props }) {
  const t = useTranslations();
  const user = useUser();
  const reactFlowInstance = useReactFlow();

  const handleAggregateChange = (aggregate: Aggregate) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "aggregate",
      aggregate,
    );
  };

  const handleWindowSizeMultiplierChange = (
    windowSizeMultiplier: number | null,
  ) => {
    if (windowSizeMultiplier !== null)
      updateReactFlowNodeData(
        reactFlowInstance,
        params.id,
        "windowSizeMultiplier",
        Math.sign(params.data.windowSizeMultiplier) * windowSizeMultiplier,
      );
  };

  const handleWindowSizeChange = (windowSize: WindowSize) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "windowSize",
      windowSize,
    );
  };

  const handleAggregationDirectionChange = (e: CheckboxChangeEvent) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "windowSizeMultiplier",
      (e.target.checked ? -1 : 1) * Math.abs(params.data.windowSizeMultiplier),
    );
  };

  return (
    <Node>
      <Header>
        {t("calculation-flow.node-types.aggregate-with-rolling-window")}
      </Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel />
        </NodeInput>
        <NodeContent>
          <Form layout="vertical" disabled={user.isViewer}>
            <FormItem label={t("calculation-flow.node-types.aggregate")}>
              <Select
                showAction={["focus", "click"]}
                value={params.data.aggregate}
                onChange={handleAggregateChange}
                options={[
                  {
                    value: "mean",
                    label: t("calculation-flow.aggregate-select.average"),
                  },
                  {
                    value: "max",
                    label: t("calculation-flow.aggregate-select.maximum"),
                  },
                  {
                    value: "min",
                    label: t("calculation-flow.aggregate-select.minimum"),
                  },
                  {
                    value: "sum",
                    label: t("calculation-flow.aggregate-select.sum"),
                  },
                  {
                    value: "integral",
                    label: t("calculation-flow.aggregate-select.integral"),
                  },
                ]}
              />
            </FormItem>
            <FormItem label={t("calculation-flow.node-types.window-size")}>
              <InputNumber
                value={Math.abs(params.data.windowSizeMultiplier)}
                onChange={handleWindowSizeMultiplierChange}
                controls={false}
                width="50%"
                min={1}
                precision={0}
              />
              <Select
                showAction={["focus", "click"]}
                value={params.data.windowSize}
                onChange={handleWindowSizeChange}
                options={[
                  {
                    value: "W",
                    label: t("calculation-flow.granularity-select.week"),
                  },
                  {
                    value: "D",
                    label: t("calculation-flow.granularity-select.day"),
                  },
                  {
                    value: "H",
                    label: t("calculation-flow.granularity-select.hour"),
                  },
                  {
                    value: "T",
                    label: t("calculation-flow.granularity-select.minute"),
                  },
                ]}
                style={{ width: "50%" }}
              />
            </FormItem>
            <Checkbox
              checked={!(params.data.windowSizeMultiplier > 0)}
              onChange={handleAggregationDirectionChange}
            >
              {t("calculation-flow.node-types.aggregate-forward-in-time")}
            </Checkbox>
          </Form>
        </NodeContent>
        <NodeOutput>
          <NodeOutputLabel />
        </NodeOutput>
      </Body>
    </Node>
  );
}

export default RollingWindowNode;
