import TimeseriesGraphForAlarmType from "./TimeseriesGraphForAlarmType";

export function AlarmTimeseriesGraph() {
  return (
    <div className="w-full h-full rounded-lg bg-background-secondary p-4 overflow-hidden box-border">
      <TimeseriesGraphForAlarmType />
    </div>
  );
}

export enum AlarmGraphFormFields {
  EventsVisible = "AlarmGraph--EventsVisible",
}

export const AlarmGraphFormDefaults = {
  [AlarmGraphFormFields.EventsVisible]: {
    defaultValue: true,
  },
};
