import { createContext, useContext, useMemo, useState } from "react";
import type { FloorPlan } from "./types";

type Context = {
  floorPlan: FloorPlan;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

const FloorPlanContext = createContext<Context | null>(null);

type Props = {
  floorPlan: FloorPlan;
  children: React.ReactNode;
};

export function FloorPlanProvider(props: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const value = useMemo(
    () => ({
      floorPlan: props.floorPlan,
      isEditing,
      setIsEditing,
    }),
    [props.floorPlan, isEditing],
  );

  return (
    <FloorPlanContext.Provider value={value}>
      {props.children}
    </FloorPlanContext.Provider>
  );
}

export function useFloorPlan() {
  const context = useContext(FloorPlanContext);

  if (context === null) {
    throw new Error("useFloorPlan must be used within a FloorPlanProvider");
  }

  return context.floorPlan;
}

export function useFloorPlanEditMode() {
  const context = useContext(FloorPlanContext);

  if (context === null) {
    throw new Error(
      "useFloorPlanEditMode must be used within a FloorPlanProvider",
    );
  }

  return [context.isEditing, context.setIsEditing] as const;
}
