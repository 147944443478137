import dayjs from "@properate/dayjs";

export const maximumFractionDigitsForUnit: Record<string, number> = {
  "°C": 1,
  Pa: 2,
  "m3/s": 3,
  "m³/s": 3,
  "%": 0,
  ppm: 0,
  ppb: 0,
  "Bq/m3": 0,
  "Bq/m³": 0,
  kW: 2,
  kWh: 2,
  A: 2,
  V: 2,
  m2: 1,
  m: 2,
  "m/s": 1,
  sec: 0,
  min: 1,
  "°F": 1,
  kPa: 0,
  "m3/h": 0,
  "m³/h": 0,
  W: 1,
  Wh: 1,
  mA: 2,
  mV: 2,
  mm: 0,
  "km/h": 1,
  K: 0,
  Bar: 2,
  "l/s": 2,
  MW: 2,
  MWh: 2,
  km: 2,
  mmH2O: 2,
  "mmH₂O": 2,
  "l/h": 0,
  GW: 2,
  GWh: 2,
  atm: 2,
  "": 2,
};

const UNIT_FORMATS: Record<string, string> = {
  "m3/s": "m³/s",
  "m3/h": "m³/h",
  mmH2O: "mmH₂O",
  "per-mille": "‰",
  "%-akt": "%",
  "%-norm": "%",
  voc_co2_eq: "VOC CO₂ ekv.",
  sec: "sekunder",
  min: "minutter",
};

export const formatUnit = (unit: string | undefined) =>
  (unit && UNIT_FORMATS[unit]) || unit;

export const formatSetPointValueForSchema = (v: {
  unit: string | undefined;
  value: number | undefined;
}): string => {
  const val = v?.value;

  return typeof val === "number"
    ? `${new Intl.NumberFormat("nb-NO").format(val)}${
        v.unit ? " " + formatUnit(v.unit) : ""
      }`
    : "--";
};

export const formatMeasurementForSchema = (v: {
  unit: string | undefined;
  value: number | undefined;
  stateDescription?: Record<number, string>;
}): string => {
  if (v.stateDescription) {
    return typeof v.value === "number"
      ? v.stateDescription[v.value] || `${v.value}`
      : "--";
  }
  const val =
    v?.value && ["%-akt", "%-norm"].includes(v?.unit || "")
      ? v?.value * 100
      : v?.value;

  return typeof val === "number"
    ? `${new Intl.NumberFormat("nb-NO", {
        maximumFractionDigits:
          (v.unit || "") in maximumFractionDigitsForUnit
            ? maximumFractionDigitsForUnit[v.unit || ""]
            : 1,
      }).format(val)}${v.unit ? " " + formatUnit(v.unit) : ""}`
    : "--";
};

export const isValid = (timestamp: Date) =>
  dayjs(timestamp).isAfter(dayjs().subtract(2, "h"));

export const getAlarmClass = (
  value: number | undefined,
  alarmType: "warning" | "error" | "status",
  max?: number,
  min?: number,
) =>
  value !== undefined &&
  ((max !== undefined && value > max) || (min !== undefined && value < min))
    ? alarmType
    : "ok";
