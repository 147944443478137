import { Fragment, PropsWithChildren, useState } from "react";
import { Timeseries } from "@cognite/sdk";
import { SensorInfo } from "@properate/common";
import { useRequest } from "ahooks";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import GraphModal from "@/pages/common/SchemaGraph";
import RoomInfo from "@/pages/common/RoomInfo";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useSensorCategoryOptions } from "@/pages/common/SchemaView/TechnicalSchema/sensorQueries";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";
import { useFloorPlanEditMode } from "./FloorPlanContext";
import { Pin } from "./types";

export function FloorPlanMapPinSensorStrategy(props: PropsWithChildren) {
  const [isEditing] = useFloorPlanEditMode();
  const buildingId = useCurrentBuildingId();
  const pin = useFloorPlanPin();
  const floorPlanUpdate = useFloorPlanUpdate();

  const [showGraphModal, setShowGraphModal] = useState(false);

  const [roomId, setRoomId] = useState(0);
  const showRoomModal = roomId > 0;

  const [timeseriesId, setTimeseriesId] = useState(0);
  const [showTimeseriesModal, setShowTimeseriesModal] = useState(false);

  async function handleRemove() {
    await floorPlanUpdate.trigger({
      pins: {
        remove: pin,
      },
    });
  }

  async function handleSwapTimeseries(timeseriesList: Timeseries[]) {
    const [timeseries] = timeseriesList;
    const newTimeseriesId = timeseries.id;

    await floorPlanUpdate.trigger({
      pins: {
        remove: pin,
        insert: [{ ...pin, timeseriesId: newTimeseriesId }],
      },
    });
  }

  async function handleUpdatePinImpl(sensor: SensorInfo) {
    // Convert structure used inside the GraphModal to the structure we save on
    // the pin object at the floor-plans Firestore collection.
    const newPin: Pin = {
      ...pin,
      variant: sensor.view === "all" ? "value+name" : sensor.view || "value",
      alias: sensor.alias ?? null,
      unit: sensor.unit ?? null,
      alarm:
        sensor.alarmType || sensor.max || sensor.min
          ? {
              level: sensor.alarmType ?? "warning",
              max: sensor.max ?? null,
              maxLabel: sensor.maxView ?? null,
              min: sensor.min ?? null,
              minLabel: sensor.minView ?? null,
            }
          : null,
    };

    await floorPlanUpdate.trigger({
      pins: {
        remove: pin,
        insert: [newPin],
      },
    });
  }

  const { run: handleUpdatePin } = useRequest(handleUpdatePinImpl, {
    debounceWait: 600,
    debounceTrailing: true,
    manual: true,
  });

  // Convert from the pin structure used in the Firestore collection to the
  // SensorStructure used by the GraphModal component.
  const sensorInfo: SensorInfo = {
    id: pin.timeseriesId,
    view: pin.variant === "value+name" ? "all" : pin.variant,
    unit: pin.unit ?? undefined,
    alias: pin.alias ?? undefined,
    alarmType: pin.alarm?.level,
    max: pin.alarm?.max ?? undefined,
    maxView: pin.alarm?.maxLabel ?? undefined,
    min: pin.alarm?.min ?? undefined,
    minView: pin.alarm?.minLabel ?? undefined,
  };

  const categoryOptions = useSensorCategoryOptions();

  return (
    <Fragment>
      <div
        role="button"
        tabIndex={0}
        onClick={() => !isEditing && setShowGraphModal(true)}
        className="flex cursor-pointer"
      >
        {props.children}
      </div>
      {showGraphModal && (
        <GraphModal
          showDocuments
          showAlerts
          showSetPoints
          showBehindRoomInfo
          buildingId={buildingId}
          hide={() => setShowGraphModal(false)}
          timeseriesInfo={sensorInfo}
          setTimeseriesInfo={handleUpdatePin}
          deleteTimeseries={handleRemove}
          openRoomInfo={setRoomId}
          showSettings={() => setShowTimeseriesModal(true)}
        />
      )}
      {showRoomModal && (
        <RoomInfo
          id={roomId}
          sensors={
            {
              /* todo: should we fetch the sensors somehow? */
            }
          }
          hide={() => {
            setRoomId(0);
            setTimeseriesId(0);
          }}
          openGraph={(timeseriesId) => {
            setRoomId(0);
            setTimeseriesId(timeseriesId);
          }}
        />
      )}
      {showTimeseriesModal && (
        <TimeseriesSelectionModal
          open
          onHide={() => {
            setTimeseriesId(0);
            setShowTimeseriesModal(false);
          }}
          selectedIds={[timeseriesId || pin.timeseriesId]}
          categoryOptions={categoryOptions}
          hiddenFilters={["building"]}
          initialFilters={{ category: pin.type }}
          onOk={handleSwapTimeseries}
          max={1}
        />
      )}
    </Fragment>
  );
}
