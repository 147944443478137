import { useContext, useEffect, useState } from "react";
import { IoHandRight } from "@react-icons/all-files/io5/IoHandRight";
import { IoHandRightOutline } from "@react-icons/all-files/io5/IoHandRightOutline";
import { ThemeContext } from "styled-components";
import {
  Button,
  Col,
  App,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  InputNumber,
} from "antd";
import {
  CloseOutlined,
  HistoryOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {
  Asset,
  CogniteClient,
  Timeseries,
  DoubleDatapoint,
} from "@cognite/sdk";
import { SensorList } from "@properate/common";
import { useUser } from "@properate/auth";
import { RoomInfoType } from "@properate/api/src/types";
import { useTranslations } from "@properate/translations";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { getSetPointStatus, roomsIndex, updateSetPoint } from "@/eepApi";
import {
  formatMeasurementForSchema,
  formatUnit,
} from "@/pages/common/SchemaView/TechnicalSchema/utils";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  TimeseriesSettings,
  useTimeseriesSettings,
} from "@/services/timeseriesSettings";
import { AuditLogModal } from "@/components/AuditLog";
import { convertUnit, getStateDescription, parseError } from "../utils";

import error from "./icons/error.svg";
import warning from "./icons/warning.svg";

type RoomSensorValue = {
  value: string;
  alarm?: string;
  alarmType: "warning" | "error" | "status";
  id: number;
};

type MinMaxMean = {
  min: RoomSensorValue | undefined;
  max: RoomSensorValue | undefined;
  mean: RoomSensorValue | undefined;
};

type RoomData = {
  humidity_sensor: MinMaxMean;
  VOC: MinMaxMean;
  radon: MinMaxMean;
  temperature: MinMaxMean;
  co2: MinMaxMean;
  motion: RoomSensorValue | undefined;
};

const ROOM_INFO_URL =
  process.env.REACT_APP_KEYCLOAK_REALM! === "prod"
    ? "https://app.properate.com/room"
    : "https://dev.eepcloud.no/room";

const getAlarm = (
  value: number,
  unit: string,
  max?: number,
  min?: number,
  maxView = `Over ${max}${formatUnit(unit)}`,
  minView = `Under ${min}${formatUnit(unit)}`,
) => {
  const aboveMax = typeof max === "number" && value > max;
  const belowMin = typeof min === "number" && value < min;
  if (aboveMax) {
    return maxView;
  } else if (belowMin) {
    return minView;
  }
  return undefined;
};

type ValueColProps = {
  value?: string;
  alarm?: string;
  alarmType?: "warning" | "error" | "status";
  id?: number;
  openGraph: (id: number) => void;
  span?: number;
};

const ValueCol = ({
  value,
  id,
  openGraph,
  span = 12,
  alarm,
  alarmType,
}: ValueColProps) => (
  <Col span={span} style={{ textAlign: "right" }}>
    {value && id ? (
      <Button
        style={{ padding: 0 }}
        type="link"
        onClick={() => {
          openGraph(id);
        }}
      >
        {alarm && (
          <Tooltip title={alarm}>
            {alarmType === "error" ? (
              <img src={error} alt="Feil" />
            ) : (
              <img src={warning} alt="Advarsel" />
            )}
          </Tooltip>
        )}
        {value}
      </Button>
    ) : (
      "--"
    )}
  </Col>
);

type Props = {
  hide: () => void;
  id: number;
  sensors: Record<string, SensorList>;
  openGraph: (id: number) => void;
};

interface ImportedTimeseries {
  timeseriesId: number;
  externalId: string;
  relationshipExternalId: string;
  name: string;
  description: string;
  value?: number;
  priority?: number;
  type: "writable" | "value";
  unit: string;
  stateDescription?: undefined | Record<number, string>;
  maxValue?: number;
  minValue?: number;
  touched?: boolean;
}

const extractMinMaxMeanIds = (room: RoomInfoType) => {
  const minMaxMeanIds: { id: number }[] = [];

  const labels = [
    "VOC",
    "co2",
    "humidity_sensor",
    "motion",
    "temperature",
    "radon",
  ];

  for (const label of labels) {
    const sensor:
      | { value: number }
      | { mean: number; max: number; min: number }
      | {} = room[label as keyof RoomInfoType]
      ? room[label as keyof RoomInfoType]
      : {};

    if ("value" in sensor) {
      minMaxMeanIds.push({ id: sensor.value });
    }
    if ("min" in sensor) {
      minMaxMeanIds.push({ id: sensor.min });
    }
    if ("max" in sensor) {
      minMaxMeanIds.push({ id: sensor.max });
    }
    if ("mean" in sensor) {
      minMaxMeanIds.push({ id: sensor.mean });
    }
  }
  return minMaxMeanIds;
};
const loadImported = async (
  client: CogniteClient,
  room: RoomInfoType,
  overrideUnits?: TimeseriesSettings,
): Promise<ImportedTimeseries[]> => {
  const relationships = await client.relationships
    .list({
      filter: { sourceExternalIds: [room.externalId!] },
      limit: 1000,
    })
    .autoPagingToArray({ limit: Infinity });

  if (relationships.length === 0) {
    return [];
  }

  const relationshipMap = relationships.reduce<Record<string, string>>(
    (prev, r) => ({
      ...prev,
      [r.targetExternalId]: r.externalId,
    }),
    {},
  );

  const externalIds = [
    ...new Set(relationships.map((rel) => rel.targetExternalId!)),
  ];
  const relationshipTimeseries = await client.timeseries.retrieve(
    externalIds.map((externalId) => ({ externalId })),
  );

  const tsAssetsMap = (
    await client.assets.retrieve(
      relationshipTimeseries.map((ts) => ({ id: ts.assetId! })),
    )
  ).reduce<Record<number, Asset>>(
    (prev, asset) => ({ ...prev, [asset.id]: asset }),
    {},
  );

  const writable = relationshipTimeseries.filter(
    (ts) =>
      tsAssetsMap[ts.assetId!].labels?.some(
        (label) => label.externalId === "writable",
      ),
  );
  const readOnly = relationshipTimeseries.filter(
    (ts) =>
      !tsAssetsMap[ts.assetId!].labels?.some(
        (label) => label.externalId === "writable",
      ),
  );

  const writableStatus =
    writable.length > 0
      ? await getSetPointStatus({
          external_ids: writable.map((ts) => ts.externalId!),
        })
      : {};

  const readOnlyValues =
    readOnly.length > 0
      ? await client.datapoints.retrieveLatest(
          readOnly.map((ts) => ({ id: ts.id })),
        )
      : [];

  const readOnlyValuesMap = readOnlyValues.reduce<
    Record<number, number | undefined>
  >(
    (prev, current) => ({
      ...prev,
      [current.id]: current.datapoints
        ? (current.datapoints[0] as DoubleDatapoint)?.value
        : undefined,
    }),
    {},
  );

  return relationshipTimeseries.map((r) =>
    writableStatus[r.externalId!]
      ? {
          timeseriesId: r.id,
          externalId: r.externalId!,
          name: r.name!,
          description: r.description || "",
          relationshipExternalId: relationshipMap[r.externalId!],
          value:
            typeof writableStatus[r.externalId!]["present-value"] === "number"
              ? (writableStatus[r.externalId!]["present-value"] as number)
              : undefined,
          priorityArray: writableStatus[r.externalId!]["priority-array"],
          priority: writableStatus[r.externalId!]["priority-array"]?.find(
            (p) => p.index === 8,
          )
            ? 8
            : 16,
          reliability: writableStatus[r.externalId!].reliability,
          outOfService: writableStatus[r.externalId!]["out-of-service"],
          type: "writable",
          unit:
            (overrideUnits && overrideUnits[r.externalId!]?.unit) ||
            r.unit ||
            "",
          stateDescription: getStateDescription(r.metadata?.state_description),
          maxValue: r.metadata?.max_value
            ? Number(r.metadata.max_value)
            : Number.MAX_SAFE_INTEGER,
          minValue: r.metadata?.min_value
            ? Number(r.metadata.min_value)
            : Number.MIN_SAFE_INTEGER,
        }
      : {
          timeseriesId: r.id,
          externalId: r.externalId!,
          name: r.name!,
          description: r.description || "",
          relationshipExternalId: relationshipMap[r.externalId!],
          value: convertUnit(
            r.unit,
            overrideUnits && overrideUnits[r.externalId!]?.unit,
            readOnlyValuesMap[r.id],
          ).value,
          type: "value",
          unit:
            (overrideUnits && overrideUnits[r.externalId!]?.unit) ||
            r.unit ||
            "",
          stateDescription: getStateDescription(r.metadata?.state_description),
        },
  );
};
export const RoomInfo = ({ hide, id, sensors, openGraph }: Props) => {
  const t = useTranslations();
  const { message } = App.useApp();
  const { client } = useCogniteClient();
  const user = useUser();
  const themeContext = useContext(ThemeContext);
  const building = useCurrentBuilding();
  const { isLoading: isLoadingOverrideUnits, overrideUnits } =
    useTimeseriesSettings(building.id);

  const [newRoom, setNewRoom] = useState<RoomInfoType | undefined>();
  const [showAllTimeseries, setShowAllTimeseries] = useState<boolean>();
  const [showWritableTimeseries, setShowWritableTimeseries] =
    useState<boolean>();

  const [importedTimeseriesList, setImportedTimeseriesList] = useState<
    ImportedTimeseries[]
  >([]);
  const [
    showHistoryForImportedTimeseries,
    setShowHistoryForImportedTimeseries,
  ] = useState<ImportedTimeseries>();

  const [data, setData] = useState<RoomData>();

  async function handleChangeTimeseries(timeseriesList: Timeseries[]) {
    // check if any relationships have been removed
    const removedTimeseriesList = importedTimeseriesList.filter(
      (importedTimeseries) =>
        timeseriesList.every((ts) => ts.id !== importedTimeseries.timeseriesId),
    );
    if (removedTimeseriesList.length > 0) {
      await client.relationships.delete(
        removedTimeseriesList.map((removedTimeseries) => ({
          externalId: removedTimeseries.relationshipExternalId,
        })),
      );
    }

    const newTimeseriesList = timeseriesList.filter(
      (timeseries) =>
        !importedTimeseriesList.some(
          (importedTimeseries) =>
            importedTimeseries.timeseriesId === timeseries.id,
        ),
    );

    await Promise.all(
      newTimeseriesList.map(async (timeseries) => {
        await client.relationships.create([
          {
            sourceExternalId: newRoom!.externalId!,
            targetExternalId: timeseries.externalId!,
            externalId: `rel_${crypto.randomUUID()}`,
            confidence: 0.95,
            sourceType: "asset" as const,
            targetType: "timeSeries" as const,
            dataSetId: newRoom!.dataSetId,
            labels: [{ externalId: "rel_setpt_realval_gen" }],
          },
        ]);
        setImportedTimeseriesList(await loadImported(client, newRoom!));
      }),
    );
  }

  useEffect(() => {
    const get = async (isLoadingOverrideUnits: boolean) => {
      if (isLoadingOverrideUnits) {
        const floorRoomSensorIds = await roomsIndex.getDocument(id);
        setNewRoom(floorRoomSensorIds);

        const infoMap = Object.keys(sensors).reduce<
          Record<
            number,
            {
              min?: number;
              max?: number;
              alarmType: "warning" | "error";
            }
          >
        >((acc, key) => {
          const timeseriesInfo = sensors[key].timeseriesInfo;
          const current = timeseriesInfo.reduce<
            Record<
              number,
              {
                min?: number;
                max?: number;
                alarmType: "warning" | "error";
              }
            >
          >(
            (a: any, c: any) => ({
              ...a,
              [c.id]: {
                min: c.min,
                max: c.max,
                alarmType: c.alarmType,
              },
            }),
            {},
          );
          return { ...acc, ...current };
        }, {});

        const minMaxMeanIds = extractMinMaxMeanIds(floorRoomSensorIds);

        const values = (
          await Promise.all([client.datapoints.retrieveLatest(minMaxMeanIds)])
        ).flat();

        const valueMap: Record<number, any> = values.reduce(
          (prev, current) => ({ ...prev, [current.id]: current.datapoints[0] }),
          {},
        );

        const timeseriesMap: Record<number, Timeseries> = values.reduce(
          (prev, current) => ({
            ...prev,
            [current.id!]: current,
          }),
          {},
        );
        const getAssetValue = (assetId: number) => {
          if (assetId) {
            const ts = timeseriesMap[assetId];
            const overrideUnit =
              (ts && overrideUnits && overrideUnits[ts.externalId!]?.unit) ||
              ts?.unit ||
              "";
            const min = convertUnit(ts.unit, overrideUnit, infoMap[ts.id]?.min)
              ?.value;
            const max = convertUnit(ts.unit, overrideUnit, infoMap[ts.id]?.max)
              ?.value;
            const alarmType = infoMap[ts.id]?.alarmType || "warning";
            const stateDescription = getStateDescription(
              ts.metadata?.state_description,
            );

            const converted = convertUnit(
              ts.unit,
              overrideUnit,
              valueMap[ts.id]?.value,
            );
            return {
              value: formatMeasurementForSchema({
                ...converted,
                stateDescription,
              }),
              alarm: getAlarm(converted.value!, converted.unit || "", max, min),
              alarmType,
              id: ts.id,
            };
          }
          return undefined;
        };

        const getValue = (
          sensor: "humidity_sensor" | "VOC" | "radon" | "temperature" | "co2",
          type: "min" | "max" | "mean",
        ) => {
          const assetId = floorRoomSensorIds[sensor][type];
          return assetId ? getAssetValue(assetId) : undefined;
        };

        const getMotion = () => {
          const assetId = floorRoomSensorIds["motion"]["value"];
          return assetId ? getAssetValue(assetId) : undefined;
        };

        const data = {
          humidity_sensor: {
            min: getValue("humidity_sensor", "min"),
            max: getValue("humidity_sensor", "max"),
            mean: getValue("humidity_sensor", "mean"),
          },
          VOC: {
            min: getValue("VOC", "min"),
            max: getValue("VOC", "max"),
            mean: getValue("VOC", "mean"),
          },
          radon: {
            min: getValue("radon", "min"),
            max: getValue("radon", "max"),
            mean: getValue("radon", "mean"),
          },
          temperature: {
            min: getValue("temperature", "min"),
            max: getValue("temperature", "max"),
            mean: getValue("temperature", "mean"),
          },
          co2: {
            min: getValue("co2", "min"),
            max: getValue("co2", "max"),
            mean: getValue("co2", "mean"),
          },
          motion: getMotion(),
        };

        setData(data);

        const imported = await loadImported(
          client,
          floorRoomSensorIds,
          overrideUnits,
        );

        setImportedTimeseriesList(imported);
      }
    };

    get(!isLoadingOverrideUnits);
  }, [
    id,
    sensors,
    client,
    setImportedTimeseriesList,
    isLoadingOverrideUnits,
    overrideUnits,
  ]);
  const showSelectTimeseries = showAllTimeseries || showWritableTimeseries;
  return (
    <>
      <Modal
        open
        styles={{ body: { background: themeContext.background2 } }}
        onCancel={() => {
          hide();
        }}
        title={`${newRoom?.name}`}
        footer={[
          <Button
            key="import"
            disabled={user.isViewer}
            onClick={() => {
              setShowAllTimeseries(true);
            }}
          >
            {t("room-info.import-measures")}
          </Button>,
          <Button
            key="import"
            disabled={user.isViewer}
            onClick={() => {
              setShowWritableTimeseries(true);
            }}
          >
            {t("room-info.import-setpoint")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              // go through the setpoints and update the ones that have been changed
              importedTimeseriesList.forEach(async (imported) => {
                if (imported.touched) {
                  try {
                    await updateSetPoint({
                      priority: imported.priority,
                      value: imported.value,
                      external_id: imported.externalId,
                      audit_source: "web",
                    });
                  } catch (e) {
                    const errorMessage = parseError(e);
                    message.error(
                      t("room-info.cant-update-setpoint", {
                        error: errorMessage,
                      }),
                    );
                  }
                }
              });
              hide();
            }}
          >
            {t("room-info.ok")}
          </Button>,
        ]}
      >
        <p>
          {newRoom?.subBuilding}{" "}
          {t("room-info.floor", {
            floor: newRoom?.floor,
          })}{" "}
          {user.isAdmin && (
            <Button
              type="link"
              onClick={async () => {
                await navigator.clipboard.writeText(`${ROOM_INFO_URL}/${id}`);
                message.success(t("room-info.link-copied"));
              }}
            >
              <LinkOutlined /> {t("room-info.copy-link")}
            </Button>
          )}
        </p>
        <Row gutter={[12, 12]}>
          <Col span={12}>{t("room-info.temperature")}</Col>
          <ValueCol openGraph={openGraph} {...(data?.temperature.mean || {})} />

          <Col span={12}>{t("room-info.moisture")}</Col>
          <ValueCol
            openGraph={openGraph}
            {...(data?.humidity_sensor.mean || {})}
          />

          <Col span={12}>{t("room-info.CO2")}</Col>
          <ValueCol openGraph={openGraph} {...(data?.co2.mean || {})} />

          <Col span={12}>{t("room-info.VOC")}</Col>
          <ValueCol openGraph={openGraph} {...(data?.VOC.mean || {})} />

          <Col span={12}>{t("room-info.radon")}</Col>
          <ValueCol openGraph={openGraph} {...(data?.radon.mean || {})} />

          <Col span={12}>{t("room-info.motion")}</Col>
          <ValueCol openGraph={openGraph} {...(data?.motion || {})} />
        </Row>
        {importedTimeseriesList.map((imp) => (
          <Row
            gutter={12}
            key={imp.relationshipExternalId}
            style={{ marginBottom: 12, marginTop: 12 }}
          >
            <Col span={15}>
              {imp.name} {imp.description}
            </Col>
            <Col span={7} style={{ textAlign: "right" }}>
              {imp.type === "writable" ? (
                <Space>
                  <div className="inline-flex">
                    <InputNumber
                      disabled={user.isViewer}
                      value={imp.value}
                      suffix={imp.unit !== "" ? imp.unit : undefined}
                      onChange={async (value) => {
                        setImportedTimeseriesList(
                          importedTimeseriesList.map((i) =>
                            i.relationshipExternalId ===
                            imp.relationshipExternalId
                              ? {
                                  ...i,
                                  value: value === null ? undefined : value,
                                  touched: i.value !== value,
                                }
                              : i,
                          ),
                        );
                      }}
                      decimalSeparator=","
                    />
                    <Button
                      disabled={user.isViewer}
                      size="small"
                      style={
                        imp.priority === 8
                          ? { color: themeContext.primary }
                          : {}
                      }
                      icon={
                        imp.priority === 8 ? (
                          <IoHandRight />
                        ) : (
                          <IoHandRightOutline />
                        )
                      }
                      type="link"
                      onClick={async (event) => {
                        event.currentTarget.blur();
                        setImportedTimeseriesList(
                          importedTimeseriesList.map((i) =>
                            i.relationshipExternalId ===
                            imp.relationshipExternalId
                              ? {
                                  ...i,
                                  overrideValue: undefined,
                                }
                              : i,
                          ),
                        );
                        await updateSetPoint({
                          priority: 8,
                          value: undefined,
                          external_id: imp.relationshipExternalId,
                          audit_source: "web",
                        });
                      }}
                    />
                    <Button
                      icon={<HistoryOutlined />}
                      className="ml-2"
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowHistoryForImportedTimeseries(imp);
                      }}
                    />
                  </div>
                </Space>
              ) : (
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => {
                    openGraph(imp.timeseriesId);
                  }}
                >
                  {formatMeasurementForSchema({
                    value: imp.value,
                    unit: imp.unit,
                    stateDescription: imp.stateDescription,
                  })}
                </Button>
              )}
            </Col>
            <Col span={2} style={{ textAlign: "right" }}>
              <Popconfirm
                disabled={user.isViewer}
                title="Er du sikker på at du vil slette koblingen?"
                onConfirm={async () => {
                  await client.relationships.delete([
                    { externalId: imp.relationshipExternalId },
                  ]);
                  setImportedTimeseriesList(
                    importedTimeseriesList.filter(
                      (r) =>
                        r.relationshipExternalId !== imp.relationshipExternalId,
                    ),
                  );
                }}
                okText="Slett"
                cancelText="Avbryt"
              >
                <Button
                  icon={<CloseOutlined />}
                  size="small"
                  disabled={user.isViewer}
                />
              </Popconfirm>
            </Col>
          </Row>
        ))}
        <TimeseriesSelectionModal
          open={Boolean(newRoom && showSelectTimeseries)}
          hiddenFilters={["building"]}
          initialFilters={{
            category: showAllTimeseries ? "default" : "setPoint",
            buildingId: building?.rootId,
            subBuilding: newRoom?.subBuilding ? newRoom.subBuilding : undefined,
          }}
          onHide={() => {
            if (showAllTimeseries) {
              setShowAllTimeseries(false);
            }
            if (showWritableTimeseries) {
              setShowWritableTimeseries(false);
            }
          }}
          selectedIds={importedTimeseriesList.map(
            (imported) => imported.timeseriesId,
          )}
          max={200}
          onOk={handleChangeTimeseries}
          initialSearch={showAllTimeseries ? newRoom?.name : ""}
        />
        {showHistoryForImportedTimeseries && (
          <AuditLogModal
            name={`${showHistoryForImportedTimeseries.name} ${showHistoryForImportedTimeseries.description}`}
            externalId={showHistoryForImportedTimeseries.externalId}
            onHide={() => setShowHistoryForImportedTimeseries(undefined)}
          />
        )}
      </Modal>
    </>
  );
};
