import { useTranslations } from "@properate/translations";
import { Empty, Select, Switch, TimePicker } from "antd";
import { Dayjs } from "@properate/dayjs";
import {
  AlertDay,
  ActiveAlarmPeriod,
  keysForAlertDays,
} from "@properate/common";
import { FormContextActionType, FormContextItem } from "../FormContext";
import { useFormContext, useFormValue } from "../FormContext/hooks";
import { ButtonVariant, NotificationRow } from "./NotificationRow";
import { AlarmNotificationFormFields } from "./types";

const { RangePicker: TimeRangePicker } = TimePicker;

const tBase = "alarm-details.notifications-page.active";

function NoActiveRows() {
  const [isAlwaysActive] = useFormValue<boolean>(
    AlarmNotificationFormFields.AlwaysActive,
  );
  const [activePeriods] = useFormValue<ActiveAlarmPeriod[]>(
    AlarmNotificationFormFields.ActivePeriods,
  );
  const t = useTranslations(tBase);
  if (isAlwaysActive || activePeriods?.length > 0) {
    return null;
  }

  return (
    <div className="w-full flex flex-row justify-center items-center">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="" />
      <p className="opacity-50">{t(`empty-message`)}</p>
    </div>
  );
}

function TimespanForAlertSchedule({
  period,
}: Readonly<{ period: ActiveAlarmPeriod }>) {
  const t = useTranslations(tBase);
  if (period.all_day) {
    return <span>{t(`all-day`)}</span>;
  }

  return <span>{`${period.start_time} - ${period.end_time}`}</span>;
}

function ActiveRows() {
  const t = useTranslations();
  const [isAlwaysActive] = useFormValue<boolean>(
    AlarmNotificationFormFields.AlwaysActive,
  );
  const [activePeriods, setActivePeriods] = useFormValue<ActiveAlarmPeriod[]>(
    AlarmNotificationFormFields.ActivePeriods,
  );

  if (isAlwaysActive || !activePeriods || activePeriods.length === 0) {
    return null;
  }

  return activePeriods.map((period, index) => (
    <NotificationRow
      key={`active-period-${index}-${period.day}`}
      // @ts-ignore
      colOne={<span>{t(keysForAlertDays[period.day])}</span>}
      colTwo={<TimespanForAlertSchedule period={period} />}
      buttonVariant={ButtonVariant.Remove}
      onClick={() => setActivePeriods(activePeriods.toSpliced(index, 1))}
    />
  ));
}

function AlwaysActive() {
  return (
    <FormContextItem
      id={AlarmNotificationFormFields.AlwaysActive}
      labelKey={`${tBase}.always-active`}
      labelProps={{ className: "w-full flex justify-between" }}
      antdInput
      valueProp="checked"
    >
      <Switch />
    </FormContextItem>
  );
}

function SelectAlertDays() {
  const t = useTranslations();
  return (
    <FormContextItem
      id={AlarmNotificationFormFields.ActivePeriodDay}
      labelKey={`${tBase}.aria-select-alert-day`}
      labelSrOnly
      antdInput
    >
      <Select
        placeholder={t(`${tBase}.alert-days-placeholder`)}
        className="w-full !h-[23px]"
        size="small"
      >
        {Object.entries(keysForAlertDays).map(([key, value]) => (
          <Select.Option key={`option_${key}`} value={key}>
            {/*@ts-ignore*/}
            {t(value)}
          </Select.Option>
        ))}
      </Select>
    </FormContextItem>
  );
}

function AddActiveRow() {
  const [isAlwaysActive] = useFormValue(
    AlarmNotificationFormFields.AlwaysActive,
  );
  const [activePeriods, setActivePeriods] = useFormValue<ActiveAlarmPeriod[]>(
    AlarmNotificationFormFields.ActivePeriods,
  );
  const [timeRange] = useFormValue<Dayjs[]>(
    AlarmNotificationFormFields.ActivePeriodTimeRange,
  );
  const [alertDay] = useFormValue<AlertDay>(
    AlarmNotificationFormFields.ActivePeriodDay,
  );
  const { dispatch } = useFormContext();

  function handleAddActive() {
    if (!timeRange?.length || timeRange?.length !== 2 || !alertDay) {
      dispatch({
        type: FormContextActionType.validateFields,
        ids: [
          AlarmNotificationFormFields.ActivePeriodTimeRange,
          AlarmNotificationFormFields.ActivePeriodDay,
        ],
      });
      return;
    }
    const startTime = timeRange[0].format("HH:mm");
    const endTime = timeRange[1].format("HH:mm");
    const newActivePeriods = [
      ...(activePeriods || []),
      {
        day: alertDay,
        all_day: false,
        start_time: startTime,
        end_time: endTime,
      },
    ];
    setActivePeriods(newActivePeriods);
  }

  if (isAlwaysActive) {
    return null;
  }

  return (
    <NotificationRow
      outerClassName="gap-2"
      innerClassName="gap-2"
      colOne={<SelectAlertDays />}
      colTwo={
        <FormContextItem
          id={AlarmNotificationFormFields.ActivePeriodTimeRange}
          labelKey={`${tBase}.aria-alert-timerange`}
          labelSrOnly
          antdInput
        >
          <TimeRangePicker size="small" className={"w-full"} format={"HH:mm"} />
        </FormContextItem>
      }
      buttonVariant={ButtonVariant.Add}
      onClick={handleAddActive}
    />
  );
}

export function ActiveTimes() {
  const t = useTranslations(tBase);
  return (
    <div className="w-full flex flex-col gap-2">
      <h2 className="m-0">{t(`header`)}</h2>
      <AlwaysActive />
      <ActiveRows />
      <NoActiveRows />
      <AddActiveRow />
    </div>
  );
}
