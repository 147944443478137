import { useSidebarData } from "@properate/ui";
import useSWR from "swr";
import { IdEither, Timeseries, TimeseriesFilterQuery } from "@cognite/sdk";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { NotesAssetFilterMode, NotesSidebarValues } from "../types";
import {
  extractSuccessfulDataFromCogniteError,
  mergedPagination,
} from "./utils";
import { useNotesDataSetIds } from "./useNotesDataSetIds";

export function useTimeseriesList() {
  const { filterQuery, idSet, assetFilterMode } =
    useSidebarData<NotesSidebarValues>();
  const dataSetIds = useNotesDataSetIds();

  function makeTimeseriesSwrArguments() {
    if (assetFilterMode === NotesAssetFilterMode.AssetList) {
      return null;
    }
    if (assetFilterMode === NotesAssetFilterMode.TimeseriesList) {
      return [...idSet];
    }
    if (dataSetIds === undefined) {
      return null;
    }

    return {
      ...(filterQuery || {}),
      filter: {
        ...(filterQuery?.filter ?? {}),
        dataSetIds,
      },
    };
  }

  async function makeTimeseriesQueryRequest(filter: TimeseriesFilterQuery) {
    const response = await client.timeseries.list(filter);
    return response?.items || [];
  }

  function makeTimeseriesListRequest(filter: number[]) {
    const retrieveIdList: IdEither[] = filter.map((id) => ({
      id,
    }));
    return mergedPagination<IdEither, IdEither[], Timeseries>(
      retrieveIdList,
      100,
      (filter) => filter,
      async (filter) => {
        let res = null;
        try {
          res = await client.timeseries.retrieve(filter, {
            ignoreUnknownIds: true,
          });
        } catch (e) {
          return extractSuccessfulDataFromCogniteError<Timeseries>(e);
        }
        return res;
      },
    );
  }

  const { client } = useCogniteClient();
  const {
    data: timeseriesList,
    error,
    ...rest
  } = useSWR(
    makeTimeseriesSwrArguments(),
    async (filter: number[] | TimeseriesFilterQuery) => {
      switch (assetFilterMode) {
        case NotesAssetFilterMode.AssetList:
          return [];
        case NotesAssetFilterMode.TimeseriesQuery:
          return makeTimeseriesQueryRequest(filter as TimeseriesFilterQuery);
        case NotesAssetFilterMode.TimeseriesList:
          return makeTimeseriesListRequest(filter as number[]);
        default:
          return null;
      }
    },
  );

  return {
    timeseriesList,
    error,
    ...rest,
  };
}
