import useSWRMutation from "swr/mutation";
import { message } from "antd";
import { mutate } from "swr";
import { useTranslations } from "@properate/translations";
import { updateSetPoint } from "@/eepApi";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";

type Arg = {
  priority: number;
  value: number;
  unit?: string;
};

export function useFloorPlanPinStatusUpdate() {
  const t = useTranslations();
  const pin = useFloorPlanPin();
  const buildingId = useCurrentBuildingId();
  const { handleOverrideUnit } = useTimeseriesSettings(buildingId);

  return useSWRMutation(
    [pin.externalId],
    async ([externalId], extra: { arg: Arg }) => {
      if (!externalId) {
        message.error(t("floor-plan-v2.messages.setpoint-updated-failed"));
        return;
      }

      try {
        await updateSetPoint({
          external_id: externalId,
          audit_source: "web/technicalSchema",
          priority: extra.arg.priority,
          value: extra.arg.value,
        });

        if (extra.arg.unit) {
          await handleOverrideUnit(buildingId, externalId, extra.arg.unit);
        }

        await mutate(["set-point", externalId]);
        await mutate(["timeseries-latest-value", pin.timeseriesId]);

        message.success(t("floor-plan-v2.messages.setpoint-updated"));
      } catch (error) {
        message.error(t("floor-plan-v2.messages.setpoint-updated-failed"));
        console.error(error);
      }
    },
  );
}
