import { useUser } from "@properate/auth";
import { FunctionComponent, useState } from "react";
import {
  RadioChangeEvent,
  Modal,
  Radio,
  Space,
  Form,
  Button,
  Checkbox,
  Divider,
} from "antd";
import { TimeSpan } from "@properate/common";
import dayjs from "@properate/dayjs";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useTranslations } from "@properate/translations";
import { RangePicker } from "@/components/RangePicker/RangePicker";
import { MILLISECONDS_IN_DAY } from "../constants";

const CUSTOM_DURATION = -1;
const FormItem = Form.Item;

interface Props {
  open: boolean;
  onRun: (timeSpan: TimeSpan, purgeDatapoints: boolean) => void;
  onClose: () => void;
}

const RunModal: FunctionComponent<Props> = ({ open, onRun, onClose }) => {
  const t = useTranslations();
  const [duration, setDuration] = useState(7 * MILLISECONDS_IN_DAY);
  const user = useUser();
  const [customTimeSpan, setCustomTimeSpan] = useState<TimeSpan>([
    dayjs().valueOf() - 7 * MILLISECONDS_IN_DAY,
    dayjs().valueOf(),
  ]);
  const [purgeDatapoints, setPurgeDatapoints] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const handleRun = async () => {
    setIsRunning(true);
    await onRun(
      duration === CUSTOM_DURATION
        ? customTimeSpan
        : [Math.max(0, dayjs().valueOf() - duration), dayjs().valueOf()],
      purgeDatapoints,
    );
    setIsRunning(false);
  };

  const handleDurationChange = (e: RadioChangeEvent) => {
    setDuration(e.target.value);
  };

  const handleRangePickerChange = (timeSpan: TimeSpan) => {
    setCustomTimeSpan(timeSpan);
  };

  const handlePurgeDatapoints = (event: CheckboxChangeEvent) => {
    setPurgeDatapoints(event.target.checked);
  };

  return (
    <Modal
      title={t("calculation-flow.calculate")}
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          {t("calculation-flow.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isRunning}
          onClick={handleRun}
          disabled={user.isViewer}
        >
          {t("calculation-flow.calculate")}
        </Button>,
      ]}
    >
      <Form layout="vertical" disabled={user.isViewer}>
        <FormItem label={t("calculation-flow.period")}>
          <Radio.Group onChange={handleDurationChange} value={duration}>
            <Space direction="vertical">
              <Radio value={7 * MILLISECONDS_IN_DAY}>
                {t("calculation-flow.period-options.last-7-days")}
              </Radio>
              <Radio value={31 * MILLISECONDS_IN_DAY}>
                {t("calculation-flow.period-options.last-31-days")}
              </Radio>
              <Radio value={365 * MILLISECONDS_IN_DAY}>
                {t("calculation-flow.period-options.last-365-days")}
              </Radio>
              <Radio value={Number.MAX_SAFE_INTEGER}>
                {t("calculation-flow.period-options.from-beginning-data")}
              </Radio>
              <Radio value={CUSTOM_DURATION}>
                <Space>
                  {t("calculation-flow.period-options.custom")}
                  <RangePicker
                    disabled={duration != CUSTOM_DURATION}
                    value={customTimeSpan}
                    withTime
                    onChange={handleRangePickerChange}
                  />
                </Space>
              </Radio>
            </Space>
          </Radio.Group>
        </FormItem>
        <Divider />
        <Checkbox onChange={handlePurgeDatapoints}>
          {t("calculation-flow.delete-all-datapoins-before-calculation")}
        </Checkbox>
      </Form>
    </Modal>
  );
};

export default RunModal;
