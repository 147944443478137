import { DelayOptions, msToMinutes, OffsetDirection } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { useFormValue } from "../FormContext";
import {
  OffsetMode,
  SelectOffsetType,
} from "../AlarmRuleTypes/common/SelectOffset";
import { useTimeseries } from "./useTimeseriesWithDatapoint";

export function useAlarmTypeDescription({
  timeseriesId,
  unit,
  noBold,
}: {
  unit?: string;
  timeseriesId?: number;
  noBold?: boolean;
}) {
  const t = useTranslations("alarm-details.alarm-types.common.offset");
  const [mode] = useFormValue<OffsetMode>(SelectOffsetType.Mode);
  const [difference] = useFormValue(SelectOffsetType.Value);
  const [direction] = useFormValue<OffsetDirection>(SelectOffsetType.Direction);
  const [delay] = useFormValue<number | undefined>(SelectOffsetType.Delay);

  const { timeseries } = useTimeseries({ timeseriesId });

  return t.rich(`preview.${mode}`, {
    bold: (content) =>
      noBold ? content : <span className="font-bold">{content}</span>,
    offset: () => {
      return `${difference} ${timeseries?.unit ?? unit ?? ""}`;
    },
    direction: () => {
      return t(`preview.direction.${direction}`);
    },
    duration: () => {
      return t(`delay-options.${msToMinutes(delay) as DelayOptions}`);
    },
  });
}
