import { useEffect, useState } from "react";
import { SchemaType, SchemaTypes } from "@properate/common";
import {
  createSchema,
  deleteSchema,
  getSchema,
  listSchemas,
  updateSchema,
} from "@/eepApi";

export function useSchemaByRootAssetId(
  schemaType: SchemaTypes,
  buildingId: number,
) {
  const [data, setData] = useState<SchemaType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    listSchemas(schemaType, { buildingId: buildingId })
      .then((data) => {
        setData(data.items);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [schemaType, buildingId]);

  return {
    data: data?.filter((schema) => !schema.isArchived) as SchemaType[],
    isLoading,
    error,
  };
}

export function useSchema(type: SchemaTypes, id: string) {
  const [data, setData] = useState<SchemaType>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    getSchema(type, id)
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [type, id]);

  return {
    data,
    isLoading,
    error,
  };
}

export async function addSchemaOfType(
  schemaType: SchemaTypes,
  schema: SchemaType,
) {
  return await createSchema(schemaType, schema);
}

export async function updateSchemaOfType(
  schemaType: SchemaTypes,
  id: string,
  schema: Partial<SchemaType>,
) {
  return await updateSchema(schemaType, id, schema);
}

export async function archiveSchema(schemaType: SchemaTypes, id: string) {
  return await deleteSchema(schemaType, id);
}
