import {
  AlarmRuleTypeName,
  OffsetDirection,
  PartialAlarmRule,
} from "@properate/common";
import { useEffect, useState } from "react";
import {
  AlarmDeviationTable,
  DeviationTableColumn,
} from "@/pages/alarms/details/components/AlarmDeviationTable";
import { numberValidator } from "../FormContext/validators";
import { useFormValue } from "../FormContext/hooks";
import {
  getAlarmTimeseries,
  TimeseriesWithAlarmAssetName,
} from "../../utils/getAlarmTimeseries";
import { getSubmitValueEntry } from "../FormContext/utils";
import { requiredSelect, tKey } from "./common/utils";
import {
  getConditionOffsetAlarmRuleFields,
  getSelectOffsetFieldDefinitions,
  getTypeSpecificOffsetAlarmRuleFields,
  OffsetDifference,
  OffsetDirectionSelect,
  OffsetMode,
  ResponseDelaySelect,
  SelectOffsetType,
} from "./common/SelectOffset";
import {
  TimeseriesThresholdAlarmMetaFields,
  TimeseriesThresholdFormFields,
  TimeseriesThresholdTypeSpecificSummary,
} from "./TimeseriesThreshold";
import { SelectSubBuilding } from "./common/SelectSubBuilding";
import { FixedTimeseriesName } from "./common/FixedTimeseriesThresholdDelayOnly";
import { AlarmRuleType } from "./index";

export const TimeseriesThresholdRoomTempDefinition: AlarmRuleType = {
  name: AlarmRuleTypeName.TimeseriesThresholdRoomTemp,
  labelTranslationKey: tKey("timeseries-threshold.room-temp.type-label"),
  getFormFields: (alarmRule) => {
    return {
      [TimeseriesThresholdFormFields.Timeseries]: {
        defaultValue:
          alarmRule?.condition?.type_specific?.base_timeseries_id ?? undefined,
      },
      [TimeseriesThresholdFormFields.SubBuilding]: {
        defaultValue: alarmRule?.sub_building_id ?? undefined,
        getValidator: (t) =>
          numberValidator(
            t(requiredSelect, {
              fieldName: t(
                tKey(`timeseries-threshold.sub-building`),
              ).toLowerCase(),
            }),
          ),
      },
      ...getSelectOffsetFieldDefinitions({
        alarmRule,
        mode: OffsetMode.Threshold,
      }),
    };
  },
  getAlarmRuleFields: ({ entries }): PartialAlarmRule => {
    return {
      sub_building_id: getSubmitValueEntry<number>(
        entries,
        TimeseriesThresholdFormFields.SubBuilding,
      ),
      condition: {
        ...getConditionOffsetAlarmRuleFields({ entries }),
        type_specific: {
          ...getTypeSpecificOffsetAlarmRuleFields({ entries }),
          base_timeseries_id: getSubmitValueEntry<number>(
            entries,
            TimeseriesThresholdFormFields.Timeseries,
          ),
        },
      },
    };
  },
  formComponent: <TimeseriesThresholdRoomTemp />,
  summaryContents: {
    generalAlarmMetaFields: <TimeseriesThresholdAlarmMetaFields />,
    typeSpecificSummary: <TimeseriesThresholdTypeSpecificSummary />,
  },
  deviationsTable: (
    <AlarmDeviationTable
      columns={[
        DeviationTableColumn.Timeframe,
        DeviationTableColumn.Value,
        DeviationTableColumn.Threshold,
        DeviationTableColumn.Notes,
      ]}
      timeseriesIdFormField={TimeseriesThresholdFormFields.Timeseries}
    />
  ),
};

function SelectMaxMin() {
  return (
    <OffsetDirectionSelect
      labelKey={tKey(`timeseries-threshold.select-max-min.label`)}
      labels={{
        [OffsetDirection.BaseGTCompare]: tKey(
          `timeseries-threshold.select-max-min.max`,
        ),
        [OffsetDirection.BaseLTCompare]: tKey(
          `timeseries-threshold.select-max-min.min`,
        ),
      }}
    />
  );
}

function TimeseriesThresholdRoomTemp() {
  const [subBuilding] = useFormValue<number>(
    TimeseriesThresholdFormFields.SubBuilding,
  );
  const [timeseriesId, setTimeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const [maxMin] = useFormValue<OffsetDirection>(SelectOffsetType.Direction);
  const [alarmTimeseries, setAlarmTimeseries] = useState<
    TimeseriesWithAlarmAssetName[]
  >([]);

  function findTimeseries(
    key: keyof TimeseriesWithAlarmAssetName,
    value: unknown,
  ) {
    return (alarmTimeseries ?? []).find((ts) => ts[key] === value);
  }

  const timeseries = findTimeseries("id", timeseriesId);

  useEffect(() => {
    if (subBuilding === undefined || subBuilding === null) {
      return;
    }
    getAlarmTimeseries(subBuilding).then(setAlarmTimeseries);
  }, [subBuilding]);

  useEffect(
    () => {
      let tsName = "";
      if (maxMin === OffsetDirection.BaseGTCompare) {
        tsName = FixedTimeseriesName.RoomTempHigh;
      } else if (maxMin === OffsetDirection.BaseLTCompare) {
        tsName = FixedTimeseriesName.RoomTempLow;
      }
      if (!tsName) {
        return;
      }
      const ts = findTimeseries("name", tsName);
      if (!ts) {
        return;
      }
      setTimeseriesId(ts.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alarmTimeseries, maxMin],
  );

  function renderTimeseriesBasedFields() {
    if (!timeseries) {
      return null;
    }
    return (
      <>
        <OffsetDifference unit={timeseries.unit ?? ""} />
        <ResponseDelaySelect />
      </>
    );
  }

  return (
    <>
      <SelectSubBuilding />
      <SelectMaxMin />
      {renderTimeseriesBasedFields()}
    </>
  );
}
