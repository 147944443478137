import * as React from "react";
import { useEffect, useState } from "react";
import { Skeleton, Tooltip } from "antd";
import dayjs from "@properate/dayjs";
import styled from "styled-components";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { convertUnit } from "@/pages/common/utils";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import {
  formatMeasurementForSchema,
  isValid,
} from "../../../pages/common/SchemaView/TechnicalSchema/utils";
import { useCogniteClient } from "../../../context/CogniteClientContext";

const Alarm = styled.span``;
const getAlarm = (
  value: number,
  alarmType: "warning" | "error",
  unit: string,
  max?: number,
  min?: number,
  minView = `Under ${min}${unit || ""}`,
  maxView = `Over ${max}${unit || ""}`,
) => {
  const aboveMax = typeof max === "number" && value > max;
  const belowMin = typeof min === "number" && value < min;
  if (aboveMax) {
    return (
      <Alarm style={{ color: alarmType === "error" ? "#FF5129" : "#FFD80B" }}>
        {maxView}
      </Alarm>
    );
  } else if (belowMin) {
    return (
      <Alarm style={{ color: alarmType === "error" ? "#FF5129" : "#FFD80B" }}>
        {minView}
      </Alarm>
    );
  }
  return undefined;
};
type Props = {
  id: number;
  min?: number;
  max?: number;
  alarmType?: "warning" | "error";
};

export const KpiValue = ({ id, min, max, alarmType }: Props) => {
  const { client } = useCogniteClient();
  const currentBuildingId = useCurrentBuildingId();
  const { overrideUnits } = useTimeseriesSettings(currentBuildingId);

  const [, setConvertedMin] = useState<number>();
  const [, setConvertedMax] = useState<number>();

  const [value, setValue] = useState<any>();

  useEffect(() => {
    let mounted = true;
    let timeoutId: any = 0;
    const update = async () => {
      try {
        const value = await client.datapoints.retrieveLatest([{ id }]);
        const overrideUnit =
          overrideUnits && overrideUnits[value[0].externalId!]?.unit;
        const valueWithUnit = convertUnit(
          value[0]?.unit,
          overrideUnit,
          value[0].datapoints[0].value as number,
        );
        const dp =
          value[0].datapoints.length > 0
            ? value[0].datapoints[0]
            : { value: undefined, timestamp: undefined };

        const point = {
          ...dp,
          value: valueWithUnit.value,
          unit: valueWithUnit.unit,
        };

        if (mounted) {
          setValue(point);
          setConvertedMin(
            convertUnit(value[0]?.unit, overrideUnit, min!)?.value,
          );
          setConvertedMax(
            convertUnit(value[0]?.unit, overrideUnit, max!)?.value,
          );
          timeoutId = setTimeout(update, 5000);
        }
      } catch (error: any) {
        console.warn(`Update failed - aborting: { id: ${id}`);
        console.warn(error);
      }
    };

    update();

    return () => {
      mounted = false;
      clearTimeout(timeoutId);
    };
  }, [id, client, min, max, overrideUnits]);

  return (
    <>
      <span
        className={`value`}
        style={!value || isValid(value.timestamp) ? {} : { color: "#ffd80b" }}
      >
        {value && isValid(value.timestamp) && formatMeasurementForSchema(value)}{" "}
        <span className="icon" />
        {(value &&
          getAlarm(
            value?.value,
            alarmType || "warning",
            value.unit || "",
            max,
            min,
          )) ||
          ""}
        {value && !isValid(value.timestamp) && (
          <Tooltip
            title={`Siste datapunkt kom for ${dayjs(
              value.timestamp,
            ).fromNow()}`}
          >
            {formatMeasurementForSchema(value)}
          </Tooltip>
        )}
        {!value && <Skeleton.Button active />}
      </span>
    </>
  );
};
