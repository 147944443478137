import { AlarmRule } from "@properate/common";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";

export type GetAlarmRuleRequestData = { ids: string[] };
export type GetAlarmRuleResponseData = Record<string, AlarmRule>;

const functions = getFunctions(getApp(), "europe-west1");
export const getAlarmRules = httpsCallable<
  GetAlarmRuleRequestData,
  GetAlarmRuleResponseData
>(functions, "getAlarmRules");

export type CreateOrUpdateAlarmRuleRequestData = AlarmRule;
export type CreateOrUpdateAlarmRuleResponseData = AlarmRule;

export const createOrUpdateAlarmRule = httpsCallable<
  CreateOrUpdateAlarmRuleRequestData,
  CreateOrUpdateAlarmRuleResponseData
>(functions, "createOrUpdateAlarmRule");

export type DeleteAlarmRuleRequestData = { id: string };
export type DeleteAlarmRuleResponseData = {
  deleted_alarm_id: string;
  deleted_event_ids: number[];
};

export const deleteAlarmRule = httpsCallable<
  DeleteAlarmRuleRequestData,
  DeleteAlarmRuleResponseData
>(functions, "deleteAlarmRule");
