import { Timeseries } from "@cognite/sdk";
import { ReactNode } from "react";
import {
  AnalysisLegendItem,
  LegendDataForGraphLegend,
  useLegendDataForGraphLegend,
} from "@/features/analysis";
import { useGraphHighlightsForEvents } from "./useGraphHighlightsForEvents";
import { useAlarmDetailsTimeSpan } from "./useAlarmDetailsTimeSpan";
import { useSelectedBuildings } from "./useSelectedBuildings";
import { useAlarmActiveTimeSpans } from "./useAlarmActiveTimeSpans";

export function useAlarmGraphLegendData({
  timeseriesList,
}: {
  timeseriesList: (Timeseries | undefined)[];
}): LegendDataForGraphLegend | undefined {
  const timeSpan = useAlarmDetailsTimeSpan();
  const selectedBuildings = useSelectedBuildings(timeseriesList);
  const legendData = useLegendDataForGraphLegend(
    selectedBuildings,
    timeSpan,
    false,
  );
  const { visible, color, toggleVisible } = useGraphHighlightsForEvents();
  const alarmActiveTimeSpans = useAlarmActiveTimeSpans();

  function renderChildren(): ReactNode {
    return (
      <AnalysisLegendItem
        isVisible={visible}
        toggleVisible={toggleVisible}
        fill={color}
        stroke={color}
        labelKey="alarm-details.graph.legend-events"
      />
    );
  }

  if (!legendData) {
    return undefined;
  }

  return {
    ...legendData,
    children: renderChildren(),
    enabledAlarmPeriods: alarmActiveTimeSpans.map(([start, end]) => ({
      start,
      end,
    })),
    visibility: {
      ...legendData.visibility,
    },
  };
}
