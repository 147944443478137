import { useState } from "react";
import { useTranslations } from "@properate/translations";
import { useFormValue } from "../../../FormContext/hooks";
import { SelectTimeseries } from "../../../components/TimeseriesSelector";
import { tKey } from "../../common/utils";
import { ThirdPartyViewState, ThirdPartyAlarmFields } from "../types";
import ViewThirdPartyAlarm from "./ViewThirdPartyAlarm";

export function ThirdPartyAlarmInfo() {
  const t = useTranslations();
  const [baseTimeseriesId] = useFormValue<number | undefined>(
    ThirdPartyAlarmFields.BaseTimeseries,
  );
  const [viewState, setViewState] = useState<ThirdPartyViewState>(
    baseTimeseriesId
      ? ThirdPartyViewState.View
      : ThirdPartyViewState.SelectBaseTimeseries,
  );

  function closeSelector() {
    setViewState(
      baseTimeseriesId
        ? ThirdPartyViewState.View
        : ThirdPartyViewState.SelectBaseTimeseries,
    );
  }

  function getActivateSelector(newViewState: ThirdPartyViewState) {
    return () => {
      setViewState(newViewState);
    };
  }

  switch (viewState) {
    case ThirdPartyViewState.SelectBaseTimeseries:
      return (
        <SelectTimeseries
          close={closeSelector}
          name={ThirdPartyAlarmFields.BaseTimeseries}
          label={t(tKey(`third-party-alarm.select-base`))}
        />
      );
    case ThirdPartyViewState.SelectVisualTimeseries:
      return (
        <SelectTimeseries
          close={closeSelector}
          name={ThirdPartyAlarmFields.AddVisualTimeseriesId}
          label={t(
            tKey(`third-party-alarm.visual-timeseries.select-timeseries`),
          )}
        />
      );
    case ThirdPartyViewState.View:
      return (
        <ViewThirdPartyAlarm
          selectBaseTimeseries={getActivateSelector(
            ThirdPartyViewState.SelectBaseTimeseries,
          )}
          selectVisualTimeseries={getActivateSelector(
            ThirdPartyViewState.SelectVisualTimeseries,
          )}
        />
      );
  }
}
