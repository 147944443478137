import { useUser } from "@properate/auth";
import {
  FunctionComponent,
  memo,
  useEffect,
  useCallback,
  useRef,
  DragEvent,
  useState,
} from "react";
import {
  addEdge,
  Controls,
  Background,
  useReactFlow,
  ReactFlowInstance,
  Node,
  Connection,
  Edge,
  ReactFlow,
  applyNodeChanges,
  applyEdgeChanges,
  EdgeChange,
  NodeChange,
  ReactFlowProvider,
} from "reactflow";
import { Button } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";

import { nodeTypes } from "../nodes/nodeTypes";
import { CalculationFlowGraph } from "../types";
import "reactflow/dist/style.css";
import NodesPopover, { HideableNodeButtonType } from "./NodesPopover";

interface Props {
  graph: CalculationFlowGraph;
  onChange: (calculationFlowGraph: CalculationFlowGraph) => void;
  hiddenNodeButtons: HideableNodeButtonType[];
}

const GraphEditor: FunctionComponent<Props> = ({
  graph,
  onChange,
  hiddenNodeButtons,
}) => {
  const t = useTranslations();
  const reactFlowWrapper = useRef<HTMLInputElement>(null);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance>();
  const { setViewport } = useReactFlow();
  const user = useUser();

  useEffect(() => {
    if (
      rfInstance &&
      JSON.stringify([nodes, edges]) !==
        JSON.stringify([graph.nodes, graph.edges])
    ) {
      onChange(rfInstance.toObject());
    }
    // onChange must be explicitly excluded in depedency list
    // to avoid an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graph, edges, nodes, rfInstance]);

  useEffect(() => {
    setNodes(graph.nodes);
    setEdges(graph.edges);
    setViewport(graph.viewport);
  }, [graph, setEdges, setNodes, setViewport]);

  const handleConnect = (params: Edge | Connection) => {
    // Remove edges that are connected to the same target handle
    setEdges(
      [...edges].filter(
        (e) =>
          !(
            e.target === params.target && e.targetHandle === params.targetHandle
          ),
      ),
    );
    setEdges((eds) => addEdge(params, eds));
  };
  const handleNodesChange = (changes: NodeChange[]) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  };
  const handleEdgesChange = (changes: EdgeChange[]) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  };
  const handleDeleteNode = () =>
    setNodes([...nodes].filter((node) => !node.selected));
  const handleDeleteEdge = () =>
    setEdges([...edges].filter((edge) => !edge.selected));
  const isNodeSelected = () => nodes.filter((node) => node.selected).length > 0;
  const isEdgeSelected = () => edges.filter((edge) => edge.selected).length > 0;

  const onDragOver = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { dropEffect: string };
    }) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    [],
  );

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (!reactFlowWrapper.current) return;
      if (!rfInstance) return;

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const newNode: Node = JSON.parse(
        event.dataTransfer.getData("application/reactflow"),
      );

      const position = rfInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      setNodes((nds) => [
        ...nds.map((n) => ({ ...n, selected: false })),
        { ...newNode, selected: true, position },
      ]);
    },
    [rfInstance, setNodes],
  );

  return (
    <div
      className="reactflow-wrapper"
      ref={reactFlowWrapper}
      style={{
        flexGrow: 1,
        height: "100%",
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onConnect={handleConnect}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onInit={(rf) => setRfInstance(rf)}
        minZoom={0.1}
        fitView
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
      >
        <Controls />
        <Background color="#aaa" gap={16} />
        <div className="node__controls">
          <NodesPopover hiddenNodeButtons={hiddenNodeButtons} />
          <Button
            type="primary"
            onClick={handleDeleteNode}
            icon={<MinusOutlined />}
            disabled={user.isViewer || !isNodeSelected()}
          >
            {t("calculation-flow.remove-node")}
          </Button>
          <Button
            type="primary"
            onClick={handleDeleteEdge}
            icon={<MinusOutlined />}
            disabled={user.isViewer || !isEdgeSelected()}
          >
            {t("calculation-flow.remove-coupling")}
          </Button>
        </div>
      </ReactFlow>
    </div>
  );
};

const GraphEditorWithProvider: FunctionComponent<Props> = ({
  graph,
  onChange,
  hiddenNodeButtons,
}) => {
  return (
    <ReactFlowProvider>
      <GraphEditor
        graph={graph}
        onChange={onChange}
        hiddenNodeButtons={hiddenNodeButtons}
      />
    </ReactFlowProvider>
  );
};

export default memo(GraphEditorWithProvider);
