import { useTranslations } from "@properate/translations";
import { NoteSource } from "./types";

export function NoteSourceText({ source }: { source?: NoteSource }) {
  const t = useTranslations();
  switch (source) {
    case NoteSource.WEB_ALARMS:
      return t("notes.source.web-alarms");
    case NoteSource.WEB_ORG_ALARMS:
      return t("notes.source.web-org-alarms");
    case NoteSource.WEB_ENERGY:
      return t("notes.source.web-energy");
    case NoteSource.WEB_COMPONENTS:
      return t("notes.source.web-components");
    case NoteSource.WEB_ANALYSIS:
      return t("notes.source.web-analysis");
    case NoteSource.WEB_INDOOR_CLIMATE:
      return t("notes.source.web-indoor-climate");
    case NoteSource.WEB_SCHEMA_VIEW:
      return t("notes.source.web-schema-view");
    case NoteSource.WEB_FLOOR_PLAN:
      return t("notes.source.web-floor-plan");
    case NoteSource.WEB_CLOUD_AUTOMATIONS:
      return t("notes.source.web-cloud-automations");
    case NoteSource.WEB_VIRTUAL_SENSORS:
      return t("notes.source.web-virtual-sensors");
    case NoteSource.WEB_SET_POINTS:
      return t("notes.source.web-set-points");
    case NoteSource.WEB_WASTE:
      return t("notes.source.web-waste");
    case NoteSource.UNKNOWN:
    default:
      return t("notes.source.unknown");
  }
}
