import { App, Table } from "antd";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table/interface";
import dayjs from "@properate/dayjs";
import { useTranslations } from "@properate/translations";
import { AuditLog, AuditLogDetails, getAuditLog } from "@/eepApi";
import { isErrorWithMessage } from "@/utils/api";
import { WhiteModal } from "@/pages/writable/elements";

type Props = {
  name: string;
  externalId: string;
  onHide: () => void;
};
export const AuditLogModal = ({ name, externalId, onHide }: Props) => {
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [auditLog, setAuditLog] = useState<AuditLog[]>([]);
  const t = useTranslations();

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        const auditLog = await getAuditLog({ externalId });
        setAuditLog(auditLog);
      } catch (error) {
        if (isErrorWithMessage(error)) {
          const errorMessage = error.message;
          return message.open({
            type: "error",
            content: t("component.audit-log-modal.error-retrieving-history", {
              errorMessage,
            }),
          });
        }
        return message.open({
          type: "error",
          content: t("component.audit-log-modal.error-retrieving-history", {
            errorMessage: isErrorWithMessage(error) ? error.message : "",
          }),
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [externalId, message, t]);
  const translations = {
    setpoint_change: ({
      value,
      unit = "",
      priority,
    }: {
      value?: number;
      unit?: string;
      priority: number;
    }) => {
      const fallbackPriority = priority || 16;
      return value !== undefined
        ? t("component.audit-log-modal.set-point-changed", {
            value,
            unit,
            fallbackPriority,
          })
        : t("component.audit-log-modal.set-point-deleted", {
            fallbackPriority,
          });
    },
  };

  const columns: ColumnsType<AuditLog> = [
    {
      title: t("component.audit-log-modal.table-column-title.name"),
      dataIndex: "user",
      key: "user",
      width: 200,
    },
    {
      title: t("component.audit-log-modal.table-column-title.date"),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (timestamp: number) =>
        dayjs(timestamp).format("DD MMMM YYYY HH:mm"),
      width: 180,
    },
    {
      title: t("component.audit-log-modal.table-column-title.change"),
      dataIndex: "details",
      key: "details",
      render: (details: AuditLogDetails) => {
        const { type, ...params } = details;
        return translations[type](params);
      },
    },
  ];

  return (
    <WhiteModal
      title={t("component.audit-log-modal.title", { name })}
      open
      onCancel={onHide}
      onOk={onHide}
      okText={t("component.audit-log-modal.close")}
      cancelButtonProps={{ style: { display: "none" } }}
      width={800}
    >
      <Table
        columns={columns}
        dataSource={auditLog}
        pagination={false}
        scroll={{ y: 600 }}
        loading={loading}
        size="small"
        rowKey="timestamp"
      />
    </WhiteModal>
  );
};
