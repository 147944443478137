import { useEffect } from "react";
import { TableColumnsType } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslations, MessageKey } from "@properate/translations";
import { useHotkeys } from "react-hotkeys-hook";
import { SortOrder } from "antd/lib/table/interface";
import { sortString } from "@properate/ui";
import { BuildingInfo } from "@/components/Building/BuildingInfo";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { allKPIs, getKPIConfiguration } from "@/utils/kpi";
import { KPI } from "@/utils/types";
import { KPIModal } from "@/components/KPIModal/KPIModal";
import { useHelp } from "@/context/HelpContext";
import { mutateUserSettings } from "@/services/userSettings";
import { useWindowSize } from "@/hooks/useWindowSize";
import { RenderKpiTitle } from "@/pages/common/RenderKpiTitle/RenderKpiTitle";
import { renderKpiValue } from "./utils/renderKPI";
import { BuildingWithKPI } from "./hooks/useBuildingsWithKPIs";
import { VerticallyCenteredContent } from "./elements";

interface Props {
  isKPIModalOpen: boolean;
  setIsKPIModalOpen: (value: boolean) => unknown;
  portfolioSettingsColumns: KPI[];
  data: BuildingWithKPI[];
}

const ESTIMATED_PAGE_HEADER_HEIGHT = 64;
const ESTIMATED_TABLE_HEADER_HEIGHT = 110;
const BUILDING_NAME_COL_WIDTH = 320;
const DEFAULT_COL_WIDTH = 110;

const PortfolioPage = ({
  isKPIModalOpen,
  setIsKPIModalOpen,
  portfolioSettingsColumns,
  data,
}: Props) => {
  const navigate = useNavigate();
  const { width: windowSizeWidth, height: windowSizeHeight } = useWindowSize();
  const t = useTranslations();

  const { setHelp } = useHelp();

  useHotkeys("Control+Shift+s", () => {
    setIsKPIModalOpen(!isKPIModalOpen);
  });

  useEffect(() => {
    setHelp({
      title: t("portfolio-view.help.title"),
      content: t.rich("portfolio-view.help.page-view", {
        p: (text) => <p>{text}</p>,
      }),
    });
  }, [setHelp, t]);
  function getKpiTitle(kpi: KPI) {
    const configuration = getKPIConfiguration(kpi);
    if ("metadata" in configuration && configuration.metadata.unit) {
      return `${t(
        `portfolio-view.kpi.labels.${configuration.asOption.label}` as MessageKey,
      )} [${configuration.metadata.unit}]`;
    }

    return t(
      `portfolio-view.kpi.labels.${configuration.asOption.label}` as MessageKey,
    );
  }

  const columns: TableColumnsType<BuildingWithKPI> = [
    {
      title: t("portfolio-view.table-column.building"),
      key: "building",
      defaultSortOrder: "ascend",
      width: BUILDING_NAME_COL_WIDTH,
      fixed: "left",
      sorter: (
        { building: buildingOne },
        { building: buildingTwo },
        sortDirection,
      ) => {
        if (buildingOne.owner === undefined) {
          if (buildingTwo.owner !== undefined) {
            return sortDirection === "ascend" ? 1 : -1;
          }
          return sortString(buildingOne.name, buildingTwo.name);
        }
        if (buildingTwo.owner === undefined) {
          return sortDirection === "descend" ? 1 : -1;
        }
        return sortString(buildingOne.owner, buildingTwo.owner);
      },
      render: (_, { building, image }) => {
        const [street, place] = building.name.split(",");
        return (
          <BuildingInfo
            street={street}
            place={place.trimStart()}
            alias={building.alias}
            owner={building.owner}
            imageUrl={image}
          />
        );
      },
    },
    ...allKPIs
      // filter away unchecked
      .filter((kpi) => portfolioSettingsColumns.includes(kpi))
      .map((kpi) => ({
        title: <RenderKpiTitle kpi={kpi} />,
        key: kpi,
        width: DEFAULT_COL_WIDTH,
        align: "right" as const,
        sorter: (
          buildingOne: BuildingWithKPI,
          buildingTwo: BuildingWithKPI,
          sortDirection: SortOrder | undefined,
        ) => {
          if (kpi === "certifications") {
            const title = getKpiTitle(kpi);
            const certificationOne = buildingOne.certifications?.find(
              (certification) => certification.type === title,
            );
            const certificationTwo = buildingTwo.certifications?.find(
              (certification) => certification.type === title,
            );
            if (!certificationOne) {
              if (certificationTwo) {
                return sortDirection === "ascend" ? 1 : -1;
              }
              return 0;
            }
            if (!certificationTwo) {
              return sortDirection === "descend" ? 1 : -1;
            }
            if (certificationOne.level[0] === certificationTwo.level[0]) {
              return sortString(
                certificationTwo.level[1],
                certificationOne.level[1],
              );
            }
            return sortString(
              certificationOne.level[0],
              certificationTwo.level[0],
            );
          }

          const kpiValueOne = buildingOne[kpi as keyof BuildingWithKPI];
          const kpiValueTwo = buildingTwo[kpi as keyof BuildingWithKPI];

          if (kpiValueOne === undefined || kpiValueOne === null) {
            if (kpiValueTwo !== undefined && kpiValueTwo !== null) {
              return sortDirection === "ascend" ? 1 : -1;
            }
            return 0;
          }
          if (kpiValueTwo === undefined || kpiValueTwo === null) {
            return sortDirection === "descend" ? 1 : -1;
          }
          return (kpiValueOne as number) - (kpiValueTwo as number);
        },
        render: (_: string, building: BuildingWithKPI) => (
          <VerticallyCenteredContent>
            {renderKpiValue(kpi, building[kpi as keyof BuildingWithKPI])}
          </VerticallyCenteredContent>
        ),
      })),
  ];

  const handleUpdateSelectedValues = async (selectedKPIs: KPI[]) => {
    await mutateUserSettings({
      portfolio: {
        columns: selectedKPIs,
      },
    });
  };

  const tableHeight =
    windowSizeHeight -
    ESTIMATED_PAGE_HEADER_HEIGHT -
    ESTIMATED_TABLE_HEADER_HEIGHT;

  const tableWidth =
    BUILDING_NAME_COL_WIDTH +
    DEFAULT_COL_WIDTH * portfolioSettingsColumns.length;

  return (
    <>
      <KPIModal
        open={isKPIModalOpen}
        title={t("portfolio-view.kpi.title")}
        onHide={() => setIsKPIModalOpen(false)}
        selectedValues={portfolioSettingsColumns}
        onUpdateSelectedValues={handleUpdateSelectedValues}
      />
      <TableWithoutDefaultSort
        virtual
        style={{
          width: windowSizeWidth,
          maxWidth: windowSizeWidth,
          minHeight: tableHeight,
        }}
        rowIsHoverable
        scroll={{
          y: tableHeight,
          x: tableWidth,
        }}
        pagination={false}
        size="small"
        rowKey="key"
        columns={columns}
        dataSource={data}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`/asset/${record.key}/building`);
            },
          };
        }}
      />
    </>
  );
};

export default PortfolioPage;
