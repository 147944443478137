export enum AccessModules {
  alarms = "alarms",
  energy = "energy",
  calendar = "calendar",
  indoorClimate = "indoorClimate",
  roomManagement = "roomManagement",
  technicalSchema = "technicalSchema",
  taskManagement = "taskManagement",
  components = "components",
  virtualSensors = "virtualSensors",
  analysis = "analysis",
  waste = "waste",
  serviceDesk = "serviceDesk",
  cloudAutomation = "cloudAutomation",
  setPoints = "setPoints",
  files = "files",
  thirdParty = "thirdParty",
  timeseries = "timeseries",
}

export const allAccessModules: AccessModules[] = [
  AccessModules.alarms,
  AccessModules.energy,
  AccessModules.calendar,
  AccessModules.indoorClimate,
  AccessModules.roomManagement,
  AccessModules.technicalSchema,
  AccessModules.taskManagement,
  AccessModules.components,
  AccessModules.virtualSensors,
  AccessModules.analysis,
  AccessModules.waste,
  AccessModules.serviceDesk,
  AccessModules.cloudAutomation,
  AccessModules.setPoints,
  AccessModules.files,
  AccessModules.thirdParty,
  AccessModules.timeseries,
];
