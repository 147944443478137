import { AlarmRuleTypeName } from "@properate/common";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { AlarmSettingsFormFields } from "@/pages/alarms/details/AlarmSettingsPage/types";
import { CompareTimeseriesFormFields } from "@/pages/alarms/details/AlarmRuleTypes/CompareTimeseries";
import { TimeseriesThresholdFormFields } from "@/pages/alarms/details/AlarmRuleTypes/TimeseriesThreshold";
import { useThirdPartyAlarmTimeseriesListWithColors } from "@/pages/alarms/details/AlarmRuleTypes/ThirdPartyAlarm/hooks";

function CompareTimeseriesNotesSidebar() {
  const [baseTimeseriesId] = useFormValue<number>(
    CompareTimeseriesFormFields.BaseTimeseries,
  );
  const [comparisonTimeseriesId] = useFormValue<number>(
    CompareTimeseriesFormFields.ComparisonTimeseries,
  );
  return (
    <TimeseriesIdNotesSidebar
      timeseriesIds={new Set([baseTimeseriesId, comparisonTimeseriesId])}
    />
  );
}

function TimeseriesThresholdNotesSidebar() {
  const [timeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  return <TimeseriesIdNotesSidebar timeseriesIds={new Set([timeseriesId])} />;
}

function ThirdPartyAlarmNotesSidebar() {
  const { timeseriesList } = useThirdPartyAlarmTimeseriesListWithColors();
  return (
    <TimeseriesIdNotesSidebar
      timeseriesIds={
        new Set((timeseriesList ?? []).map(({ timeseries }) => timeseries.id))
      }
    />
  );
}

export function TimeseriesIdNotesSidebar({
  timeseriesIds,
}: Readonly<{
  timeseriesIds: Set<number>;
}>) {
  const { dataSetId, name: currentBuildingName } = useCurrentBuilding();
  if (!dataSetId) {
    return null;
  }
  return (
    <NotesSidebar
      assetFilterMode={NotesAssetFilterMode.TimeseriesList}
      noteSource={NoteSource.WEB_ALARMS}
      idSet={timeseriesIds}
      buildings={[{ id: dataSetId, name: currentBuildingName }]}
    />
  );
}

export function AlarmTypeSpecificNotesSidebar() {
  const [alarmType] = useFormValue<AlarmRuleTypeName>(
    AlarmSettingsFormFields.Type,
  );

  switch (alarmType) {
    case AlarmRuleTypeName.CompareTimeseries:
    case AlarmRuleTypeName.CompareTimeseriesPredictedEnergyConsumption:
      return <CompareTimeseriesNotesSidebar />;
    case AlarmRuleTypeName.TimeseriesThreshold:
    case AlarmRuleTypeName.TimeseriesThresholdRoomTemp:
    case AlarmRuleTypeName.TimeseriesThresholdCO2:
    case AlarmRuleTypeName.TimeseriesThresholdVOC:
    case AlarmRuleTypeName.TimeseriesThresholdEffectGuard:
    case AlarmRuleTypeName.MissingData:
    case AlarmRuleTypeName.LeakProtection:
      return <TimeseriesThresholdNotesSidebar />;
    case AlarmRuleTypeName.LockedThirdPartyAlarm:
    case AlarmRuleTypeName.ThirdPartyAlarm:
      return <ThirdPartyAlarmNotesSidebar />;
    case undefined:
      // No sidebar when alarm rule is not yet created, but no logging as this is a valid state
      return null;
    default:
      console.error(`No notes sidebar for alarm rule type: ${alarmType}`);
      return null;
  }
}
