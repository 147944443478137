import useSWR from "swr";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";

export function useFloorPlanPinTimeseries() {
  const pin = useFloorPlanPin();
  const { client } = useCogniteClient();

  return useSWR(
    pin.timeseriesId ? ["timeseries", pin.timeseriesId] : null,
    async ([_, timeseriesId]) =>
      client.timeseries.retrieve([{ id: timeseriesId }]).then((timeseries) => {
        if (timeseries.length > 0) {
          return timeseries[0];
        }
      }),
  );
}
