import { FunctionComponent } from "react";
import { Handle, Position } from "reactflow";
import styled from "styled-components";

export const Node = styled.div`
  width: 200px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.neutral8};
  border: 1px solid #c4c4c4;

  .react-flow__handle {
    background: #c4c4c4;
    width: 12px;
    height: 15px;
    border-radius: 3px;
    transform: translateY(50%);
  }
`;

export const SmallNode = styled(Node)`
  width: 100px;
`;

export const LargeNode = styled(Node)`
  width: 500px;
`;

export const Body = styled.div`
  padding-block: 8px;
`;

export const Header = styled(Body)`
  padding: 8px 10px;
  background-color: ${(props) => props.theme.neutral7};
`;

export const InputHeader = styled(Header)`
  background-color: ${(props) => props.theme.green_40};
`;

export const OutputHeader = styled(Header)`
  background-color: ${(props) => props.theme.accent8};
`;

export const NodeContent = styled.div`
  padding-right: 8px;
  padding-left: 8px;
`;

export const NodeInputLabel = styled.div`
  font-weight: bold;
  width: 100%;
  padding-right: 8px;
`;

export const NodeOutputLabel = styled.div`
  margin-left: auto;
  font-weight: bold;
  padding-left: 8px;
`;

interface OutputProps {
  children?: React.ReactNode;
}

export const NodeOutput: FunctionComponent<OutputProps> = (props) => {
  return (
    <div style={{ display: "flex" }}>
      {props.children}
      <Handle
        type="source"
        position={Position.Right}
        style={{ position: "relative" }}
      ></Handle>
    </div>
  );
};

interface InputProps {
  inputId: string;
  children?: React.ReactNode;
}

export const NodeInput: FunctionComponent<InputProps> = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{ position: "relative" }}
        id={props.inputId}
      />
      <div style={{ width: "100%" }}>{props.children}</div>
    </div>
  );
};
