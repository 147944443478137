import { useUser } from "@properate/auth";
import { FunctionComponent, useState } from "react";
import { Button, Switch, Tooltip, Badge, Avatar, App } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  RightOutlined,
  CaretRightOutlined,
  SettingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import dayjs from "@properate/dayjs";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { ShowEdit } from "../../../components/ShowEdit";
import { CalculationFlow } from "../types";
import { SettingsModal } from "./SettingsModal";
import { CopyButton } from "./CopyButton";

interface Props {
  calculationFlow: CalculationFlow;
  hasUnsavedChanges: boolean;
  onSave: () => void;
  onRun: () => void;
  onChange: (calculationFlow: CalculationFlow) => void;
}

const FlowPageHeader: FunctionComponent<Props> = ({
  calculationFlow,
  hasUnsavedChanges,
  onSave,
  onRun,
  onChange,
}) => {
  const { notification } = App.useApp();
  const currentBuildingId = useCurrentBuildingId();
  const [isSaving, setIsSaving] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const user = useUser();
  const t = useTranslations();

  const handleActiveChange = () => {
    onChange({
      ...calculationFlow,
      frequency: -calculationFlow.frequency,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    await onSave();
    setIsSaving(false);
    notification.success({
      message: t("calculation-flow.virtual-sensor-was-saved"),
    });
  };

  const handleSettingsModalOk = (calculationFlow: CalculationFlow) => {
    setIsSettingsModalVisible(false);
    onChange(calculationFlow);
  };

  return (
    <PageHeader
      title={
        <>
          <Link to={`/asset/${currentBuildingId}/${calculationFlow.type}`}>
            {calculationFlow.type === "virtualSensor"
              ? t("calculation-flow.virtual-sensors")
              : t("calculation-flow.cloud-automation")}
          </Link>
          <RightOutlined />
        </>
      }
      subTitle={
        user.isViewer ? (
          calculationFlow.name
        ) : (
          <ShowEdit
            text={calculationFlow.name}
            onFinish={async (name) => {
              onChange({ ...calculationFlow, name });
            }}
          />
        )
      }
      extra={
        <>
          {calculationFlow.notifications.length > 0 && (
            <Tooltip
              title={
                <div>
                  {calculationFlow.notifications.map((notification, index) => (
                    <div key={index}>{`${dayjs(notification.timestamp).format(
                      "DD. MMMM HH:mm",
                    )}: ${notification.message}`}</div>
                  ))}
                </div>
              }
            >
              <Badge count={calculationFlow.notifications.length}>
                <Avatar
                  shape="square"
                  style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                  size="large"
                  icon={<WarningOutlined />}
                />
              </Badge>
            </Tooltip>
          )}
          {t("calculation-flow.active")}:
          <Switch
            onChange={handleActiveChange}
            checked={calculationFlow.frequency > 0}
            disabled={user.isViewer}
          />
          <Button
            icon={<SettingOutlined />}
            onClick={() => setIsSettingsModalVisible(true)}
            disabled={user.isViewer}
          />
          <CopyButton calculationFlow={calculationFlow} />
          <Tooltip
            title={
              !hasUnsavedChanges
                ? t("calculation-flow.no-changes-to-save")
                : null
            }
          >
            <Button
              type="primary"
              onClick={handleSave}
              loading={isSaving}
              disabled={user.isViewer || !hasUnsavedChanges}
            >
              {t("calculation-flow.save")}
            </Button>
          </Tooltip>
          {calculationFlow.type === "virtualSensor" && (
            <Button
              type="primary"
              onClick={onRun}
              icon={<CaretRightOutlined />}
              disabled={user.isViewer}
            >
              {t("calculation-flow.calculate")}
            </Button>
          )}
          <ToggleSidebarButton
            hiddenWhenSidebarVisible
            sidebarHiddenContent={t("notes.show-notes-button")}
          />
          <SettingsModal
            open={isSettingsModalVisible}
            calculationFlow={calculationFlow}
            onCancel={() => setIsSettingsModalVisible(false)}
            onOk={handleSettingsModalOk}
          />
        </>
      }
    />
  );
};

export default FlowPageHeader;
