import { useUser } from "@properate/auth";
import { Node as ReactFlowNode, useReactFlow } from "reactflow";
import { Select, Form } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  Node,
  NodeInput,
  NodeOutput,
  Header,
  Body,
  NodeOutputLabel,
  NodeInputLabel,
  NodeContent,
} from "./helpers/NodeComponents";

const { Option } = Select;
const FormItem = Form.Item;

const windowSizes = ["hour", "day", "week", "month", "year"];
const windowTypes = ["fixed", "rolling"];

interface HistoricMaxNodeProps {
  operationId: string;
  windowSize: string;
  windowType: string;
}

export const getEmptyHistoricMaxNode =
  (): ReactFlowNode<HistoricMaxNodeProps> => {
    return {
      id: getNodeId("historicMax"),
      type: "historicMax",
      data: {
        operationId: "historic_max",
        windowSize: "day",
        windowType: "fixed",
      },
      position: {
        x: 0,
        y: 0,
      },
    };
  };

function HistoricMaxNode(params: { id: string; data: HistoricMaxNodeProps }) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();
  const user = useUser();

  const handleChangeWindowSize = (windowSize: string) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "windowSize",
      windowSize,
    );
  };

  const handleChangeWindowType = (windowType: string) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "windowType",
      windowType,
    );
  };

  return (
    <Node>
      <Header>
        {t("calculation-flow.node-types.historical-max-over-calendar-period")}
      </Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeContent>
          <Form layout="vertical" disabled={user.isViewer}>
            <FormItem label={t("calculation-flow.node-types.window-size")}>
              <Select
                showAction={["focus", "click"]}
                value={params.data.windowSize}
                style={{ width: "100%" }}
                onChange={handleChangeWindowSize}
              >
                {windowSizes.map((size) => (
                  <Option key={size} value={size}>
                    {t(`calculation-flow.window-sizes.${size}` as MessageKey)}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem label={t("calculation-flow.node-types.window-type")}>
              <Select
                showAction={["focus", "click"]}
                value={params.data.windowType}
                style={{ width: "100%" }}
                onChange={handleChangeWindowType}
              >
                {windowTypes.map((type) => (
                  <Option key={type} value={type}>
                    {t(`calculation-flow.window-sizes.${type}` as MessageKey)}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Form>
        </NodeContent>
        <NodeOutput>
          <NodeOutputLabel>
            {t("calculation-flow.node-types.window-size-description")}
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </Node>
  );
}

export default HistoricMaxNode;
