import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import {
  getSystemCodeFromExternalId,
  formatSubBuildingFromExternalId,
} from "@properate/common";
import { CogniteClient } from "@cognite/sdk";
import { AimOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { useSchema } from "@/services/newSchemas";
import { ACCENT2 } from "@/utils/ProperateColors";
import { processImage, SchemaView } from "../common/SchemaView/SchemaView";
import { useHelp } from "../../context/HelpContext";
import { getAssetsWithLabels } from "../../utils/helpers";
import { useCogniteClient } from "../../context/CogniteClientContext";
import { Sensor } from "../common/SchemaView/TechnicalSchema/Sensor";
import { useBuildingPageTitle } from "../../hooks/usePageTitle";

export const TechnicalSchemaView = () => {
  const t = useTranslations();
  useBuildingPageTitle(t("floor-plan.technical-schema-view.title"));
  const { client } = useCogniteClient();
  const { id, fileId, view }: any = useParams();
  const [buildings, setBuildings] = useState<string[]>([]);
  const [systems, setSystems] = useState<string[]>([]);
  const { setHelp } = useHelp();
  const [image, setImage] = useState<HTMLElement>();
  const { data: schema, error } = useSchema("technicalSchema", fileId);

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    setHelp({
      title: t("floor-plan.technical-schema-view.title"),
      content: t.rich("floor-plan.technical-schema-view.help.view-page", {
        p: (text) => <p>{text}</p>,
      }),
    });
  }, [setHelp, t]);

  useEffect(() => {
    const get = async (client: CogniteClient) => {
      const assets = [
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "ventilation_unit",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "cooling_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "heating_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "sanitary_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "process_cooling",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "outdoor_heating_system",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "low_voltage_supply",
        ])),
        ...(await getAssetsWithLabels(client, parseInt(id), [
          "cooling_production",
        ])),
      ];

      setSystems(
        assets
          .reduce<string[]>((acc, asset) => {
            const system = getSystemCodeFromExternalId(asset.externalId!);
            if (system.length > 0 && acc.indexOf(system) === -1) {
              acc.push(system);
            }
            return acc;
          }, [])
          .sort((a, b) => {
            const aParts = a.split(".");
            const bParts = b.split(".");
            return (
              aParts[0].localeCompare(bParts[0]) ||
              (aParts.length > 1 ? aParts[1] : "").localeCompare(
                bParts.length > 1 ? bParts[1] : "",
              )
            );
          }),
      );

      const subBuildings = assets?.reduce((prev, current) => {
        const subBuilding = formatSubBuildingFromExternalId(
          current.externalId!,
        );
        if (subBuilding !== "Common" && !prev.includes(subBuilding)) {
          return [...prev, subBuilding];
        }
        return prev;
      }, [] as string[]);
      setBuildings(subBuildings);
    };

    get(client);
  }, [client, id]);

  useEffect(() => {
    if (schema) {
      setImage(processImage(schema.image));
    }
  }, [schema]);

  return (
    <>
      <SchemaView
        title={t("floor-plan.technical-schema-view.title")}
        id={id}
        fileId={fileId}
        type="technicalSchema"
        view={view}
        systems={systems}
        buildings={buildings}
        availableSensors={[
          <Sensor
            name={t("floor-plan.technical-schema-view.sensor-gauge-general")}
            icon={<AimOutlined />}
            type="gauge-general"
            key="gauge-general"
          />,
          <Sensor
            color={ACCENT2}
            name={t(
              "floor-plan.technical-schema-view.sensor-set-point-general",
            )}
            icon={<AimOutlined />}
            type="set-point-general"
            key="set-point-general"
          />,
        ]}
        schema={schema}
        image={image}
        setImage={setImage}
      />
    </>
  );
};
