import { ExternalId } from "@cognite/sdk";
import { Button, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import TimeSeriesGraphButton from "./timeseriesGraphButton";

interface Props {
  externalIds: ExternalId[];
  onChange: (externalIds: ExternalId[]) => void;
}

function TimeSeriesTable({ externalIds, onChange }: Props) {
  const t = useTranslations();
  const handleDelete = (toDelete: ExternalId) => {
    const filteredExternalIds = externalIds.filter(
      (externalId) => externalId !== toDelete,
    );
    onChange(filteredExternalIds);
  };

  const columns = [
    {
      title: t("timeseries.ids"),
      dataIndex: "externalId",
      width: "auto",
      render: (_: any, externalId: ExternalId) => {
        return <>{externalId.externalId}</>;
      },
    },
    {
      render: (_: any, externalId: ExternalId) => {
        return (
          <Space>
            <TimeSeriesGraphButton
              externalId={externalId}
              noExternalIdTooltip={""}
            />
            <Button
              onClick={() => handleDelete(externalId)}
              icon={<DeleteOutlined />}
              size="small"
            />
          </Space>
        );
      },
    },
  ];

  return (
    <TableWithoutDefaultSort
      bordered
      dataSource={externalIds}
      columns={columns}
      rowClassName="editable-row"
      pagination={false}
    />
  );
}

export default TimeSeriesTable;
