import { useUser } from "@properate/auth";
import { AutoComplete, Button, Input, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FacetDistribution } from "meilisearch";
import { useState } from "react";
import { useTranslations } from "@properate/translations";
import { useFacets } from "@/pages/Timeseries/useFacets";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { MeterType } from "@/components/MeterSelectionModal/types";
import { ContrastSearchInput } from "./elements";

interface Props {
  subBuildingFilter: string | null;
  systemFilter: string | null;
  translatedLabelsFilter: string[];
  facetDistribution: FacetDistribution | null;
  onChangeSubBuildingFilter: (value: string) => unknown;
  onChangeSystemFilter: (value: string | null) => unknown;
  onChangeTranslatedLabelsFilter: (value: string[]) => unknown;
  onSearch: (value: string) => unknown;
  onClickAddMeter: (meterType: MeterType) => unknown;
}

export function TimeseriesTableHeader({
  subBuildingFilter,
  translatedLabelsFilter,
  facetDistribution,
  onChangeSubBuildingFilter,
  onChangeSystemFilter,
  onChangeTranslatedLabelsFilter,
  onSearch,
  onClickAddMeter,
}: Props) {
  const user = useUser();
  const currentBuilding = useCurrentBuilding();
  const [systemQuery, setSystemQuery] = useState<string>("");
  const t = useTranslations();

  const { data: systemFacets } = useFacets(
    "system",
    {
      buildingId: currentBuilding.id,
      subBuilding: subBuildingFilter,
      system: null,
      translatedLabels: translatedLabelsFilter,
    },
    undefined,
    systemQuery,
  );

  return (
    <Space>
      <Select
        key="selectedBuilding"
        placeholder={t("timeseries.toolbar.filter-by-building-placeholder")}
        value={subBuildingFilter}
        onChange={onChangeSubBuildingFilter}
        style={{ width: 150 }}
        allowClear
      >
        {facetDistribution?.subBuilding &&
          Object.entries(facetDistribution.subBuilding)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((building) => (
              <Select.Option value={building[0]} key={building[0]}>
                {building[0]} ({building[1]})
              </Select.Option>
            ))}
      </Select>
      <AutoComplete
        key="selectedSystems"
        placeholder={t("timeseries.toolbar.filter-by-system-placeholder")}
        options={
          systemFacets &&
          Object.entries(systemFacets)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((system) => {
              return {
                value: system[0],
                label: `${system[0]} (${system[1]})`,
              };
            })
        }
        style={{
          width: 150,
        }}
        onSelect={onChangeSystemFilter}
        onSearch={(text) => setSystemQuery(text)}
        allowClear
        onClear={() => {
          setSystemQuery("");
          onChangeSystemFilter(null);
        }}
      >
        <Input />
      </AutoComplete>
      <Select
        key="selectLabels"
        mode="multiple"
        placeholder={t("timeseries.toolbar.filter-by-labels-placeholder")}
        value={translatedLabelsFilter}
        onChange={onChangeTranslatedLabelsFilter}
        style={{ width: 360 }}
        allowClear
      >
        {facetDistribution?.translatedLabels &&
          Object.entries(facetDistribution.translatedLabels)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((label) => (
              <Select.Option value={label[0]} key={label[0]}>
                {label[0]} ({label[1]})
              </Select.Option>
            ))}
      </Select>
      <ContrastSearchInput
        key="search"
        placeholder={t("timeseries.toolbar.search-placeholder")}
        data-testid="search-timeseries"
        allowClear
        style={{ width: 300 }}
        onChange={(event) => onSearch(event.target.value)}
      />
      <>
        <Button
          icon={<PlusOutlined />}
          disabled={user.isViewer}
          onClick={() => onClickAddMeter("energy")}
        >
          {t("timeseries.toolbar.add-energy-meter-label")}
        </Button>
        <Button
          icon={<PlusOutlined />}
          disabled={user.isViewer}
          onClick={() => onClickAddMeter("water")}
        >
          {t("timeseries.toolbar.add-water-meter-label")}
        </Button>
      </>
    </Space>
  );
}
