import useSWR from "swr";
import { formatMeasurement } from "@properate/common";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";
import { useFloorPlanPinConfig } from "../FloorPlanMapPinConfigContext";

export function useFloorPlanPinTimeseriesLatestValue() {
  const pin = useFloorPlanPin();
  const cogniteClient = useProperateCogniteClient();
  const config = useFloorPlanPinConfig();

  const values = useSWR(
    pin.timeseriesId ? ["timeseries-latest-value", pin.timeseriesId] : null,
    ([_, id]) => cogniteClient.getLatestValue(id),
  );

  const unit = config?.unit ?? values.data?.unit;
  const latestDatapoint = values.data?.datapoints?.[0];
  let formattedValue = "";

  if (latestDatapoint) {
    formattedValue =
      typeof latestDatapoint.value === "number"
        ? formatMeasurement({ value: latestDatapoint.value, unit })
        : `${latestDatapoint} ${unit}`;
  }

  return {
    unit,
    latestDatapoint,
    formattedValue,
    isLoading: values.isLoading,
    error: values.error,
  };
}
