import { MouseEvent, useEffect, useRef, useState } from "react";
import { Position } from "@sasza/react-panzoom/types/types";
import styled from "styled-components";
import { message } from "antd";
import {
  useFloorPlanMapApi,
  useFloorPlanMapMoveHandlers,
} from "./FloorPlanMapContext";
import { useFloorPlanBackground } from "./hooks/useFloorPlanBackground";
import { useFloorPlan } from "./FloorPlanContext";

export function FloorPlanMapMiniMap() {
  return (
    <div className="absolute right-4 top-4">
      <FloorPlanMiniMapImpl />
    </div>
  );
}

function FloorPlanMiniMapImpl() {
  const miniMapRef = useRef<HTMLDivElement>(null);
  const focalPointRef = useRef<HTMLDivElement>(null);
  const cover = useFloorPlanBackground();
  const floorPlan = useFloorPlan();
  const mapApi = useFloorPlanMapApi();
  const moveHandlers = useFloorPlanMapMoveHandlers();

  const [mouseOffset, setMouseOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const BG_WIDTH = floorPlan.background.width;
  const BG_HEIGHT = floorPlan.background.height;

  const WIDTH = 192;
  const HEIGHT = (WIDTH / BG_WIDTH) * BG_HEIGHT;

  useEffect(() => {
    const focalPoint = focalPointRef.current;
    const mapContainer = mapApi?.childNode?.parentNode as HTMLElement | null;

    if (!mapApi || !focalPoint || !mapContainer) {
      return; // Not ready yet
    }

    type HandleMoveFn = (args: { position: Position; zoom: number }) => void;

    const handleMove: HandleMoveFn = ({ position, zoom }) => {
      const mapRect = mapContainer.getBoundingClientRect();

      const visibleWidth = BG_WIDTH * zoom;
      const visibleHeight = BG_HEIGHT * zoom;

      const maxOffsetX = Math.max(1, visibleWidth - mapRect.width);
      const maxOffsetY = Math.max(1, visibleHeight - mapRect.height);

      const focalPointWidth = Math.min(
        WIDTH,
        (WIDTH * mapRect.width) / visibleWidth,
      );

      const focalPointHeight = Math.min(
        HEIGHT,
        (HEIGHT * mapRect.height) / visibleHeight,
      );

      if (position.x === 0) {
        focalPoint.style.left = "0";
      } else {
        const left = Math.min(
          Math.max(
            0,
            Math.abs((position.x / maxOffsetX) * (WIDTH - focalPointWidth)),
          ),
          WIDTH - focalPointWidth,
        );

        focalPoint.style.left = `${left}px`;
      }

      if (position.y === 0) {
        focalPoint.style.top = "0";
      } else {
        const top = Math.min(
          Math.max(
            0,
            Math.abs((position.y / maxOffsetY) * (HEIGHT - focalPointHeight)),
          ),
          HEIGHT - focalPointHeight,
        );

        focalPoint.style.top = `${top}px`;
      }

      focalPoint.style.width = `${focalPointWidth}px`;
      focalPoint.style.height = `${focalPointHeight}px`;
    };

    moveHandlers.add(handleMove);

    handleMove({
      position: mapApi.getPosition(),
      zoom: mapApi.getZoom(),
    });

    return () => {
      moveHandlers.delete(handleMove);
    };
  }, [mapApi, moveHandlers, BG_HEIGHT, BG_WIDTH, HEIGHT, WIDTH]);

  function handleMouseDown(event: MouseEvent<HTMLDivElement>) {
    if (focalPointRef.current) {
      event.preventDefault();

      const rect = focalPointRef.current.getBoundingClientRect();

      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      setIsDragging(true);
      setMouseOffset({ x, y });
    }
  }

  function handleMouseMove(event: MouseEvent<HTMLDivElement>) {
    if (!isDragging) {
      return;
    }

    const map = mapApi?.childNode?.parentNode as HTMLElement | null;
    const container = miniMapRef.current;
    const focalPoint = focalPointRef.current;

    if (mapApi && map && focalPoint && container) {
      const mapRect = map.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();
      const focalPointRect = focalPoint.getBoundingClientRect();

      const deltaX = Math.max(
        0,
        Math.min(
          event.clientX - containerRect.left - mouseOffset.x,
          containerRect.width - focalPointRect.width,
        ),
      );

      const deltaY = Math.max(
        0,
        Math.min(
          event.clientY - containerRect.top - mouseOffset.y,
          containerRect.height - focalPointRect.height,
        ),
      );

      const zoom = mapApi.getZoom();
      const visibleWidth = BG_WIDTH * zoom;
      const visibleHeight = BG_HEIGHT * zoom;

      const maxOffsetX = Math.max(1, visibleWidth - mapRect.width);
      const maxOffsetY = Math.max(1, visibleHeight - mapRect.height);

      if (maxOffsetX && maxOffsetY) {
        const positionX =
          (deltaX * maxOffsetX) / (containerRect.width - focalPointRect.width);
        const positionY =
          (deltaY * maxOffsetY) /
          (containerRect.height - focalPointRect.height);

        mapApi.setPosition(-positionX, -positionY);
      }
    }
  }

  function handleMouseUp() {
    setIsDragging(false);
  }

  return (
    <MiniMap
      ref={miniMapRef}
      style={{
        position: "relative",
        outline: "1px solid rgba(0, 0, 0, 0.5)",
        backgroundImage: `url(${cover.data})`,
        backgroundSize: "contain",
        width: WIDTH,
        height: HEIGHT,
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <div
        ref={focalPointRef}
        style={{
          position: "absolute",
          outline: "1px solid red",
          boxSizing: "border-box",
          width: "10%",
          height: "10%",
          top: 0,
          left: 0,
          pointerEvents: "none",
        }}
      />
    </MiniMap>
  );
}

const MiniMap = styled.div`
  :hover {
    transform: scale(2) translateX(-25%) translateY(25%);
  }
`;
