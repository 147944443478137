import Supercluster, { ClusterFeature, PointFeature } from "supercluster";
import { useEffect, useMemo, useState } from "react";
import { Position } from "@sasza/react-panzoom/types/types";
import { Element } from "@sasza/react-panzoom";
import { useFloorPlan, useFloorPlanEditMode } from "./FloorPlanContext";
import {
  MoveHandler,
  useFloorPlanMapApi,
  useFloorPlanMapMoveHandlers,
} from "./FloorPlanMapContext";
import { Pin } from "./types";
import { FloorPlanMapPin } from "./FloorPlanMapPin";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";

export function FloorPlanMapPinCluster() {
  const floorPlan = useFloorPlan();
  const mapApi = useFloorPlanMapApi();
  const moveHandlers = useFloorPlanMapMoveHandlers();

  type Clusters = Array<PointFeature<Pin> | ClusterFeature<Pin>>;

  const [, setClusters] = useState<Clusters>([]);
  const supercluster = useMemo(() => new Supercluster(), []);

  useEffect(() => {
    supercluster.load(
      floorPlan.pins.map((pin) => ({
        type: "Feature",
        properties: pin,
        geometry: {
          type: "Point",
          coordinates: [pin.position.x, pin.position.y],
        },
      })),
    );
  }, [supercluster, floorPlan.pins]);

  useEffect(() => {
    const handler: MoveHandler = () => {
      // todo
    };

    if (mapApi) {
      handler({
        position: mapApi.getPosition(),
        zoom: mapApi.getZoom(),
      });
    }

    moveHandlers.add(handler);

    return () => {
      moveHandlers.delete(handler);
    };
  }, [moveHandlers, supercluster, setClusters, mapApi]);

  return (
    <>
      {floorPlan.pins.map((pin) => (
        <PanZoomPin key={pin.id} pin={pin}>
          <FloorPlanMapPin key={pin.id} pin={pin} />
        </PanZoomPin>
      ))}
    </>
  );
}

type PanZoomPinProps = {
  pin: Pin;
  children: React.ReactNode;
};

function PanZoomPin(props: PanZoomPinProps) {
  const [isEditing] = useFloorPlanEditMode();
  const updateFloorPlan = useFloorPlanUpdate();

  function handleMouseUp(pos: Position & Object) {
    const hasMoved =
      pos.x !== props.pin.position.x || pos.y !== props.pin.position.y;

    if (hasMoved) {
      updateFloorPlan.trigger({
        pins: {
          remove: props.pin,
          insert: [{ ...props.pin, position: { x: pos.x, y: pos.y } }],
        },
      });
    }
  }

  return (
    <Element
      id={props.pin.id}
      x={props.pin.position.x}
      y={props.pin.position.y}
      onMouseUp={handleMouseUp}
      disabledMove={!isEditing}
    >
      {props.children}
    </Element>
  );
}
