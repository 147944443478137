import { useEffect, useState } from "react";
import { formatSubBuildingFromExternalId } from "@properate/common";
import { Select, Spin } from "antd";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { getAssetsWithLabels } from "@/utils/helpers";
import { useFormValue } from "../../FormContext/hooks";
import { TimeseriesThresholdFormFields } from "../TimeseriesThreshold";
import { useTimeseriesWithDatapoint } from "../../hooks";
import { FormContextItem } from "../../FormContext";
import { tKey } from "./utils";

type SubBuilding = {
  name: string;
  id: number;
};

export function SelectSubBuilding() {
  const currentBuildingId = useCurrentBuildingId();
  const { client } = useCogniteClient();
  const [timeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const [subBuilding, setSubBuilding] = useFormValue<number>(
    TimeseriesThresholdFormFields.SubBuilding,
  );
  const { timeseries } = useTimeseriesWithDatapoint({ timeseriesId });
  const [subBuildings, setSubBuildings] = useState<SubBuilding[]>([]);

  useEffect(() => {
    getAssetsWithLabels(client, currentBuildingId, ["building"]).then(
      (newSubBuildings): void => {
        setSubBuildings(
          newSubBuildings.map((asset) => ({
            name:
              formatSubBuildingFromExternalId(asset.externalId ?? "") || "--",
            id: asset.id,
          })),
        );
      },
    );
  }, [client, currentBuildingId]);

  useEffect(
    () => {
      if (!timeseries?.externalId) {
        return;
      }
      if (subBuilding !== undefined) {
        return;
      }
      const subBuildingName = formatSubBuildingFromExternalId(
        timeseries.externalId,
      );
      const tsSubBuilding = subBuildings.find(
        ({ name }) => name === subBuildingName,
      );
      if (tsSubBuilding) {
        setSubBuilding(tsSubBuilding.id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeseries, subBuildings, subBuilding],
  );

  if (subBuildings.length === 0) {
    return <Spin />;
  }

  return (
    <FormContextItem
      id={TimeseriesThresholdFormFields.SubBuilding}
      labelKey={tKey(`timeseries-threshold.select-sub-building`)}
      antdInput
      inputProps={{
        showSearch: true,
        options: subBuildings,
        optionFilterProp: "name",
        fieldNames: { label: "name", value: "id" },
      }}
    >
      <Select />
    </FormContextItem>
  );
}
