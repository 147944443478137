import { useTranslations } from "@properate/translations";
import { Empty, Input } from "antd";
import {
  AlertRecipient,
  getContactFromPhoneOrEmail,
  getPhoneOrEmailFromContact,
} from "@properate/common";
import { useFormContext, useFormValue } from "../FormContext/hooks";
import { FormContextActionType, FormContextItem } from "../FormContext";
import { AlarmNotificationFormFields } from "./types";
import { ButtonVariant, NotificationRow } from "./NotificationRow";

const tBase = "alarm-details.notifications-page.recipients";

function NoRecipients() {
  const t = useTranslations(tBase);
  const [recipients] = useFormValue<AlertRecipient[]>(
    AlarmNotificationFormFields.Recipients,
  );
  if (recipients?.length > 0) {
    return null;
  }
  return (
    <div className={"w-full flex flex-row justify-center items-center"}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="" />
      <p className="opacity-50">{t(`empty-message`)}</p>
    </div>
  );
}

function Recipients() {
  const [recipients, setRecipients] = useFormValue<AlertRecipient[]>(
    AlarmNotificationFormFields.Recipients,
  );

  if (!recipients || recipients.length === 0) {
    return null;
  }

  return recipients.map((recipient, index) => (
    <NotificationRow
      key={`inactive-period-${index}`}
      colOne={<span>{recipient.name}</span>}
      colTwo={<span>{getContactFromPhoneOrEmail(recipient)}</span>}
      buttonVariant={ButtonVariant.Remove}
      onClick={() => setRecipients(recipients.toSpliced(index, 1))}
    />
  ));
}

function AddRecipient() {
  const t = useTranslations(tBase);
  const [recipients, setRecipients] = useFormValue<AlertRecipient[]>(
    AlarmNotificationFormFields.Recipients,
  );
  const [recipientName, setRecipientName] = useFormValue<string>(
    AlarmNotificationFormFields.RecipientName,
  );
  const [recipientContact, setRecipientContact] = useFormValue<string>(
    AlarmNotificationFormFields.RecipientContact,
  );
  const { dispatch } = useFormContext();

  function handleAddRecipient() {
    if (!(recipientName ?? "").trim() || !(recipientContact ?? "").trim()) {
      dispatch({
        type: FormContextActionType.validateFields,
        ids: [
          AlarmNotificationFormFields.RecipientName,
          AlarmNotificationFormFields.RecipientContact,
        ],
      });
      return;
    }

    setRecipients([
      ...(recipients ?? []),
      {
        name: recipientName,
        muted: false,
        ...getPhoneOrEmailFromContact(recipientContact),
      },
    ]);
    setRecipientName("");
    setRecipientContact("");
  }

  return (
    <NotificationRow
      outerClassName="gap-2"
      innerClassName="gap-2"
      colOne={
        <FormContextItem
          labelKey={`${tBase}.aria-name`}
          id={AlarmNotificationFormFields.RecipientName}
          labelSrOnly
        >
          <Input size="small" placeholder={t(`name`)} />
        </FormContextItem>
      }
      colTwo={
        <FormContextItem
          labelKey={`${tBase}.aria-contact`}
          id={AlarmNotificationFormFields.RecipientContact}
          labelSrOnly
        >
          <Input size="small" placeholder={t(`contact`)} />
        </FormContextItem>
      }
      onClick={handleAddRecipient}
      buttonVariant={ButtonVariant.Add}
    />
  );
}

export function Recipient() {
  const t = useTranslations(tBase);
  return (
    <div className="w-full flex flex-col gap-2">
      <h2 className="m-0">{t(`header`)}</h2>
      <NoRecipients />
      <Recipients />
      <AddRecipient />
    </div>
  );
}
