import useSWRMutation from "swr/mutation";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  runTransaction,
} from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { message } from "antd";
import { useTranslations } from "@properate/translations";
import { useFloorPlan } from "../FloorPlanContext";
import { FloorPlan } from "../types";

export function useFloorPlanUpdate() {
  const t = useTranslations();
  const floorPlan = useFloorPlan();

  type Extra = {
    arg: {
      name?: FloorPlan["name"];
      subBuilding?: FloorPlan["subBuilding"];
      pins?: {
        insert?: FloorPlan["pins"];
        remove?: FloorPlan["pins"][number];
      };
    };
  };

  return useSWRMutation(
    [floorPlan.snapshotId],
    async ([snapshotId], extra: Extra) => {
      const { pins, ...patch } = extra.arg;
      const ref = doc(collection(browserFirestore, "floor-plans"), snapshotId);

      await runTransaction(browserFirestore, async (trx) => {
        trx.update(ref, patch);

        if (pins) {
          if (pins.remove) {
            trx.update(ref, { pins: arrayRemove(pins.remove) });
          }

          if (pins.insert) {
            trx.update(ref, { pins: arrayUnion(...pins.insert) });
          }
        }
      });
    },
    {
      onSuccess() {
        message.success(t("floor-plan-v2.messages.updated"));
      },
      onError() {
        message.error(t("floor-plan-v2.messages.updated-failed"));
      },
    },
  );
}
