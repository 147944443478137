import { PanZoomWithCover as PanZoom } from "@sasza/react-panzoom";
import { RefObject, useEffect, useRef, useState } from "react";
import { PanZoomApi } from "@sasza/react-panzoom/types/types";
import { cn } from "@properate/ui";
import styled from "styled-components";
import { FloorPlanMapProvider, MoveHandler } from "./FloorPlanMapContext";
import { FloorPlanMapPinConfigProvider } from "./FloorPlanMapPinConfigContext";
import { useFloorPlanBackground } from "./hooks/useFloorPlanBackground";
import { FloorPlanMapPinDropArea } from "./FloorPlanMapDnD";
import { FloorPlanMapMiniMap } from "./FloorPlanMapMiniMap";
import { FloorPlanMapPinCluster } from "./FloorPlanMapPinCluster";
import { useFloorPlan } from "./FloorPlanContext";

const Container = styled.div`
  cursor: grab;

  .react-panzoom-with-cover--grabbing-in {
    cursor: grabbing;
  }
`;

export function FloorPlanMap() {
  const panZoomRef = useRef<PanZoomApi>(null);
  const cover = useFloorPlanBackground();
  const [moveHandlers] = useState(new Set<MoveHandler>());
  const [isCoverLoaded, setIsCoverLoaded] = useState(false);

  useZoomChangesOnResize(isCoverLoaded, panZoomRef);

  const handleMove: MoveHandler = (params) => {
    moveHandlers.forEach((handler) => {
      handler(params);
    });
  };

  if (cover.data) {
    return (
      <Container
        className={cn("w-full h-full overflow-hidden relative bg-[#CCCDC8]", {
          "opacity-0": !isCoverLoaded,
        })}
      >
        <FloorPlanMapProvider apiRef={panZoomRef} moveHandlers={moveHandlers}>
          <FloorPlanMapPinConfigProvider>
            <FloorPlanMapPinDropArea>
              <PanZoom
                ref={panZoomRef}
                cover={cover.data}
                onCoverLoad={() => setIsCoverLoaded(true)}
                onContainerPositionChange={handleMove}
                onContainerZoomChange={handleMove}
              >
                <FloorPlanMapPinCluster />
              </PanZoom>
              <FloorPlanMapMiniMap />
            </FloorPlanMapPinDropArea>
          </FloorPlanMapPinConfigProvider>
        </FloorPlanMapProvider>
      </Container>
    );
  }

  return null;
}

/**
 * Ensure we can zoom out to fit the map in the container. This is done via the
 * `zoomMin` option on the PanZoom API, and we need to recompute this value when
 * the container size changes, which happens when the window is resized or the
 * sidebar is collapsed.
 *
 * @note This also comes with a patch on the `@sasza/react-panzoom` package, which
 *       applies this new `zoomMin` logic to the `PanZoomWithCover` component.
 */
function useZoomChangesOnResize(
  isCoverLoaded: boolean,
  panZoomRef: RefObject<PanZoomApi>,
) {
  const floorPlan = useFloorPlan();

  useEffect(() => {
    if (!isCoverLoaded || !panZoomRef.current) {
      return;
    }

    const mapApi = panZoomRef.current;
    const containerEl = panZoomRef.current.childNode.parentElement;

    const imageSize = {
      width: floorPlan.background.width,
      height: floorPlan.background.height,
    };

    const observer = new ResizeObserver(([container]) => {
      const zoomMin = Math.max(
        0.05,
        Math.min(
          container.contentRect.width / imageSize.width,
          container.contentRect.height / imageSize.height,
        ),
      );

      mapApi.setOptions({
        zoomMin,
      });
    });

    if (containerEl) {
      observer.observe(containerEl);
    }

    return () => {
      observer.disconnect();
    };
  }, [
    isCoverLoaded,
    panZoomRef,
    floorPlan.background.width,
    floorPlan.background.height,
  ]);
}
