import useSWR from "swr";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFloorPlan } from "../FloorPlanContext";

export function useFloorPlanBackground() {
  const floorPlan = useFloorPlan();
  const { client: cogniteClient } = useCogniteClient();

  return useSWR(
    ["floor-plan-img", floorPlan.background.cogniteFileId],
    async ([_, fileId]) =>
      cogniteClient.files
        .getDownloadUrls([{ id: fileId }])
        .then(([fileLink]) => fileLink.downloadUrl),
    { revalidateOnFocus: false },
  );
}
