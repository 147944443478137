import { useUser } from "@properate/auth";
import { Node, useReactFlow } from "reactflow";
import { useState, useEffect } from "react";
import { ExternalId, Timeseries } from "@cognite/sdk";
import { Form, Button, Select } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { GraphModal } from "../../common/SchemaGraph/GraphModal";
import { useCogniteClient } from "../../../context/CogniteClientContext";
import { GraphModalData } from "../types";
import {
  LargeNode,
  NodeInput,
  NodeInputLabel,
  Body,
  OutputHeader,
} from "./helpers/NodeComponents";
import {
  getNodeId,
  internalIdFromExternalId,
  updateReactFlowNodeData,
} from "./helpers/Utils";

const FormItem = Form.Item;

interface Props {
  externalId: ExternalId | null;
  priority: number;
  operationId: string;
}

export const getEmptySetpointOutputNode = (): Node<Props> => {
  return {
    id: getNodeId("setpointOutput"),
    type: "setpointOutput",
    data: {
      externalId: null,
      priority: 16,
      operationId: "write_setpoint",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function SetpointOutputNode(params: { id: string; data: Props }) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();
  const currentBuildingId = useCurrentBuildingId();

  const [isSetpointSelectionModalVisible, setIsSetpointSelectionModalVisible] =
    useState(false);
  const [selectedSetpoint, setSelectedSetpoint] = useState<Timeseries | null>(
    null,
  );
  const [showSetpointData, setShowSetpointData] =
    useState<GraphModalData | null>(null);
  const [priority, setPriority] = useState(params.data.priority);
  const { client } = useCogniteClient();
  const user = useUser();

  useEffect(() => {
    if (selectedSetpoint !== null && selectedSetpoint.externalId !== undefined)
      updateReactFlowNodeData(reactFlowInstance, params.id, "externalId", {
        externalId: selectedSetpoint.externalId,
      });
  }, [params.id, reactFlowInstance, selectedSetpoint]);

  useEffect(() => {
    updateReactFlowNodeData(reactFlowInstance, params.id, "priority", priority);
  }, [params.id, reactFlowInstance, priority]);

  return (
    <>
      <LargeNode>
        <OutputHeader>
          {t("calculation-flow.node-types.write-setpoint")}
          <div style={{ float: "right" }}>
            <Button
              onClick={async () => {
                if (params.data.externalId) {
                  const id = await internalIdFromExternalId(
                    params.data.externalId,
                    client,
                  );
                  if (id)
                    setShowSetpointData({
                      id,
                      unit: "",
                    });
                }
              }}
              size="small"
              disabled={user.isViewer || !params.data.externalId}
            >
              <LineChartOutlined />
            </Button>
          </div>
        </OutputHeader>
        <Body>
          <NodeInput inputId="a">
            <NodeInputLabel style={{ width: "100%" }}>
              <Form layout="vertical" disabled={user.isViewer}>
                <FormItem label="Id" hidden={params.data.externalId === null}>
                  {params.data.externalId?.externalId}
                </FormItem>
                <FormItem>
                  <Button
                    onClick={() => setIsSetpointSelectionModalVisible(true)}
                  >
                    {params.data.externalId
                      ? t("calculation-flow.node-types.change-setpoint")
                      : t("calculation-flow.node-types.select-setpoint")}
                  </Button>
                </FormItem>
                <FormItem label={t("calculation-flow.node-types.priority")}>
                  <Select
                    value={params.data.priority.toString()}
                    style={{ width: 200 }}
                    showAction={["focus", "click"]}
                    onChange={(value: string) => setPriority(Number(value))}
                    options={[
                      {
                        value: "8",
                        label: t(
                          "calculation-flow.node-types.highest-priority",
                          {
                            value: "8",
                          },
                        ),
                      },
                      { value: "9", label: "9" },
                      { value: "10", label: "10" },
                      { value: "11", label: "11" },
                      { value: "12", label: "12" },
                      { value: "13", label: "13" },
                      { value: "14", label: "14" },
                      { value: "15", label: "15" },
                      {
                        value: "16",
                        label: t(
                          "calculation-flow.node-types.lowest-priority",
                          {
                            value: "16",
                          },
                        ),
                      },
                    ]}
                  />
                </FormItem>
              </Form>
            </NodeInputLabel>
          </NodeInput>
        </Body>
        {showSetpointData && (
          <GraphModal
            timeseriesInfo={showSetpointData}
            setTimeseriesInfo={setShowSetpointData}
            hide={() => setShowSetpointData(null)}
            expanded
            showDocuments={false}
            buildingId={currentBuildingId}
          />
        )}
      </LargeNode>
      <TimeseriesSelectionModal
        hiddenFilters={["building"]}
        initialFilters={{
          category: "setPoint",
        }}
        open={isSetpointSelectionModalVisible}
        onOk={(setpoints: Timeseries[]) =>
          setSelectedSetpoint(setpoints.length === 0 ? null : setpoints[0])
        }
        onHide={() => setIsSetpointSelectionModalVisible(false)}
        selectedIds={selectedSetpoint ? [selectedSetpoint.id] : []}
        max={1}
      />
    </>
  );
}

export default SetpointOutputNode;
