import { Asset, CogniteClient, Datapoints, Timeseries } from "@cognite/sdk";
import Dataloader from "dataloader";

export type ProperateCogniteClient = {
  getTimeseries: (id: number) => Promise<Timeseries>;
  getTimeseriesMany: (ids: number[]) => Promise<Timeseries[]>;
  getLatestValue: (id: number) => Promise<Datapoints>;
  getLatestValueMany: (ids: number[]) => Promise<Datapoints[]>;
  getRoomInfo: (assetId: number) => Promise<{ name: string; id: number }>;
};

async function ignoreMissing<T extends { id: number }>(
  fetchFunction: (
    ids: { id: number }[],
    options?: { ignoreUnknownIds: boolean },
  ) => Promise<T[]>,
  ids: readonly number[],
): Promise<(T | Error)[]> {
  const results = await fetchFunction(
    ids.map((id) => ({ id })),
    { ignoreUnknownIds: true },
  );
  const map = results.reduce<Record<number, T>>(
    (prev, result) => ({ ...prev, [result.id]: result }),
    {},
  );

  return ids.map((id) => {
    if (map[id]) {
      return map[id];
    }
    return new Error(`missing id ${id}`);
  });
}

export const cogniteBatch = (client: CogniteClient): ProperateCogniteClient => {
  const fetchIds = async (ids: readonly number[]) => {
    return ignoreMissing<Timeseries>(client.timeseries.retrieve, ids);
  };
  const idLoader = new Dataloader(fetchIds, {
    maxBatchSize: 100,
  });

  const fetchValue = async (ids: readonly number[]) => {
    valueLoader.clearAll();
    return ignoreMissing<Datapoints>(client.datapoints.retrieveLatest, ids);
  };
  const valueLoader = new Dataloader(fetchValue, {
    maxBatchSize: 100,
  });

  const fetchName = async (ids: readonly number[]) => {
    const assets = await client.assets.retrieve(ids.map((id) => ({ id })));

    // we know that all assets except the root asset has a parentId set, so we know it exists for all assets you want
    // to get room info for
    const assetParentIds = [...new Set(assets.map((asset) => asset.parentId!))];

    const assetParents = await client.assets.retrieve(
      assetParentIds.map((id) => ({ id })),
    );

    const parentAssetIdToAssetMap = assetParents.reduce<Record<number, Asset>>(
      (prev, parent) => ({ ...prev, [parent.id]: parent }),
      {},
    );

    return assets.map((asset) => ({
      name: `${parentAssetIdToAssetMap[asset.parentId!].name} ${
        parentAssetIdToAssetMap[asset.parentId!].description || ""
      }`,
      id: asset.parentId as number,
    }));
  };
  const nameLoader = new Dataloader(fetchName, {
    maxBatchSize: 100,
  });

  return {
    getTimeseries: async (id: number) => {
      return idLoader.load(id);
    },
    getTimeseriesMany: async (ids: number[]) => {
      const result = await idLoader.loadMany(ids);
      return result.filter(
        (timeseries): timeseries is Timeseries =>
          !(timeseries instanceof Error),
      );
    },
    getLatestValue: async (id: number) => {
      return valueLoader.load(id);
    },
    getLatestValueMany: async (ids: number[]) => {
      const result = await valueLoader.loadMany(ids);
      return result.filter(
        (datapoint): datapoint is Datapoints => !(datapoint instanceof Error),
      );
    },
    getRoomInfo: async (assetId: number) => {
      return nameLoader.load(assetId);
    },
  };
};
