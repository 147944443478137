import { useUser } from "@properate/auth";
import { Node as ReactFlowNode, useReactFlow } from "reactflow";
import { Form, InputNumber } from "antd";
import { useTranslations } from "@properate/translations";
import { getNodeId, updateReactFlowNodeData } from "./helpers/Utils";
import {
  Node,
  NodeOutput,
  NodeOutputLabel,
  NodeInput,
  Header,
  Body,
  NodeInputLabel,
  NodeContent,
} from "./helpers/NodeComponents";

const FormItem = Form.Item;

interface ClipNodeProps {
  operationId: string;
  lower: number | null;
  upper: number | null;
}

export const getEmptyClipNode = (): ReactFlowNode<ClipNodeProps> => {
  return {
    id: getNodeId("clip"),
    type: "clip",
    data: {
      operationId: "clip",
      lower: null,
      upper: null,
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function LimitNode(params: { id: string; data: ClipNodeProps }) {
  const t = useTranslations();

  const user = useUser();
  const reactFlowInstance = useReactFlow();

  const handleLowerChange = (lower: number | null) => {
    updateReactFlowNodeData(reactFlowInstance, params.id, "lower", lower);
  };

  const handleUpperChange = (upper: number | null) => {
    updateReactFlowNodeData(reactFlowInstance, params.id, "upper", upper);
  };

  return (
    <Node>
      <Header>{t("calculation-flow.node-types.upper-and-lower-limit")}</Header>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel>A</NodeInputLabel>
        </NodeInput>
        <NodeContent>
          <Form layout="vertical" disabled={user.isViewer}>
            <FormItem label={t("calculation-flow.node-types.low-limit")}>
              <InputNumber
                value={params.data.lower || undefined}
                onChange={handleLowerChange}
                controls={false}
                decimalSeparator=","
              />
            </FormItem>
            <FormItem label={t("calculation-flow.node-types.high-limit")}>
              <InputNumber
                value={params.data.upper || undefined}
                onChange={handleUpperChange}
                controls={false}
                decimalSeparator=","
              />
            </FormItem>
          </Form>
        </NodeContent>
        <NodeOutput>
          <NodeOutputLabel>
            {t("calculation-flow.node-types.limited-A")}
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </Node>
  );
}

export default LimitNode;
