import { TimeSpan } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { Granularity } from "@/utils/helpers";
import { RangePicker } from "@/components/RangePicker/RangePicker";
import { getTimeSpanOptions } from "./timeSpanOptions";

interface Props {
  value: TimeSpan;
  onChange: (value: TimeSpan) => unknown;
  notBefore?: number;
  notAfter?: number;
  granularity?: Granularity;
  allowClear?: boolean;
}

const getPicker = (granularity: Granularity) => {
  if (granularity === "M") {
    return "month";
  }
  if (granularity === "w") {
    return "week";
  }
  if (granularity === "d") {
    return "date";
  }
  return "date";
};

export function TimeSpanSelection({
  value,
  onChange,
  notBefore,
  notAfter,
  granularity = "h",
  allowClear = true,
}: Props) {
  const t = useTranslations();

  const timeSpanOptions = getTimeSpanOptions(t, notBefore);
  return (
    <RangePicker
      value={value}
      picker={getPicker(granularity)}
      onChange={onChange}
      notBefore={notBefore}
      notAfter={notAfter}
      format={granularity === "h" ? "D. MMM YYYY HH:mm" : "D. MMM YYYY"}
      presets={timeSpanOptions}
      allowClear={allowClear}
    />
  );
}
