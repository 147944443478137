import { AlarmWithId, AlarmSeverity } from "@properate/common";
import { TableColumnsType, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { useTranslations, MessageKey } from "@properate/translations";
import { sortString, useAccordionValues } from "@properate/ui";
import { ERROR } from "@/utils/ProperateColors";
import { LineClamp } from "@/components/LineClamp";
import { SearchHighlighter } from "@/components/SearchHighlighter";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useGetFilteredAlarms } from "@/features/alarms";
import { AlarmsTableActionColumn } from "./AlarmsTableActionColumn";
import { AlarmsTableEventsColumn } from "./AlarmsTableEventsColumn";
import { AlarmsTablePlanColumn } from "./AlarmsTablePlanColumn";
import { AlarmsTableSubscribersColumn } from "./AlarmsTableSubscribersColumn";
import { getFlattenedSubscriptions } from "./utils/getFlattenedSubscriptions";
import { AlarmsTableNotesColumn, makeFilterId } from "./AlarmsTableNotesColumn";

type Props = {
  buildingIds: number[];
  onRowClick: (alarm: AlarmWithId) => void;
  tableHeight: number;
};

export function AlarmsTable({ buildingIds, onRowClick, tableHeight }: Props) {
  const { filteredAlarms, search } = useGetFilteredAlarms(buildingIds);
  const { filters: noteAccordionFilters } = useAccordionValues();
  const t = useTranslations();

  const columns: TableColumnsType<AlarmWithId> = [
    {
      key: "severity",
      title: t("org-alarms.table-columns.severity"),
      width: 90,
      align: "center",
      sorter: ({ severity: severityOne }, { severity: severityTwo }) => {
        const order: AlarmSeverity[] = ["CRITICAL", "ERROR", "WARNING", "INFO"];
        return order.indexOf(severityOne) - order.indexOf(severityTwo);
      },
      render: (_, { severity }) =>
        t(`org-alarms.severity.${severity.toLowerCase()}` as MessageKey),
    },
    {
      title: t("org-alarms.table-columns.active"),
      key: "active",
      width: 80,
      align: "center",
      sorter: (
        { activeEvent: activeEventOne },
        { activeEvent: activeEventTwo },
      ) => (activeEventOne ? 1 : 0) - (activeEventTwo ? 1 : 0),
      render: (_, { activeEvent }) =>
        activeEvent && (
          <Tooltip title="Aktiv" placement="right">
            <div style={{ color: ERROR, fontSize: 32 }}>&bull;</div>
          </Tooltip>
        ),
    },
    {
      title: t("org-alarms.table-columns.name"),
      key: "name",
      defaultSortOrder: "ascend",
      width: 220,
      sorter: ({ name: nameOne }, { name: nameTwo }) =>
        sortString(nameOne, nameTwo),
      render: (_, { name }) =>
        name && (
          <LineClamp title={name}>
            <SearchHighlighter search={search} text={name} />
          </LineClamp>
        ),
    },
    {
      title: t("org-alarms.table-columns.description"),
      key: "description",
      sorter: (
        { description: descriptionOne },
        { description: descriptionTwo },
      ) => sortString(descriptionOne, descriptionTwo),
      render: (_, { description }) =>
        description !== undefined && (
          <LineClamp title={description}>
            <SearchHighlighter search={search} text={description} />
          </LineClamp>
        ),
    },
    {
      title: t("org-alarms.table-columns.timeseries"),
      key: "timeseries",
      width: 220,
      sorter: ({ timeseries: timeseriesOne }, { timeseries: timeseriesTwo }) =>
        sortString(timeseriesOne?.name, timeseriesTwo?.name),
      render: (_, { timeseries }) =>
        timeseries && (
          <LineClamp title={timeseries.name}>
            <SearchHighlighter search={search} text={timeseries.name} />
          </LineClamp>
        ),
    },
    {
      title: t("org-alarms.table-columns.schedule"),
      key: "plan",
      width: 160,
      render: (_, alarm) => <AlarmsTablePlanColumn alarm={alarm} />,
    },
    {
      title: t("org-alarms.table-columns.created-by"),
      key: "owner",
      width: 220,
      sorter: ({ owner: ownerOne }, { owner: ownerTwo }) =>
        sortString(ownerOne, ownerTwo),
      render: (_, { owner }) => (
        <LineClamp title={owner}>
          <SearchHighlighter search={search} text={owner} />
        </LineClamp>
      ),
    },
    {
      title: t("org-alarms.table-columns.subscribers"),
      key: "subscribers",
      width: 220,
      sorter: (alarmOne, alarmTwo) => {
        const alarmSubscriptionsOne = getFlattenedSubscriptions(alarmOne);
        const alarmSubscriptionsTwo = getFlattenedSubscriptions(alarmTwo);
        return alarmSubscriptionsOne.length - alarmSubscriptionsTwo.length;
      },
      render: (_, alarm) => (
        <AlarmsTableSubscribersColumn alarm={alarm} search={search} />
      ),
    },
    {
      title: t("org-alarms.table-columns.events"),
      key: "events",
      width: 64,
      sorter: (
        { eventCount: eventCountOne = 0 },
        { eventCount: eventCountTwo = 0 },
      ) => eventCountOne - eventCountTwo,
      render: (_, alarm) => <AlarmsTableEventsColumn alarm={alarm} />,
    },
    {
      key: "action",
      width: 44,
      render: (_, alarm) => <AlarmsTableActionColumn alarm={alarm} />,
    },
    {
      key: "notes",
      width: 44,
      render: (_, alarm) => <AlarmsTableNotesColumn alarm={alarm} />,
    },
  ];

  if (buildingIds.length > 1) {
    columns.splice(2, 0, {
      title: t("org-alarms.table-columns.address"),
      key: "address",
      width: 180,
      sorter: (
        { buildingAddress: buildingAddressOne },
        { buildingAddress: buildingAddressTwo },
      ) => sortString(buildingAddressOne, buildingAddressTwo),
      render: (_, { buildingId, buildingAddress }) => (
        <Link
          to={`/asset/${buildingId}/building`}
          onClick={(event) => event.stopPropagation()}
        >
          <LineClamp title={buildingAddress}>
            <SearchHighlighter search={search} text={buildingAddress} />
          </LineClamp>
        </Link>
      ),
    });
  }

  return (
    <TableWithoutDefaultSort
      virtual
      columns={columns}
      pagination={false}
      dataSource={filteredAlarms}
      rowKey="snapshotId"
      onRow={(alarm) => ({
        onClick: () => onRowClick(alarm),
      })}
      scroll={{
        y: tableHeight,
        x: "100%",
      }}
      rowIsHoverable
      size="small"
      rowClassName={(alarm: AlarmWithId) => {
        const shouldHighlight =
          noteAccordionFilters.get(makeFilterId(alarm))?.active ?? false;

        return shouldHighlight ? "bg-info-bg" : "";
      }}
    />
  );
}
