import { PropsWithChildren } from "react";
import { EditOutlined } from "@ant-design/icons";
import useSWR from "swr";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";
import { useFloorPlan, useFloorPlanEditMode } from "./FloorPlanContext";

export function FloorPlanMapPinEditableSign(props: PropsWithChildren) {
  const pin = useFloorPlanPin();
  const [isEditMode] = useFloorPlanEditMode();

  if (isEditMode) {
    return props.children;
  }

  if (pin.type === "set-point-general") {
    return <WithEditableSign>{props.children}</WithEditableSign>;
  }

  return <FloorPlanMapPinEditableSignForSensors {...props} />;
}

function FloorPlanMapPinEditableSignForSensors(props: PropsWithChildren) {
  const isRelatedToSetPoint = useFloorPlanPinIsRelatedToSetPoint();

  if (isRelatedToSetPoint) {
    return <WithEditableSign>{props.children}</WithEditableSign>;
  }

  return props.children;
}

function useFloorPlanPinIsRelatedToSetPoint() {
  const floorPlan = useFloorPlan();
  const pin = useFloorPlanPin();
  const { client: cogniteClient } = useCogniteClient();
  const properateCogniteClient = useProperateCogniteClient();
  const timeseriesIds = floorPlan.pins.map((pin) => pin.timeseriesId);

  const { data: relations = [] } = useSWR(
    [timeseriesIds, "set-point-relations"],
    async ([timeseriesIds]) => {
      const timeseries =
        await properateCogniteClient.getTimeseriesMany(timeseriesIds);

      const targetExternalIds = timeseries
        .filter((ts) => ts.externalId)
        .map((ts) => ts.externalId as string);

      return cogniteClient.relationships
        .list({
          filter: {
            targetExternalIds,
            labels: {
              containsAll: [
                {
                  externalId: "rel_setpt_realval_gen",
                },
              ],
            },
            confidence: {
              min: 0.3,
              max: 1.0,
            },
          },
        })
        .autoPagingToArray({
          limit: -1,
        });
    },
  );

  return relations.some((relation) => {
    return relation.targetExternalId === pin.externalId;
  });
}

function WithEditableSign(props: PropsWithChildren) {
  return (
    <>
      {props.children}
      <div className="flex items-center mr-4">
        <EditOutlined />
      </div>
    </>
  );
}
