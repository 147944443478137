import { message } from "antd";
import axios, { AxiosError } from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import { useTranslations } from "@properate/translations";

const functions = getFunctions(getApp(), "europe-west1");

const serverLog = httpsCallable(functions, "serverLog");
function isClientError(error: any) {
  if ("status" in error && typeof error.status === "number") {
    return error.status >= 400 && error.status < 500;
  }
  if ("code" in error && typeof error.code === "number") {
    return error.code >= 400 && error.code < 500;
  }
  return false;
}

let isErrorVisible = false;

export const useHandleApiError = () => {
  const t = useTranslations();

  return async function handleAPIError(
    error: any,
    messageTimeoutInSeconds = 3,
  ) {
    if (!isErrorVisible) {
      isErrorVisible = true;
      setTimeout(
        () => (isErrorVisible = false),
        messageTimeoutInSeconds * 1000,
      );
      console.error(error);
      try {
        await serverLog({
          message: error.message,
          stack: error.stack,
        });
      } catch (error) {
        if (isErrorWithMessage(error)) {
          console.error("Error when sending error to server: " + error.message);
        } else {
          console.error("Error when sending error to server");
        }
      }
      if (isClientError(error)) {
        return message.error(
          t("common.error.something-failed-external-code-status", {
            code: error.code,
            status: error.status,
          }),
          messageTimeoutInSeconds,
        );
      }
      if (isErrorWithMessage(error)) {
        return message.error(
          t("common.error.something-failed-external-message", {
            message: error.message,
          }),
          messageTimeoutInSeconds,
        );
      }
      return message.error(
        t("common.error.something-failed-external"),
        messageTimeoutInSeconds,
      );
    }
  };
};

export function isErrorWithMessage(
  error: unknown,
): error is { message: string } {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof error.message === "string"
  );
}

export function isAxiosError<ResponseType>(
  error: unknown,
): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}
