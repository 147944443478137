import { useUser } from "@properate/auth";
import { useState, useEffect } from "react";
import { Node, useReactFlow } from "reactflow";
import { ExternalId } from "@cognite/sdk";
import { Form } from "antd";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "../../../context/CogniteClientContext";
import {
  findAvailableTimeseriesExternalId,
  getNodeId,
  splitExternalId,
  updateReactFlowNodeData,
} from "./helpers/Utils";
import {
  LargeNode,
  NodeInput,
  NodeInputLabel,
  Body,
  OutputHeader,
} from "./helpers/NodeComponents";
import TimeseriesDetails from "./components/timeseriesOutput/TimeSeriesDetails";
import TimeSeriesGraphButton from "./components/timeseriesGraphButton";
import TimeSeriesSelectionForm from "./components/TimeSeriesSelectionForm";

interface Props {
  externalId: ExternalId | null;
  operationId: string;
  parentExternalId: ExternalId | null;
  bb: string;
  unit: string;
}

export const getEmptyTimeSeriesOutputNode = (
  buildingExternalId: string | null,
): Node<Props> => {
  return {
    id: getNodeId("timeseriesOutput"),
    type: "timeseriesOutput",
    data: {
      externalId: null,
      operationId: "write_timeseries",
      parentExternalId:
        buildingExternalId !== null
          ? {
              externalId: `${buildingExternalId.replace(
                "AH_",
                "TS_",
              )}+Common=000.000-OF001`,
            }
          : null,
      bb: "OF",
      unit: "",
    },
    position: {
      x: 0,
      y: 0,
    },
  };
};

function TimeSeriesOutputNode(params: { id: string; data: Props }) {
  const t = useTranslations();

  const reactFlowInstance = useReactFlow();
  const cognite = useCogniteClient();
  const user = useUser();

  const [showExternalIdLocal, setShowExternalIdLocal] = useState(false);
  const [externalIdLocal, setExternalIdLocal] = useState(
    params.data.externalId === null ? null : params.data.externalId,
  );
  const { client } = useCogniteClient();

  useEffect(() => {
    const findExternalIdLocal = async (parentExternalId: ExternalId) => {
      setExternalIdLocal(
        await findAvailableTimeseriesExternalId(
          cognite.client,
          parentExternalId.externalId,
          params.data.bb,
        ),
      );
    };
    if (params.data.parentExternalId)
      findExternalIdLocal(params.data.parentExternalId);
  }, [
    client.timeseries,
    cognite.client,
    params.data.bb,
    params.data.externalId,
    params.data.parentExternalId,
  ]);

  useEffect(() => {
    setShowExternalIdLocal(params.data.externalId === null);
  }, [params.data.externalId]);

  const handleParentExternalIdChange = (
    parentExternalId: ExternalId | null,
  ) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "parentExternalId",
      parentExternalId,
    );
    setShowExternalIdLocal(true);
  };

  const handleUnitChange = (unit: string | null) => {
    updateReactFlowNodeData(reactFlowInstance, params.id, "unit", unit);
  };

  const handleBBChange = (bb: string) => {
    updateReactFlowNodeData(reactFlowInstance, params.id, "bb", bb);
    setShowExternalIdLocal(true);
  };

  const { subBuilding, system } =
    externalIdLocal !== null
      ? splitExternalId(externalIdLocal)
      : { subBuilding: null, system: null };

  return (
    <LargeNode>
      <OutputHeader>
        {t("calculation-flow.write-to-timeseries", {
          meterType: "timeseries",
        })}
        <TimeSeriesGraphButton
          externalId={params.data.externalId}
          noExternalIdTooltip={t("calculation-flow.save-to-view")}
          style={{ float: "right" }}
        />
      </OutputHeader>
      <Body>
        <NodeInput inputId="a">
          <NodeInputLabel style={{ width: "100%" }}>
            <Form layout="vertical" disabled={user.isViewer}>
              <TimeSeriesSelectionForm
                externalId={
                  showExternalIdLocal ? externalIdLocal : params.data.externalId
                }
                onChange={handleParentExternalIdChange}
                hiddenFilters={["building"]}
              />
            </Form>
          </NodeInputLabel>
        </NodeInput>
        <TimeseriesDetails
          subBuilding={subBuilding}
          system={system}
          labels={[]}
          bb={params.data.bb}
          unit={params.data.unit}
          onBBChange={handleBBChange}
          onUnitChange={handleUnitChange}
        />
      </Body>
    </LargeNode>
  );
}

export default TimeSeriesOutputNode;
