import { MouseEvent, useMemo } from "react";
import dayjs from "@properate/dayjs";
import { DatapointAggregate, DatapointAggregates } from "@cognite/sdk";
import { Group } from "@visx/group";
import { TooltipWithBounds, useTooltip } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { TimeSpan, formatUnit } from "@properate/common";
import { Space } from "antd";
import { useTheme } from "styled-components";
import { useParams } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { formatNonScientific } from "@/utils/format";
import { useUserSettings } from "@/services/userSettings";
import { HeatMapAggregate, HeatMapViewMode } from "../types";
import { getLabelKeyForAggregate } from "../utils";
import { HeatMapRightAxis } from "./HeatMapRightAxis";
import { HeatMapCalendar } from "./HeatMapCalendar";
import { HeatMapTopAxis } from "./HeatMapTopAxis";
import { HeatMapLeftAxis } from "./HeatMapLeftAxis";

interface Props {
  timeseriesWithData: DatapointAggregates;
  aggregate: HeatMapAggregate;
  viewMode: HeatMapViewMode;
  referenceDate: number;
  timeSpan: TimeSpan;
  height: number;
  width: number;
  color: string;
  compact: boolean;
  showWeekNumbers: boolean;
}

export function HeatMapRect({
  timeseriesWithData,
  aggregate,
  viewMode,
  referenceDate,
  timeSpan,
  width,
  height,
  color,
  compact,
  showWeekNumbers,
}: Props) {
  const t = useTranslations();

  const margins = {
    horizontal: 90,
    vertical: compact ? 20 : 90,
  };
  const { data: preferences } = useUserSettings();
  const isSidebarCollapsed = Boolean(preferences?.isSidebarCollapsed);
  const { snapshotId } = useParams() as { snapshotId: string };

  const widthSidebar = snapshotId ? (isSidebarCollapsed ? 50 : 280) : 0;
  const graphWidth = width - margins.horizontal - widthSidebar;
  const graphHeight = Math.min(800, height - margins.vertical);
  const theme = useTheme();
  const colorRange = useMemo(
    () => [theme.neutral9, color] as [string, string],
    [theme, color],
  );
  const { showTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop } =
    useTooltip<{ timestamp: string; value: string }>();
  const isLeftAxisUsed = viewMode === "week" || showWeekNumbers;

  function handleMouseMove(
    event: MouseEvent<SVGRectElement>,
    datapoint: DatapointAggregate,
  ) {
    const point = localPoint(event);
    if (point) {
      const dateFormat =
        viewMode === "week" ? "dddd D. MMMM YYYY HH:mm" : "dddd D. MMMM YYYY";
      return showTooltip({
        tooltipLeft: point.x,
        tooltipTop: point.y,
        tooltipData: {
          timestamp: dayjs(datapoint.timestamp).format(dateFormat),
          value: `${formatNonScientific(datapoint[aggregate]!)} ${formatUnit(
            timeseriesWithData.unit,
          )}`,
        },
      });
    }
    hideTooltip();
  }

  return (
    // Relative position is for correct tooltip positioning
    <div style={{ position: "relative", height }}>
      {height > 0 && width > 0 && (
        <svg height={height} width={width}>
          <Group left={margins.horizontal / 2} top={margins.vertical / 2}>
            <HeatMapTopAxis
              graphWidth={graphWidth}
              offsetRight={graphWidth * 0.03}
              compact={compact}
            />
            {timeseriesWithData.datapoints.length > 0 && (
              <HeatMapRightAxis
                timeseriesWithData={timeseriesWithData}
                aggregate={aggregate}
                graphHeight={graphHeight}
                graphWidth={graphWidth}
                offsetTop={Math.max(graphHeight * 0.05, 30)}
                colorRange={colorRange}
                compact={compact}
                unit={timeseriesWithData.unit}
              />
            )}
            {isLeftAxisUsed && (
              <HeatMapLeftAxis
                graphHeight={graphHeight}
                offsetTop={Math.max(graphHeight * 0.05, 30)}
                viewMode={viewMode}
                referenceDate={referenceDate}
                compact={compact}
              />
            )}
            <HeatMapCalendar
              timeseriesWithData={timeseriesWithData}
              aggregate={aggregate}
              viewMode={viewMode}
              timeSpan={timeSpan}
              graphHeight={graphHeight}
              graphWidth={graphWidth}
              offsetRight={graphWidth * 0.03}
              offsetTop={Math.max(graphHeight * 0.05, 30)}
              colorRange={colorRange}
              onMouseMove={handleMouseMove}
              onMouseLeave={hideTooltip}
              compact={compact}
            />
          </Group>
        </svg>
      )}
      {tooltipData !== undefined && (
        <TooltipWithBounds left={tooltipLeft} top={tooltipTop}>
          <Space direction="vertical">
            <span>{tooltipData.timestamp}</span>
            <span>
              {t(getLabelKeyForAggregate(aggregate))}: {tooltipData.value}
            </span>
          </Space>
        </TooltipWithBounds>
      )}
    </div>
  );
}
