import { Accordion, useSidebarData } from "@properate/ui";
import { useMemo } from "react";
import { useTranslations } from "@properate/translations";
import { Empty, Spin } from "antd";
import SomethingWrong from "./SomethingWrong";
import NotesSidebarTitlePortal from "./NotesSidebarTitlePortal";
import NewNoteButton from "./NewNoteButton";
import { useNotes } from "./hooks";
import { CenterContent, dateFormat, isAllDay } from "./utils";
import { NoteMeta } from "./NoteMeta";
import { NoteActions } from "./NoteActions";
import {
  Note,
  NoteLevel,
  NoteLevelColors,
  NoteMetaField,
  NotesSidebarValues,
} from "./types";

function NotesAccordionEntryTitle({ note }: Readonly<{ note: Note }>) {
  const allDay = isAllDay({ startTime: note.startTime, endTime: note.endTime });
  const dateTimeRange = `${dateFormat(note.startTime, !allDay)} - ${dateFormat(
    note.endTime,
    !allDay,
  )}`;

  return (
    <div className={"flex flex-row w-full items-center gap-3"}>
      <div
        className={`w-1.5 h-1.5 border border-solid rounded-full ${
          NoteLevelColors[note.level] || NoteLevelColors[NoteLevel.UNKNOWN]
        }`}
      />
      <span>{dateTimeRange}</span>
    </div>
  );
}

export function NotesAccordion() {
  const { notes, isLoading, error } = useNotes();
  const { buildings } = useSidebarData<NotesSidebarValues>();
  const t = useTranslations();

  const accordionEntries = useMemo(() => {
    if (error || isLoading || !notes) {
      return [];
    }
    const showClosedFields = [NoteMetaField.Timeseries];
    const hideOpenFields: NoteMetaField[] = [];

    if (buildings.length > 1) {
      showClosedFields.push(NoteMetaField.Building);
    } else {
      hideOpenFields.push(NoteMetaField.Building);
    }

    return notes.map((note) => ({
      uniqueId: note.id,
      title: <NotesAccordionEntryTitle note={note} />,
      primaryOpenContent: (
        <p className="font-sans mt-0 leading-5 whitespace-pre-line">
          {note.content}
        </p>
      ),
      primaryClosedContent: (
        <div>
          <p className="font-sans mt-0 mb-2 leading-5 truncate">
            {note.content}
          </p>
          <NoteMeta
            note={note}
            showFields={showClosedFields}
            fieldOptions={{ truncateTimeseries: true }}
          />
        </div>
      ),
      secondaryContent: (
        <>
          <NoteMeta note={note} hideFields={hideOpenFields} />
          <NoteActions note={note} />
        </>
      ),
    }));
  }, [notes, isLoading, error, buildings]);

  function renderContent() {
    if (accordionEntries?.length === 0) {
      return (
        <CenterContent>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("notes.empty-message")}
          />
        </CenterContent>
      );
    }
    return (
      <Accordion
        htmlId="notes-sidebar"
        entries={accordionEntries}
        className="pl-4 pr-5"
      />
    );
  }

  if (error) {
    console.error(error);
    return <SomethingWrong hideBackButton />;
  }

  if (isLoading) {
    return (
      <CenterContent>
        <Spin />
      </CenterContent>
    );
  }

  return (
    <>
      <NotesSidebarTitlePortal>
        <NewNoteButton />
      </NotesSidebarTitlePortal>
      {renderContent()}
    </>
  );
}
