import { NodeTypes } from "reactflow";
import AddNode from "./Add";
import ClipNode from "./Clip";
import LnNode from "./Ln";
import TimeSeriesInputNode from "./TimeSeriesInput";
import ConstantInputNode from "./ConstantInput";
import SignalGeneratorNode from "./SignalGenerator";
import SubNode from "./Sub";
import RootNode from "./Root";
import PowerNode from "./Power";
import DivisionNode from "./Division";
import InverseNode from "./Inverse";
import ProductNode from "./Product";
import IfElseNode from "./IfElse";
import ScheduleNode from "./Schedule";
import HistoricMaxNode from "./HistoricMax";
import TimeSeriesOutputNode from "./TimeSeriesOutput";
import TimeSeriesMeterOutputNode from "./TimeSeriesMeterOutput";
import SetpointOutputNode from "./SetpointOutput";
import AbsoluteValueNode from "./AbsoluteValue";
import AggregateNode from "./Aggregate";
import RollingWindowNode from "./RollingWindow";
import SetpointsOutputNode from "./SetpointsOutput";
import TimeShiftNode from "./TimeShift";
import MinimumNode from "./Minimum";
import MaximumNode from "./Maximum";
import MeanNode from "./Mean";
import MultiTimeSeriesInputNode from "./MultiTimeSeriesInput";
import CommentNode from "./Comment";
import TableOutputNode from "./TableOutput";

export const nodeTypes: NodeTypes = {
  timeseriesInput: TimeSeriesInputNode,
  multiTimeseriesInput: MultiTimeSeriesInputNode,
  signalGenerator: SignalGeneratorNode,
  add: AddNode,
  sub: SubNode,
  min: MinimumNode,
  max: MaximumNode,
  mean: MeanNode,
  ln: LnNode,
  aggregate: AggregateNode,
  rollingWindow: RollingWindowNode,
  product: ProductNode,
  division: DivisionNode,
  ifElse: IfElseNode,
  schedule: ScheduleNode,
  historicMax: HistoricMaxNode,
  constant: ConstantInputNode,
  timeseriesOutput: TimeSeriesOutputNode,
  timeseriesMeterOutput: TimeSeriesMeterOutputNode,
  setpointOutput: SetpointOutputNode,
  setpointsOutput: SetpointsOutputNode,
  tableOutput: TableOutputNode,
  absoluteValue: AbsoluteValueNode,
  power: PowerNode,
  root: RootNode,
  inverse: InverseNode,
  clip: ClipNode,
  timeShift: TimeShiftNode,
  comment: CommentNode,
};
