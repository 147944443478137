"use client";

import { createContext, useContext, useEffect } from "react";
import useSWR from "swr";
import { fetchLoginStatus, setupApiAuth } from "@properate/api";

export type AuthedUser = {
  email: string;
  name: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isViewer: boolean;
  ownerAlias: string | null;
  hasAccess: boolean;
  isLoading: boolean;
};

type UnauthedUser = {
  email: null;
  name: null;
  isAdmin: null;
  isSuperAdmin: null;
  isViewer: null;
  ownerAlias: null;
  hasAccess: null;
  isLoading: boolean;
};

export type AuthContextValue = AuthedUser | UnauthedUser;

const AuthContext = createContext<AuthContextValue>({
  email: null,
  name: null,
  isAdmin: null,
  isViewer: null,
  ownerAlias: null,
  isSuperAdmin: null,
  hasAccess: null,
  isLoading: true,
});

export function AuthContextProvider(props: {
  name: string | null;
  email: string | null;
  /** Keycloak's token; also used to authenticate the Cognite client */
  getAccessToken: () => string | undefined;
  refreshAccessToken: () => Promise<unknown>;
  children: React.ReactNode;
}) {
  setupApiAuth(props.getAccessToken, props.refreshAccessToken);
  const currentAccessToken = props.getAccessToken();

  const { data, mutate, error } = useSWR(
    ["owner", currentAccessToken],
    async function loginStatusFetcher() {
      if (currentAccessToken) {
        const resData = await fetchLoginStatus();
        if (resData.data) {
          const { owner = "all", eep: tags } = resData.data;
          return { ownerAlias: owner, tags };
        }

        if (resData.error === "other") {
          throw new Error(resData.error);
        }
      }
      return undefined;
    },
    {
      errorRetryCount: 5,
      refreshInterval: 0,
      revalidateOnFocus: false,
      onError: console.error,
    },
  );

  const isLoaded = data !== undefined || error !== undefined;

  // Revalidate the login-status when SWR-cache is cleared.
  useEffect(() => {
    if (data === undefined) {
      mutate();
    }
  }, [data, mutate]);

  const { ownerAlias = null, tags = [] } = data ?? {};

  const isAdmin = tags.includes("admin");
  const isViewer = tags.includes("viewer");
  const isSuperAdmin = isAdmin && ownerAlias === "all";
  const hasAccess = !!tags.length || error?.message === "other";

  return (
    <AuthContext.Provider
      value={
        props.email === null
          ? {
              email: null,
              name: null,
              isAdmin: null,
              isViewer: null,
              ownerAlias: null,
              isSuperAdmin: null,
              hasAccess: null,
              isLoading: true,
            }
          : {
              email: props.email,
              name: props.name ?? props.email,
              isAdmin,
              isViewer,
              ownerAlias,
              isSuperAdmin,
              hasAccess,
              isLoading: !isLoaded,
            }
      }
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export function useUser() {
  const user = useContext(AuthContext);

  if (user.email === null) {
    throw new Error(
      "`useUser` must be used within an authenticated context. " +
        "For possibly unauthenticated contexts, use `usePossiblyUser`.",
    );
  }

  return user;
}

export function usePossiblyUser() {
  return useContext(AuthContext);
}

let _cogniteToken = "";

export function setCogniteToken(token: string) {
  _cogniteToken = token;
}

export function getCogniteToken() {
  return new Promise<string>((resolve) => {
    let attempts = 0;

    const interval = setInterval(() => {
      if (attempts > 50) {
        console.error("Fetching the Cognite token timed out");
        clearInterval(interval);
        return resolve("");
      }

      if (_cogniteToken) {
        clearInterval(interval);
        return resolve(_cogniteToken);
      }

      attempts++;
    }, 100);
  });
}
