import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, App, Row } from "antd";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { ThemeContext } from "styled-components";
import dayjs from "@properate/dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslations, useLocale } from "@properate/translations";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { COLOR_PALETTE, PURPLE100 } from "@/utils/ProperateColors";
import {
  CalendarEvents,
  CalendarStatusMap,
  ColoredProperateCalendar,
  Holiday,
  NewProperateCalendarEvent,
  ProperateCalendar,
  ProperateCalendarEvent,
} from "@/utils/types";
import {
  addCalendarEvent,
  deleteCalendarEvent,
  deleteImportedCalendar,
  getCalendarEvents,
  getCalendars,
  getHolidays,
  syncCalendar,
  updateCalendar,
  updateCalendarEvent,
} from "@/eepApi";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useHelp } from "@/context/HelpContext";
import { AddDayEventModal } from "@/pages/properateCalendar/AddDayEventModal";
import { UpdateStatus } from "@/pages/properateCalendar/UpdateStatus";
import { isErrorWithMessage } from "@/utils/api";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { YearlyCalendar } from "./YearlyCalendar";
import { Header } from "./Header";
import { Side } from "./Side";
import MonthWeekCalendar from "./MonthWeekCalendar";

import { AddEventModal } from "./AddEventModal";
import { AddWeeklyEventModal } from "./AddWeeklyEventModal";
import { EventsPlan } from "./EventsPlan";
import { EditEventModal } from "./EditEventModal";
import { EditRecurringModal } from "./EditRecurringModal";
import { DeleteRecurringModal } from "./DeleteRecurringModal";

const getEventsForCalendar = async (
  calendarId: string,
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
  excludes: string[],
  excludeEventsWithDefaultValue = true,
) => {
  return await getCalendarEvents({
    calendarId,
    start: start.toDate(),
    end: end.toDate(),
    excludes,
    excludeEventsWithDefaultValue,
  });
};

const MILLISECONDS_IN_WEEK = 7 * 24 * 60 * 60 * 1000;

const getEventsForCalendars = async (
  calendarIds: string[],
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
  excludes: string[],
) => {
  if (calendarIds.length === 0) return {};

  return calendarIds.reduce<Promise<CalendarEvents>>(
    async (prev, calendar_id) => {
      const p = await prev;

      p[calendar_id] = await getEventsForCalendar(
        calendar_id,
        start,
        end,
        excludes,
        false,
      );
      return p;
    },
    Promise.resolve({}),
  );
};

export const ProperateCalendarPage = () => {
  const locale = useLocale();
  const t = useTranslations();
  useBuildingPageTitle(t("calendar.title"));

  const themeContext = useContext(ThemeContext);
  const { mode } = useParams() as { mode: "list" | "year" | "month" | "week" };
  const { message } = App.useApp();

  const [status, setStatus] = useState<CalendarStatusMap>();
  const [updateStatus, setUpdateStatus] = useState<UpdateStatus>();

  const [addEventModalDate, setAddEventModalDate] = useState<Date | null>(null);
  const [addWeeklyEventModalDate, setAddWeeklyEventModalDate] =
    useState<Date | null>(null);
  const [addDayEventModalDate, setAddDayEventModalDate] = useState<Date | null>(
    null,
  );
  const [editEventModalEvent, setEditEventModalEvent] =
    useState<ProperateCalendarEvent | null>(null);
  const [editRecurringModalEvent, setEditRecurringModalEvent] =
    useState<ProperateCalendarEvent | null>(null);
  const [deleteRecurringModalEvent, setDeleteRecurringModalEvent] =
    useState<ProperateCalendarEvent | null>(null);

  const buildingId = useCurrentBuildingId();

  const { data: preferences } = useUserSettings();

  const { client } = useCogniteClient();

  const [calendars, setCalendars] = useState<ProperateCalendar[]>();

  const { setHelp } = useHelp();

  const refreshCalendarList = async () => {
    try {
      const calendars = await getCalendars({ building_id: buildingId });
      setCalendars(calendars);
    } catch (e) {
      console.error(e);
      message.error(t("calendar.failed-to-update-calendars"));
    }
  };

  useEffect(() => {
    const get = async () => {
      setLoadingCalendars(true);
      try {
        await refreshCalendarList();
      } finally {
        setLoadingCalendars(false);
      }
    };
    get();
  }, [client, buildingId, message]);

  useEffect(() => {
    const updateStatus = new UpdateStatus({
      buildingId,
      handleStatusChange: setStatus,
    });
    updateStatus.refresh();
    setUpdateStatus(updateStatus);
    return () => {
      updateStatus.clear();
    };
  }, [buildingId]);

  const [date, setDate] = useState(dayjs().startOf("day"));

  const [schedule, setSchedule] = useState<CalendarEvents>();

  const [weekSchedule, setWeekSchedule] = useState<CalendarEvents>();

  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingCalendars, setLoadingCalendars] = useState(true);

  const [syncing, setSyncing] = useState(false);

  const [holidays, setHolidays] = useState<Holiday[]>([]);

  const navigate = useNavigate();

  const start = useMemo(
    () => date.startOf(mode === "list" ? "year" : mode),
    [date, mode],
  );
  const end = useMemo(
    () => start.add(1, mode === "list" ? "year" : mode),
    [start, mode],
  );

  const selectedCalendars = useMemo(() => {
    return preferences?.calendar?.[buildingId]?.selectedCalendars || [];
  }, [preferences, buildingId]);

  useEffect(() => {
    setHelp({
      title: t("calendar.title"),
      content: t.rich("calendar.help", {
        p: (chunks) => <p>{chunks}</p>,
      }),
    });
  }, [setHelp, t]);

  useEffect(() => {
    const get = async () => {
      try {
        const holidays = await getHolidays({
          start: start.toDate(),
          end: end.toDate(),
          locale,
        });
        setHolidays(holidays);
      } catch (e) {
        console.error(e);
        message.error(t("calendar.failed-to-load-holidays"));
      }
    };
    get();
  }, [start, end, message, locale, t]);

  const updateSchedule = useCallback(
    (schedule: CalendarEvents) => {
      if (selectedCalendars.includes("holidays")) {
        setSchedule({ ...schedule, holidays });
      } else {
        setSchedule(schedule);
      }
    },
    [selectedCalendars, holidays],
  );

  const selectedInstallations = useMemo(() => {
    return (
      preferences?.calendar?.[buildingId]?.selectedInstallations || []
    ).filter(
      (installation) =>
        calendars?.some((calendar) => installation === calendar.calendar_id),
    );
  }, [preferences, buildingId, calendars]);

  const installations = preferences?.calendar?.[buildingId]?.installations;

  const refreshCalendar = async (
    calendarId: string,
    start: dayjs.Dayjs,
    end: dayjs.Dayjs,
    mode: string,
  ) => {
    try {
      const events = await getEventsForCalendar(
        calendarId,
        start,
        end,
        mode === "list" ? ["weekly"] : [],
        false,
      );
      setSchedule((prev) => ({
        ...prev,
        [calendarId]: events,
      }));

      const weeklyEvents = await getEventsForCalendar(
        calendarId,
        dayjs(0),
        dayjs(MILLISECONDS_IN_WEEK),
        ["single", "yearly", "yearly_on_this_holiday"],
      );

      setWeekSchedule((prev) => ({
        ...prev,
        [calendarId]: weeklyEvents,
      }));
      if (updateStatus) {
        updateStatus.refresh();
      }
    } catch (e) {
      console.error(e);
      message.error(
        t("calendar.can-not-load-calendar-with-id", {
          calendarId,
        }),
      );
    }
  };
  useEffect(() => {
    let running = true;
    const loadEvents = async () => {
      setLoadingEvents(true);
      setSchedule(undefined);
      setWeekSchedule(undefined);
      try {
        const schedule = await getEventsForCalendars(
          selectedInstallations,
          start,
          end,
          mode === "list" ? ["weekly"] : [],
        );
        const weekSchedule = await getEventsForCalendars(
          selectedInstallations,
          dayjs(0),
          dayjs(MILLISECONDS_IN_WEEK),
          ["single", "yearly", "yearly_on_this_holiday"],
        );

        if (running) {
          updateSchedule(schedule);
          setWeekSchedule(weekSchedule);
        }
      } catch (e) {
        console.error(e);
        message.error(t("calendar.can-not-load-calendars"));
      } finally {
        setLoadingEvents(false);
      }
    };

    if (!loadingCalendars) {
      loadEvents();
    }
    return () => {
      running = false;
      setLoadingEvents(false);
      setSchedule(undefined);
      setWeekSchedule(undefined);
    };
  }, [
    t,
    updateSchedule,
    setWeekSchedule,
    start,
    end,
    mode,
    selectedInstallations,
    message,
    loadingCalendars,
  ]);

  const calendarMap: Record<string, ColoredProperateCalendar> = useMemo(() => {
    return (calendars || []).reduce(
      (prev, current, index) => ({
        ...prev,
        [current.calendar_id]: {
          ...current,
          color:
            installations?.[current.calendar_id]?.color ||
            COLOR_PALETTE[index % COLOR_PALETTE.length].code,
        },
      }),
      {
        holidays: {
          name: t("calendar.holidays"),
          description: "",
          calendar_id: "holidays",
          color: installations?.["holidays"]?.color || PURPLE100,
          valid_values: {},
          original_valid_values: {},
          default_value: "NULL",
          system: "",
        },
      },
    );
  }, [calendars, installations, t]);

  const handleUpdateCalendar = async (
    calendar_id: string,
    update: {
      defaultValue?: string;
      validValues?: Record<string, string>;
      active?: boolean;
      extendedDescription?: string;
    },
  ) => {
    if (calendars) {
      const calendar = calendars!.find((c) => c.calendar_id === calendar_id)!;

      const payload = { ...calendar };

      if (update.defaultValue !== undefined) {
        payload.default_value = update.defaultValue;
      }

      if (update.validValues !== undefined) {
        payload.valid_values = update.validValues;
      }

      if (update.active !== undefined) {
        payload.active = update.active;
      }

      if (update.extendedDescription !== undefined) {
        payload.extended_description = update.extendedDescription;
      }

      try {
        const result = await updateCalendar(payload);
        setCalendars(
          [
            ...calendars.filter((c) => c.calendar_id !== calendar_id),
            {
              ...calendar,
              default_value: result.default_value,
              valid_values: result.valid_values,
              active: result.active,
              extended_description: result.extended_description,
            },
          ].sort((a, b) => a.name.localeCompare(b.name)),
        );
        await refreshCalendar(calendar.calendar_id, start, end, mode);
      } catch (error) {
        console.error("Error while updating calendar!", error);
        if (isErrorWithMessage(error)) {
          message.error(
            t("calendar.can-not-update-calendar-error", {
              errorMessage: error.message,
            }),
          );
        } else {
          message.error(t("calendar.can-not-update-calendar"));
        }
      }
    }
  };

  const handleDeleteCalendar = async (calendarId: string) => {
    try {
      await deleteImportedCalendar({ calendarId });
      await refreshCalendarList();
    } catch (error) {
      console.error(`Klarte ikke å slette kalender!`, error);
      if (isErrorWithMessage(error)) {
        message.error(
          t("calendar.can-not-delete-calendar-error", {
            errorMessage: error.message,
          }),
        );
      } else {
        message.error(t("calendar.can-not-delete-calendar"));
      }
    }
  };

  const onClickSyncButton = async () => {
    try {
      setSyncing(true);
      if (selectedInstallations.length > 0) {
        await syncCalendar({
          buildingId,
          calendarIds: selectedInstallations,
        });
      }
    } catch (error) {
      if (isErrorWithMessage(error)) {
        message.error(
          t("calendar.error-sync-calendar-error", {
            errorMessage: error.message,
          }),
        );
      } else {
        message.error(t("calendar.error-sync-calendar"));
      }
    } finally {
      if (updateStatus) {
        updateStatus.refresh();
      }
      setSyncing(false);
    }
  };
  const handleAddEvent = async (event: NewProperateCalendarEvent) => {
    try {
      await addCalendarEvent(event);
      await refreshCalendar(event.calendar_id, start, end, mode);
    } catch (e) {
      console.error(e);
      message.error(t("calendar.can-not-add-event"));
    }
  };

  const handleUpdateEvent = async (updatedEvent: ProperateCalendarEvent) => {
    try {
      await updateCalendarEvent(updatedEvent);
      await refreshCalendar(updatedEvent.calendar_id, start, end, mode);
    } catch (e) {
      console.error(e);
      message.error(t("calendar.can-not-update-event"));
    }
  };

  const handleDeleteEvent =
    (existingEvent: ProperateCalendarEvent) =>
    async (deletedEvent: ProperateCalendarEvent) => {
      if (existingEvent.schedule === "single") {
        try {
          await deleteCalendarEvent(deletedEvent);
          await refreshCalendar(deletedEvent.calendar_id, start, end, mode);
        } catch (e) {
          console.error(e);
          message.error(t("calendar.can-not-delete-event"));
        }
      } else {
        setDeleteRecurringModalEvent(deletedEvent);
        setEditEventModalEvent(null);
      }
    };
  return (
    <>
      <Row style={{ height: "100vh" }} key={buildingId}>
        <Col flex="auto" style={{ minHeight: "100%" }}>
          <Row>
            <Col span={24}>
              <Header
                mode={mode}
                setMode={async (mode) => {
                  await mutateUserSettings({
                    calendar: {
                      [buildingId]: {
                        ...preferences?.calendar?.[buildingId],
                        mode,
                      },
                    },
                  });
                  navigate(`../calendar/${mode}`);
                }}
                addEvent={() => setAddEventModalDate(date.toDate())}
                date={date}
                setDate={setDate}
                disabled={!selectedInstallations.length}
                loading={loadingEvents || loadingCalendars}
                syncing={syncing}
                onClickSyncButton={onClickSyncButton}
              />
            </Col>
          </Row>
          {!(loadingEvents || loadingCalendars) && (
            <AutoSizer>
              {({ width, height }: Size) => (
                <div
                  style={{ width, maxHeight: height, overflow: "auto" }}
                  data-testid="calendar"
                >
                  {mode === "year" && schedule && (
                    <YearlyCalendar
                      year={date.year()}
                      schedule={schedule}
                      installationMap={calendarMap}
                    />
                  )}
                  {(mode === "month" || mode === "week") && schedule && (
                    <MonthWeekCalendar
                      mode={mode}
                      calendarMap={calendarMap}
                      date={date.toDate()}
                      schedule={schedule}
                      height={height - 56}
                      disabled={!selectedInstallations.length}
                      setShowEditEventModal={setEditEventModalEvent}
                      setShowAddEventModal={setAddEventModalDate}
                    />
                  )}
                  {mode === "list" && schedule && weekSchedule && (
                    <EventsPlan
                      year={date.year()}
                      schedule={schedule}
                      installationMap={calendarMap}
                      weekSchedule={weekSchedule}
                      holidays={holidays}
                      height={height - 56}
                      disabled={selectedInstallations.length === 0}
                      setShowEditEventModal={setEditEventModalEvent}
                      setShowAddWeeklyEventModal={setAddWeeklyEventModalDate}
                      setShowAddDayEventModal={setAddDayEventModalDate}
                    />
                  )}
                </div>
              )}
            </AutoSizer>
          )}
        </Col>
        <Col
          flex="none"
          style={{ background: themeContext.background, width: 400 }}
        >
          <Side
            timeseries={calendars || []}
            timeseriesMap={calendarMap}
            refreshCalendarList={refreshCalendarList}
            selectTimeseries={async (selection: string[]) => {
              await mutateUserSettings({
                calendar: {
                  [buildingId]: {
                    ...preferences?.calendar?.[buildingId],
                    selectedInstallations: selection,
                  },
                },
              });
            }}
            selectedTimeseries={
              preferences?.calendar?.[buildingId]?.selectedInstallations || []
            }
            selectCalendars={async (selection: string[]) => {
              await mutateUserSettings({
                calendar: {
                  [buildingId]: {
                    ...preferences?.calendar?.[buildingId],
                    selectedCalendars: selection,
                  },
                },
              });
            }}
            selectedCalendars={
              preferences?.calendar?.[buildingId]?.selectedCalendars || []
            }
            status={status || {}}
            onUpdatePreferences={async (
              externalId: string,
              update: { color?: string },
            ) => {
              await mutateUserSettings({
                calendar: {
                  [buildingId]: {
                    ...preferences?.calendar?.[buildingId],
                    installations: {
                      ...preferences?.calendar?.[buildingId]?.installations,
                      [externalId]: {
                        ...preferences?.calendar?.[buildingId]?.installations?.[
                          externalId
                        ],
                        ...update,
                      },
                    },
                  },
                },
              });
            }}
            onUpdateCalendar={handleUpdateCalendar}
            onDeleteCalendar={handleDeleteCalendar}
          />
        </Col>
      </Row>
      {addEventModalDate && (
        <AddEventModal
          date={addEventModalDate}
          calendars={(
            preferences?.calendar?.[buildingId]?.selectedInstallations || []
          ).map((id) => calendarMap[id])}
          addEvent={async (event: NewProperateCalendarEvent) => {
            try {
              await addCalendarEvent(event);
              await refreshCalendar(event.calendar_id, start, end, mode);
            } catch (e) {
              console.error(e);
              message.error(t("calendar.can-not-add-event"));
            }
            setAddEventModalDate(null);
          }}
          onHide={() => setAddEventModalDate(null)}
        />
      )}
      {addWeeklyEventModalDate && (
        <AddWeeklyEventModal
          date={addWeeklyEventModalDate}
          calendars={(
            preferences?.calendar?.[buildingId]?.selectedInstallations || []
          ).map((id) => calendarMap[id])}
          addEvent={async (event: NewProperateCalendarEvent) => {
            try {
              await addCalendarEvent(event);
              await refreshCalendar(event.calendar_id, start, end, mode);
            } catch (e) {
              console.error(e);
              message.error(t("calendar.can-not-add-event"));
            }
            setAddWeeklyEventModalDate(null);
          }}
          onHide={() => setAddWeeklyEventModalDate(null)}
        />
      )}
      {addDayEventModalDate && (
        <AddDayEventModal
          date={addDayEventModalDate}
          calendars={(
            preferences?.calendar?.[buildingId]?.selectedInstallations || []
          ).map((id) => calendarMap[id])}
          addEvent={async (event: NewProperateCalendarEvent) => {
            try {
              await addCalendarEvent(event);
              await refreshCalendar(event.calendar_id, start, end, mode);
            } catch (e) {
              console.error(e);
              message.error(t("calendar.can-not-add-event"));
            }
            setAddDayEventModalDate(null);
          }}
          onHide={() => setAddDayEventModalDate(null)}
        />
      )}
      {editEventModalEvent && (
        <EditEventModal
          event={{ ...editEventModalEvent } as ProperateCalendarEvent}
          hide={() => setEditEventModalEvent(null)}
          calendar={calendarMap[editEventModalEvent.calendar_id]}
          addEvent={handleAddEvent}
          updateEvent={handleUpdateEvent}
          deleteEvent={handleDeleteEvent(editEventModalEvent)}
        />
      )}
      {editRecurringModalEvent && (
        <EditRecurringModal
          open={!!editRecurringModalEvent}
          event={{ ...editRecurringModalEvent }}
          onSave={async (event) => {
            if (event.schedule === "single") {
              try {
                await deleteCalendarEvent(editRecurringModalEvent);
                await addCalendarEvent(event);
              } catch (e) {
                console.error(e);
                message.error(t("calendar.can-not-update-event"));
              }
            } else {
              try {
                await updateCalendarEvent(event);
              } catch (e) {
                console.error(e);
                message.error(t("calendar.can-not-update-event"));
              }
            }
            await refreshCalendar(event.calendar_id, start, end, mode);
            setEditRecurringModalEvent(null);
          }}
          hide={() => setEditRecurringModalEvent(null)}
        />
      )}
      {deleteRecurringModalEvent && (
        <DeleteRecurringModal
          event={{ ...deleteRecurringModalEvent }}
          onSave={async (event) => {
            try {
              await deleteCalendarEvent(event);
              await refreshCalendar(event.calendar_id, start, end, mode);
            } catch (e) {
              console.error(e);
              message.error(t("calendar.can-not-delete-event"));
            }
            setDeleteRecurringModalEvent(null);
          }}
          hide={() => setDeleteRecurringModalEvent(null)}
          open={!!deleteRecurringModalEvent}
        />
      )}
    </>
  );
};
