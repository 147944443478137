import { PropsWithChildren } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import { useTranslations } from "@properate/translations";
import { useFloorPlanEditMode } from "./FloorPlanContext";
import { useFloorPlanUpdate } from "./hooks/useFloorPlanUpdate";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";

export function FloorPlanMapPinDeleteButton(props: PropsWithChildren) {
  const t = useTranslations();
  const [isEditing] = useFloorPlanEditMode();
  const floorPlanUpdate = useFloorPlanUpdate();
  const pin = useFloorPlanPin();

  if (!isEditing) {
    return props.children;
  }

  async function handleRemove() {
    await floorPlanUpdate.trigger({
      pins: {
        remove: pin,
      },
    });
  }

  return (
    <>
      {props.children}
      <Popconfirm
        title={t("floor-plan-v2.sensor-settings-modal-delete-pop-title")}
        onConfirm={handleRemove}
      >
        <button className="flex items-center mr-4 text-red-500 bg-transparent border-none cursor-pointer">
          <DeleteOutlined />
        </button>
      </Popconfirm>
    </>
  );
}
