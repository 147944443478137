import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { Button } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { PropsWithChildren } from "react";
import {
  LegendDataForGraphLegend,
  LegendDataForGraphLegendName,
} from "../../types";

export type AnalysisLegendDataProps = PropsWithChildren &
  LegendDataForGraphLegend & {
    isScatterplot?: boolean;
  };

export function AnalysisLegendItem({
  fill,
  stroke,
  isVisible,
  toggleVisible,
  labelKey,
}: Readonly<{
  fill?: string;
  stroke?: string;
  isVisible: boolean;
  toggleVisible: () => void;
  labelKey: MessageKey;
}>) {
  const t = useTranslations();
  return (
    <LegendItem margin="0 5px">
      <svg width={15} height={15} style={{ marginRight: 5 }}>
        <rect
          fill={fill}
          stroke={stroke}
          strokeWidth={5}
          width={15}
          height={15}
        />
      </svg>
      <LegendLabel align="left" margin="0 0 0 4px">
        <div className="flex flex-row items-center nowrap gap-1 text-xs">
          {t(labelKey)}
          <Button
            size="small"
            icon={isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            type="link"
            onClick={toggleVisible}
          />
        </div>
      </LegendLabel>
    </LegendItem>
  );
}

export const AnalysisLegendData = ({
  operationalScale,
  visibility,
  onChangeVisibility,
  isScatterplot,
  notePeriods,
  nonOperationalPeriods,
  holidayPeriods,
  enabledAlarmPeriods,
  children,
}: AnalysisLegendDataProps) => {
  return (
    <LegendOrdinal<LegendDataForGraphLegend["operationalScale"]>
      scale={operationalScale}
      direction="row"
      labelMargin="0 15px 0 0"
    >
      {(labels) => (
        <div className="flex justify-end mb-2">
          {children}
          {(labels as { text: LegendDataForGraphLegendName }[])
            .filter((label) => {
              if (label.text === "notes") {
                return !!notePeriods;
              }
              if (label.text === "nonOperational") {
                return !!nonOperationalPeriods;
              }
              if (label.text === "holidays") {
                return !!holidayPeriods;
              }
              if (label.text === "alarmEnabled") {
                return !!enabledAlarmPeriods;
              }
            })
            .map((label, i) => {
              const isNote = label.text === "notes";
              return (
                <AnalysisLegendItem
                  key={`legend-item-${i}`}
                  isVisible={visibility[label.text]}
                  fill={
                    isNote && isScatterplot
                      ? "transparent"
                      : operationalScale(label.text)
                  }
                  stroke={
                    isScatterplot ? operationalScale(label.text) : undefined
                  }
                  labelKey={`analysis.timeseries-graph.legend.${label.text}`}
                  toggleVisible={() => onChangeVisibility(label.text)}
                />
              );
            })}
        </div>
      )}
    </LegendOrdinal>
  );
};
