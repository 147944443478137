import { useTranslations } from "@properate/translations";
import { NavLink } from "react-router-dom";
import useAlarmDetailsBaseUrl from "../hooks/useAlarmDetailsBaseUrl";
import {
  AlarmDetailsPageId,
  makeAlarmDetailsPageUrl,
} from "../hooks/createNewAlarmHooks";

export function AlarmDetailsNav() {
  const baseUrl = useAlarmDetailsBaseUrl();

  const t = useTranslations();
  const navLinkClassName = ({ isActive }: { isActive: boolean }) =>
    `text-xl font-semibold ${
      isActive
        ? "text-foreground underline underline-offset-4 decoration-primary decoration-2 "
        : "text-muted-foreground"
    }`;
  return (
    <nav className={"sticky top-0 bg-background-secondary z-20 pt-4"}>
      <ul className="list-none flex flex-row gap-5 justify-start border-solid border-0 border-b border-muted pb-4">
        <li>
          <NavLink
            to={makeAlarmDetailsPageUrl(baseUrl, AlarmDetailsPageId.Settings)}
            className={navLinkClassName}
            end
          >
            {t("alarm-details.nav.settings")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={makeAlarmDetailsPageUrl(
              baseUrl,
              AlarmDetailsPageId.Notifications,
            )}
            className={navLinkClassName}
            end
          >
            {t("alarm-details.nav.notifications")}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={makeAlarmDetailsPageUrl(baseUrl, AlarmDetailsPageId.Deviations)}
            className={navLinkClassName}
            end
          >
            {t("alarm-details.nav.deviations")}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
