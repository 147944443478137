import { Fragment, MouseEvent, useMemo, useState, WheelEvent } from "react";
import { TimeSpan } from "@properate/common";
import { GridColumns } from "@visx/grid";
import { scaleTime } from "@visx/scale";
import { Group } from "@visx/group";
import { RectClipPath } from "@visx/clip-path";
import { Area, LinePath } from "@visx/shape";
import { TooltipWithBounds, useTooltip } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { useSidebarData } from "@properate/ui";
import AutoSizer from "react-virtualized-auto-sizer";
import { getItemByProp, splitArray } from "@/utils/array";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { NEUTRAL5 } from "@/utils/ProperateColors";
import { useDebounced } from "@/hooks/useDebounced";
import { NotesSidebarValues, NotesSidebarViewState } from "@/features/notes";
import { AnalysisLegendData, SimpleGraphHighlights } from "@/features/analysis";
import {
  getGraphMargins,
  isScalesWithUnit,
  mapSimplePointsToScalesWithMetadata,
  mapToSimplePointsWithMetadataList,
  zoomOnTimeSpan,
} from "../../utils";
import {
  AnalysisSelectedNodeForPrefilledNote,
  DatapointAverages,
  LegendDataForGraphLegend,
  SelectedAnalysisPoints,
  SetSelectedAnalysisPoints,
  SettingsTimeseriesSimple,
  SimplePoint,
} from "../../types";
import {
  useGetTimeseriesListWithRawAggregateData,
  usePanning,
} from "../../hooks";
import { SimpleGraphNotesRangeSelection } from "./SimpleGraphNotesRangeSelection";
import { filterClosestSimplePointsWithMetadataList } from "./utils";
import { SimpleGraphAxes } from "./SimpleGraphAxes";
import { SimpleGraphMarkers } from "./SimpleGraphMarkers";
import { SimpleGraphTooltip } from "./SimpleGraphTooltip";

const graphMargins = getGraphMargins("simple");

interface Props {
  timeSpan: TimeSpan;
  settingsTimeseriesList: SettingsTimeseriesSimple[];
  width: number;
  height: number;
  zoomedTimeSpan?: TimeSpan;
  mergeUnits?: boolean;
  highlightedTimeseriesIds?: number[];
  onChangeZoomedTimeSpan?: (value: TimeSpan) => unknown;
  interactive?: boolean;
  tickSize?: number;
  legendData?: LegendDataForGraphLegend;
  setSelectedPoints?: SetSelectedAnalysisPoints;
  selectedPoints?: SelectedAnalysisPoints;
  markersForTimeseries?: Record<
    number,
    {
      value: number;
      color?: string;
      strokeDashArray?: string;
      strokeWidth?: number;
      strokeOpacity?: number;
      className?: string;
    }
  >;
  highlights?: SimpleGraphHighlights;
}

export function AutoSizedSimpleGraph(props: Omit<Props, "height" | "width">) {
  const { legendData } = props;
  function renderGraph() {
    return (
      <AutoSizer>
        {({ height, width }) => (
          <SimpleGraph {...props} height={height} width={width} />
        )}
      </AutoSizer>
    );
  }
  if (legendData) {
    const { children } = legendData;
    return (
      <div className="w-full h-full flex flex-col gap-1">
        <div className="grow">{renderGraph()}</div>
        <AnalysisLegendData {...legendData}>{children}</AnalysisLegendData>
      </div>
    );
  }
  return renderGraph();
}

export const SimpleGraph = ({
  timeSpan,
  settingsTimeseriesList,
  width,
  height,
  zoomedTimeSpan = timeSpan,
  mergeUnits = false,
  highlightedTimeseriesIds = [],
  onChangeZoomedTimeSpan,
  interactive = false,
  tickSize = 12,
  legendData,
  setSelectedPoints,
  selectedPoints,
  markersForTimeseries,
  highlights,
}: Props) => {
  const graphWidth = width - graphMargins.left - graphMargins.right;
  const graphHeight = height - graphMargins.top - graphMargins.bottom;
  // These can be <0 when mounting
  const isGraphLargeEnough = graphWidth > 0 && graphHeight > 0;
  const timeseriesIds = useMemo(
    () => settingsTimeseriesList.map(({ id }) => ({ id })),
    [settingsTimeseriesList],
  );
  const { timeseriesList } = useGetTimeseriesList(
    timeseriesIds.map(({ id }) => id),
  );
  const { viewState } = useSidebarData<NotesSidebarValues>();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<JSX.Element>();
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  /**
   * Debounce changes in zoomed time span, which can update very quickly when zooming or panning to avoid
   * (1) lots of requests to Cognite's API and (2) a flickering UI when panning the graph
   */
  const zoomedTimeSpanDebounced = useDebounced(zoomedTimeSpan, 200);
  const {
    raw: { timeseriesListWithData: timeseriesListWithRawData },
    aggregate: { timeseriesListWithData: timeseriesListWithAggregateData },
  } = useGetTimeseriesListWithRawAggregateData<DatapointAverages>({
    items: timeseriesIds,
    start: zoomedTimeSpanDebounced[0],
    end: zoomedTimeSpanDebounced[1],
    aggregates: ["average", "min", "max"],
  });
  const simplePointsWithMetadataList = useMemo(
    () =>
      mapToSimplePointsWithMetadataList(
        [...timeseriesListWithRawData, ...timeseriesListWithAggregateData],
        settingsTimeseriesList,
        {
          applyValueLimits: true,
        },
      ),
    [
      timeseriesListWithRawData,
      timeseriesListWithAggregateData,
      settingsTimeseriesList,
    ],
  );
  const [
    aggregateSimplePointsWithMetadataList,
    rawSimplePointsWithMetadataList,
  ] = splitArray(simplePointsWithMetadataList, ({ simplePoints }) =>
    simplePoints.some((simplePoint) => typeof simplePoint.yMin === "number"),
  );
  const scalesWithMetadata = mapSimplePointsToScalesWithMetadata(
    simplePointsWithMetadataList,
    [graphHeight, 0],
    settingsTimeseriesList,
    { mergeUnits },
  );

  const {
    handleMouseDown: handleMouseDownPanning,
    handleMouseMove: handleMouseMovePanning,
    handleMouseUp: handleMouseUpPanning,
    isDragging,
  } = usePanning(timeSpan, zoomedTimeSpan, graphWidth, onChangeZoomedTimeSpan);
  const timeScale = scaleTime({
    range: [0, graphWidth],
    domain: zoomedTimeSpan,
  });

  const onClick = (event: MouseEvent<SVGRectElement>) => {
    if (
      setSelectedPoints &&
      viewState === NotesSidebarViewState.customContent
    ) {
      const { x: localPointX } = localPoint(event)!;
      const dateForPoint = timeScale.invert(
        localPointX - graphMargins.left, // Adjust for transform (left/top props) on Group
      );

      const closestPoints = filterClosestSimplePointsWithMetadataList({
        simplePointsWithMetadataList,
        zoomedTimeSpan,
        date: dateForPoint,
      });

      let assetIds: number[] = [];
      let dataSetId: number | undefined = undefined;

      closestPoints.forEach((item) => {
        const timeseries = getItemByProp(
          timeseriesList,
          item.item.metadata.timeseriesId,
        );
        if (timeseries) {
          if (!dataSetId) {
            dataSetId = timeseries.dataSetId;
          }

          if (dataSetId && timeseries.assetId) {
            assetIds = [...assetIds, timeseries.assetId];
          }
        }
      });
      const data: AnalysisSelectedNodeForPrefilledNote = {
        timestamp: dateForPoint.valueOf(),
        assetIds,
        dataSetId,
        leftOffset: localPointX,
      };

      setSelectedPoints((points) => {
        if (points?.[0] && !points[1]) {
          return points[0].timestamp > data.timestamp
            ? [data, points[0]]
            : [points[0], data];
        }
        return [data];
      });
    }
  };

  function handleMouseMove(event: MouseEvent<SVGRectElement>) {
    // Can only return null if event comes from an element not within an SVG element
    const { x: localPointX, y: localPointY } = localPoint(event)!;
    const dateForPoint = timeScale.invert(
      localPointX - graphMargins.left, // Adjust for transform (left/top props) on Group
    );
    setHoveredDate(dateForPoint);
    updateTooltip(dateForPoint, {
      tooltipLeft: localPointX,
      tooltipTop: localPointY,
    });
    handleMouseMovePanning(event);
  }

  function updateTooltip(
    date: Date,
    { tooltipLeft, tooltipTop }: { tooltipLeft: number; tooltipTop: number },
  ) {
    showTooltip({
      tooltipData: (
        <SimpleGraphTooltip
          timeseriesList={timeseriesList}
          simplePointsWithMetadataList={simplePointsWithMetadataList}
          date={date}
          zoomedTimeSpan={zoomedTimeSpan}
        />
      ),
      tooltipLeft,
      tooltipTop,
    });
  }

  const hasSimplePoints = simplePointsWithMetadataList.some(
    ({ simplePoints }) => simplePoints.length > 0,
  );

  function handleWheel(event: WheelEvent<SVGRectElement>) {
    if (tooltipOpen) {
      hideTooltip();
    }
    if (onChangeZoomedTimeSpan) {
      const zoomedTimeSpanUpdated = zoomOnTimeSpan(
        event,
        timeSpan,
        zoomedTimeSpan,
      );
      if (
        zoomedTimeSpanUpdated[0] !== zoomedTimeSpan[0] ||
        zoomedTimeSpanUpdated[1] !== zoomedTimeSpan[1]
      ) {
        onChangeZoomedTimeSpan(zoomedTimeSpanUpdated);
      }
    }
  }

  function handleMouseLeave() {
    hideTooltip();
  }

  function scaleValueForTimeseries(
    timeseriesId: number,
    unit: string,
    value: number,
  ): number {
    if (isScalesWithUnit(scalesWithMetadata)) {
      const { scale } = getItemByProp(scalesWithMetadata, unit, "unit");
      return scale(value)!;
    }
    const { scale } = getItemByProp(
      scalesWithMetadata,
      timeseriesId,
      "timeseriesId",
    );
    return scale(value)!;
  }

  function renderMarkerForTimeseries(
    timeseriesId: number,
    unit: string,
    simplePoints: SimplePoint[],
  ) {
    if (!markersForTimeseries || !(timeseriesId in markersForTimeseries)) {
      return null;
    }
    const marker = markersForTimeseries[timeseriesId];
    const points = [...simplePoints];
    points[0] = {
      ...points[0],
      timestamp: new Date(timeSpan[0]),
    };
    points[points.length - 1] = {
      ...points[points.length - 1],
      timestamp: new Date(timeSpan[1]),
    };
    return (
      <LinePath
        key={`marker_${timeseriesId}_${marker.value}`}
        data={points}
        x={(simplePoint) => timeScale(simplePoint.timestamp)}
        y={(_simplePoint) =>
          scaleValueForTimeseries(timeseriesId, unit, marker.value)
        }
        stroke={marker.color ?? "#000"}
        strokeOpacity={marker.strokeOpacity ?? 1}
        strokeDasharray={marker.strokeDashArray ?? "3 3"}
        strokeWidth={marker.strokeWidth ?? 1.5}
        className={marker.className ?? ""}
      />
    );
  }

  function renderHighlights() {
    if (!highlights?.timeSpans?.length || !highlights?.visible) {
      return null;
    }
    function getWidth(timeSpan: TimeSpan) {
      const start = timeScale(timeSpan[0]);
      const end = timeScale(timeSpan[1]);
      if (end > graphWidth) {
        return graphWidth - start;
      }
      return end - start;
    }
    return (highlights?.timeSpans ?? []).map(({ timeSpan, id }) => (
      <rect
        key={`highlight-${timeSpan[0]}-${timeSpan[1]}-${id ?? ""}`}
        x={timeScale(timeSpan[0]) + graphMargins.left}
        y={graphMargins.bottom}
        width={getWidth(timeSpan)}
        height={graphHeight}
        fill={highlights.color}
        opacity={highlights.opacity}
      />
    ));
  }

  return (
    <>
      {isGraphLargeEnough ? (
        <svg width={width} height={height}>
          {/* Avoid overflow when zooming in */}
          <RectClipPath
            id="zoomed-overflow"
            width={graphWidth}
            height={graphHeight}
          />
          {renderHighlights()}
          <Group
            left={graphMargins.left}
            top={graphMargins.top}
            clipPath="url(#zoomed-overflow)"
          >
            <GridColumns
              scale={timeScale}
              width={graphWidth}
              height={graphHeight}
              stroke={NEUTRAL5}
              strokeDasharray="3 3"
            />
            {aggregateSimplePointsWithMetadataList.map(
              ({ simplePoints, metadata: { timeseriesId, color, unit } }) => {
                // 'min' and 'max' will be set when aggregating
                return (
                  <Area
                    key={`area-${timeseriesId}`}
                    data={simplePoints}
                    x={(simplePoint) => timeScale(simplePoint.timestamp)}
                    y0={(simplePoint) =>
                      scaleValueForTimeseries(
                        timeseriesId,
                        unit,
                        simplePoint.yMin!,
                      )
                    }
                    y1={(simplePoint) =>
                      scaleValueForTimeseries(
                        timeseriesId,
                        unit,
                        simplePoint.yMax!,
                      )!
                    }
                    fill={color}
                    fillOpacity={0.5}
                  />
                );
              },
            )}
            {rawSimplePointsWithMetadataList.map(
              ({ simplePoints, metadata: { timeseriesId, unit, color } }) =>
                simplePoints.map((simplePoint) => (
                  <circle
                    key={`${simplePoint.timestamp.getTime()}-${timeseriesId}`}
                    cx={timeScale(simplePoint.timestamp)}
                    cy={scaleValueForTimeseries(
                      timeseriesId,
                      unit,
                      simplePoint.y,
                    )}
                    fill={color}
                    r={3}
                    pointerEvents="none"
                  />
                )),
            )}
            {simplePointsWithMetadataList.map(
              ({ simplePoints, metadata: { timeseriesId, unit, color } }) => {
                const isHighlightedRow =
                  highlightedTimeseriesIds.includes(timeseriesId);
                return (
                  <Fragment key={`wrapper_${timeseriesId}`}>
                    <LinePath
                      key={`linepath-${timeseriesId}`}
                      data={simplePoints}
                      x={(simplePoint) => timeScale(simplePoint.timestamp)}
                      y={(simplePoint) =>
                        scaleValueForTimeseries(
                          timeseriesId,
                          unit,
                          simplePoint.y,
                        )
                      }
                      stroke={color}
                      strokeWidth={isHighlightedRow ? 5 : 2}
                    />
                    {renderMarkerForTimeseries(
                      timeseriesId,
                      unit,
                      simplePoints,
                    )}
                  </Fragment>
                );
              },
            )}
            {hoveredDate && tooltipLeft !== undefined && (
              <SimpleGraphMarkers
                height={graphHeight}
                leftOffset={tooltipLeft - graphMargins.left}
                date={hoveredDate}
                scalesWithMetadata={scalesWithMetadata}
                simplePointsWithMetadataList={simplePointsWithMetadataList}
                timeScale={timeScale}
                zoomedTimeSpan={zoomedTimeSpan}
              />
            )}
            {selectedPoints && (
              <SimpleGraphNotesRangeSelection
                selectedPoints={selectedPoints}
                graphHeight={graphHeight}
                graphMargins={graphMargins}
                timeScale={timeScale}
                scalesWithMetadata={scalesWithMetadata}
                simplePointsWithMetadataList={simplePointsWithMetadataList}
                zoomedTimeSpan={zoomedTimeSpan}
              />
            )}
          </Group>
          <SimpleGraphAxes
            scalesWithMetadata={scalesWithMetadata}
            timeScale={timeScale}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            tickSize={tickSize}
            legendData={legendData}
          />
          {interactive && hasSimplePoints && (
            <rect
              width={width - graphMargins.left - graphMargins.right}
              height={height - graphMargins.bottom - graphMargins.top}
              fill="transparent"
              cursor={isDragging ? "grab" : "initial"}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              onMouseDown={handleMouseDownPanning}
              onMouseUp={handleMouseUpPanning}
              onWheel={handleWheel}
              onClick={onClick}
              x={graphMargins.left}
              y={graphMargins.top}
            />
          )}
        </svg>
      ) : null}
      {interactive && hasSimplePoints && (
        <div style={{ position: "relative", bottom: height }}>
          {tooltipOpen && (
            <TooltipWithBounds
              // set this to random so it correctly updates with parent bounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
            >
              {tooltipData}
            </TooltipWithBounds>
          )}
        </div>
      )}
    </>
  );
};
