import { Button, Form, InputNumber, Popover } from "antd";
import { useEffect, useState } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { cn } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { formatExtendedTimeseriesDescription } from "@/utils/helpers";
import { useFloorPlanPin } from "./FloorPlanMapPinContext";
import { useFloorPlanEditMode } from "./FloorPlanContext";
import { useFloorPlanPinTimeseries } from "./hooks/useFloorPlanPinTimeseries";
import { useFloorPlanMapMoveHandlers } from "./FloorPlanMapContext";
import { useFloorPlanPinAlarms } from "./hooks/useFloorPlanPinAlarms";
import { useFloorPlanPinStatus } from "./hooks/useFloorPlanPinStatus";
import { useFloorPlanPinStatusUpdate } from "./hooks/useFloorPlanPinStatusUpdate";

type Props = {
  content?: React.ReactNode;
  children: React.ReactNode;
};

export function FloorPlanMapPinPopover(props: Props) {
  const timeseries = useFloorPlanPinTimeseries();
  const pin = useFloorPlanPin();
  const [isPopoverOpen, setPopoverOpen] = usePopoverState();
  const alarms = useFloorPlanPinAlarms();

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={setPopoverOpen}
      trigger="hover"
      title={
        <div className="w-64">
          {timeseries.data
            ? formatExtendedTimeseriesDescription(timeseries.data)
            : "Laster…"}
        </div>
      }
      content={
        <div className="flex flex-col w-64">
          {alarms.map((alarm) => (
            <div
              key={alarm.message}
              className={cn("mb-1", {
                "text-blue-600": alarm.level === "status",
                "text-yellow-600": alarm.level === "warning",
                "text-red-600": alarm.level === "error",
              })}
            >
              {alarm.level === "status" ? (
                <InfoCircleOutlined />
              ) : (
                <WarningOutlined />
              )}{" "}
              {alarm.message}
            </div>
          ))}
          {pin.type === "set-point-general" && <ValueInput />}
        </div>
      }
    >
      <div
        onClickCapture={() => {
          // if we click on the pin, we close the popover because there
          // will be a modal showing the pin settings.
          setPopoverOpen(false);
        }}
      >
        {props.children}
      </div>
    </Popover>
  );
}

function usePopoverState() {
  const [isEditing] = useFloorPlanEditMode();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const moveHandlers = useFloorPlanMapMoveHandlers();

  useEffect(() => {
    const handler = () => setPopoverOpen(false);

    moveHandlers.add(handler);

    return () => {
      moveHandlers.delete(handler);
    };
  }, [moveHandlers]);

  function handleOpenChange(open: boolean) {
    if (isEditing) {
      return;
    }

    setPopoverOpen(open);
  }

  return [isPopoverOpen, handleOpenChange] as const;
}

function ValueInput() {
  const t = useTranslations();
  const timeseries = useFloorPlanPinTimeseries();
  const status = useFloorPlanPinStatus();
  const statusUpdate = useFloorPlanPinStatusUpdate();

  function handleFinish(fields: { value: number }) {
    if (status.data) {
      statusUpdate.trigger({
        priority: status.data.priority,
        value: fields.value,
      });
    }
  }

  return (
    <div className="flex items-center justify-between gap-4 pt-2">
      <div className="text-foreground/80">
        {t("floor-plan-v2.sensor-value")}:
      </div>
      <Form className="flex items-center gap-2" onFinish={handleFinish}>
        <Form.Item name="value" noStyle>
          <InputNumber
            key={status.data?.value}
            name="value"
            className="w-[93px]"
            defaultValue={status.data?.value}
            size="small"
            disabled={statusUpdate.isMutating}
            min={
              typeof timeseries.data?.metadata?.min_value === "string"
                ? parseInt(timeseries.data.metadata.min_value)
                : undefined
            }
            max={
              typeof timeseries.data?.metadata?.max_value === "string"
                ? parseInt(timeseries.data.metadata.max_value)
                : undefined
            }
          />
        </Form.Item>
        <Button
          htmlType="reset"
          type="text"
          icon={<CloseOutlined />}
          disabled={statusUpdate.isMutating}
          size="small"
        />
        <Button
          htmlType="submit"
          type="primary"
          icon={<CheckOutlined />}
          loading={statusUpdate.isMutating}
          size="small"
        >
          {t("floor-plan-v2.sensor-value-submit")}
        </Button>
      </Form>
    </div>
  );
}
