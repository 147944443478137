import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { ToggleSidebarButton } from "@properate/ui";
import { AlarmDetailsTimespanSelector } from "@/pages/alarms/details/components/AlarmDetailsTimespanSelector";

export function AlarmPageHeader() {
  const navigate = useNavigate();
  const t = useTranslations();
  return (
    <div className="w-full flex box-border gap-2">
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate("../newAlarms")}
      >
        {t("alarm-details.header.back")}
      </Button>
      <div className="grow" />
      <AlarmDetailsTimespanSelector />
      <ToggleSidebarButton
        hiddenWhenSidebarVisible
        sidebarHiddenContent={t("notes.show-notes-button")}
      />
    </div>
  );
}
