import { useTranslations } from "@properate/translations";
import dayjs from "@properate/dayjs";
import { useMemo } from "react";
import { formatUnit } from "@/pages/common/SchemaView/TechnicalSchema/utils";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";
import { Pin } from "../types";
import { useFloorPlanPinTimeseriesLatestValue } from "./useFloorPlanPinTimeseriesLatestValue";
import { useFloorPlanPinTimeseries } from "./useFloorPlanPinTimeseries";
import { useFloorPlanPinStatus } from "./useFloorPlanPinStatus";

type Alarm = {
  level: NonNullable<Pin["alarm"]>["level"];
  message: string;
};

export function useFloorPlanPinAlarms() {
  const t = useTranslations();
  const pin = useFloorPlanPin();
  const { latestDatapoint, unit } = useFloorPlanPinTimeseriesLatestValue();
  const timeseries = useFloorPlanPinTimeseries();
  const status = useFloorPlanPinStatus();

  return useMemo<Alarm[]>(() => {
    if (!latestDatapoint || !timeseries.data) {
      if (!timeseries.isLoading) {
        return [
          {
            level: "error",
            message: t("floor-plan.timeseries-not-found", {
              id: pin.timeseriesId,
            }),
          },
        ];
      }

      return [];
    }

    const alarms: Alarm[] = [];

    if (
      pin.type !== "set-point-general" &&
      typeof latestDatapoint.value === "number" &&
      dayjs(latestDatapoint.timestamp).isBefore(dayjs().subtract(2, "h"))
    ) {
      alarms.push({
        level: "warning",
        message: t("floor-plan.last-data-point-came-for", {
          value: dayjs(latestDatapoint.timestamp).fromNow(),
        }),
      });
    }

    if (
      pin.type !== "set-point-general" &&
      typeof latestDatapoint.value === "number" &&
      typeof pin.alarm?.min === "number" &&
      latestDatapoint.value < pin.alarm.min
    ) {
      const formattedUnit = formatUnit(unit) || "";

      alarms.push({
        level: pin.alarm.level,
        message:
          pin.alarm.minLabel ??
          t("floor-plan-v2.sensor-alarm-under", {
            value: pin.alarm.min + formattedUnit,
          }),
      });
    }

    if (
      pin.type !== "set-point-general" &&
      typeof latestDatapoint.value === "number" &&
      typeof pin.alarm?.max === "number" &&
      latestDatapoint.value > pin.alarm.max
    ) {
      const formattedUnit = formatUnit(unit) || "";

      alarms.push({
        level: pin.alarm.level,
        message:
          pin.alarm.maxLabel ??
          t("floor-plan-v2.sensor-alarm-over", {
            value: pin.alarm.max + formattedUnit,
          }),
      });
    }

    if (pin.type === "set-point-general" && status.data?.outOfService) {
      alarms.push({
        level: "warning",
        message: t("floor-plan-v2.sensor-alarm-out-of-service"),
      });
    }

    if (pin.type === "set-point-general" && status.data?.fault) {
      alarms.push({
        level: "warning",
        message: status.data.fault,
      });
    }

    return alarms;
  }, [
    latestDatapoint,
    pin.type,
    pin.timeseriesId,
    pin.alarm,
    t,
    timeseries.data,
    timeseries.isLoading,
    status.data,
    unit,
  ]);
}
