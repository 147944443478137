import dayjs from "@properate/dayjs";
import { TimeSpan } from "@properate/common";
import { useFormValue } from "@/pages/alarms/details/FormContext";
import { TimeSpanSelection } from "@/components/TimeSpanSelection";

export enum AlarmDetailsTimespanFormIds {
  FromTime = "timeSpanSelector__FromTime",
  ToTime = "timeSpanSelector__ToTime",
}

export const AlarmDetailsTimespanFormDefaults = {
  [AlarmDetailsTimespanFormIds.FromTime]: {
    defaultValue: dayjs().subtract(1, "year").valueOf(),
  },
  [AlarmDetailsTimespanFormIds.ToTime]: { defaultValue: dayjs().valueOf() },
};

export function AlarmDetailsTimespanSelector() {
  const [fromDate, setFromDate] = useFormValue<number>(
    AlarmDetailsTimespanFormIds.FromTime,
  );
  const [toDate, setToDate] = useFormValue<number>(
    AlarmDetailsTimespanFormIds.ToTime,
  );

  function handleChange(dates: TimeSpan) {
    const newFromDate = dates[0];
    const newToDate = dates[1];

    if (newFromDate !== fromDate) {
      setFromDate(newFromDate);
    }
    if (newToDate !== toDate) {
      setToDate(newToDate);
    }
  }

  return (
    <TimeSpanSelection onChange={handleChange} value={[fromDate, toDate]} />
  );
}
