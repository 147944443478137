import { AlarmDetailsGridLayout } from "@/pages/alarms/details/components/layout";
import { AlarmDetailsFooter } from "../components/AlarmDetailsFooter";
import { CommonAlarmSettingsFields } from "./CommonAlarmSettingsFields";
import { AlarmSettingsFieldsForType } from "./AlarmSettingsFieldsForType";

export function AlarmSettingsPage() {
  return (
    <AlarmDetailsGridLayout
      firstColumn={<CommonAlarmSettingsFields />}
      secondColumn={<AlarmSettingsFieldsForType />}
      footer={<AlarmDetailsFooter />}
    />
  );
}

export { getAlarmSettingsFormFields } from "./getAlarmSettingsFormFields";
