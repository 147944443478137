import { collection } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { sortString, useGetFirestoreCollection } from "@properate/ui";
import { ColumnsType } from "antd/lib/table";
import { PageHeader } from "@ant-design/pro-layout";
import { useNavigate } from "react-router-dom";
import { Button, Select, Switch } from "antd";
import { useState } from "react";
import { useTranslations } from "@properate/translations";
import styled from "styled-components";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useLocalStorageState } from "ahooks";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { FloorPlan } from "./types";
import { FloorPlanListCreationModal } from "./FloorPlanListCreationModal";
import { useFloorPlanBackground } from "./hooks/useFloorPlanBackground";
import { FloorPlanProvider } from "./FloorPlanContext";
import { useFloorPlanAssets } from "./hooks/useFloorPlanAssets";
import { FloorPlanHeaderKPIs } from "./FloorPlanHeaderKPIs";

const ref = collection(browserFirestore, "floor-plans");

export function FloorPlanList() {
  const t = useTranslations();
  const navigate = useNavigate();
  const buildingId = useCurrentBuildingId();
  const [isCreating, setIsCreating] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState("all");
  const [showBackground, setShowBackground] = useLocalStorageState(
    "floor-plan-list-show-background",
    { defaultValue: false },
  );
  const { data = [], isLoading } = useGetFirestoreCollection<FloorPlan>(ref);
  const assets = useFloorPlanAssets();

  const columns: ColumnsType<FloorPlan> = [
    {
      title: t("floor-plan-v2.table-column-building"),
      key: "subBuilding",
      width: 90,
      sorter: (a, b) => sortString(a.subBuilding, b.subBuilding),
      render: (record) => record.subBuilding,
    },
    {
      title: t("floor-plan-v2.table-column-name"),
      key: "name",
      width: 240,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (record) => record.name,
    },
    {
      title: t("floor-plan-v2.table-column-kpis"),
      key: "kpis",
      render: (record) => (
        <KpiAligner>
          <div className="flex items-center h-40">
            <FloorPlanProvider floorPlan={record}>
              <FloorPlanHeaderKPIs />
            </FloorPlanProvider>
          </div>
        </KpiAligner>
      ),
    },
  ];

  if (showBackground) {
    columns.push({
      title: t("floor-plan-v2.table-column-preview"),
      key: "background",
      width: 260,
      className: "!p-0",
      render: (record) => (
        <FloorPlanProvider floorPlan={record}>
          <BackgroundImage />
        </FloorPlanProvider>
      ),
    });
  }

  columns.push({
    title: t("floor-plan-v2.table-column-actions"),
    key: "action",
    width: 100,
    render: (record) => (
      <Button
        onClick={() => {
          navigate(`/asset/${buildingId}/floor-plan/${record.snapshotId}`);
        }}
      >
        {t("floor-plan-v2.table-column-actions-open")} <ArrowRightOutlined />
      </Button>
    ),
  });

  return (
    <div>
      <PageHeader
        title={t("floor-plan-v2.title")}
        extra={
          <div className="flex items-center gap-6">
            <label className="flex items-center gap-2">
              {t("floor-plan.toolbar.preview-schema")}:
              <Switch checked={showBackground} onChange={setShowBackground} />
            </label>
            <label className="flex items-center gap-2">
              {t("floor-plan-v2.table-filter-building-part")}:
              <Select
                value={selectedBuilding}
                placeholder={t("floor-plan.toolbar.select-building-label")}
                onSelect={setSelectedBuilding}
                aria-label={t("floor-plan.toolbar.select-building-label")}
                className="w-28"
              >
                <Select.Option value="all" key="all">
                  {t("floor-plan-v2.table-filter-view-all")}
                </Select.Option>
                {assets.data?.subBuildings?.map((building) => (
                  <Select.Option value={building} key={building}>
                    {building}
                  </Select.Option>
                ))}
              </Select>
            </label>
            <Button type="primary" onClick={() => setIsCreating(true)}>
              {t("floor-plan-v2.table-new-floor-plan")}
            </Button>
          </div>
        }
      />
      <div className="px-4">
        <TableWithoutDefaultSort
          rowKey="snapshotId"
          columns={columns}
          dataSource={data.filter((item) => {
            if (item.softDeleted) {
              return false;
            }

            return selectedBuilding !== "all"
              ? item.subBuilding === selectedBuilding
              : true;
          })}
          loading={isLoading}
        />
      </div>
      <FloorPlanListCreationModal
        open={isCreating}
        onHide={() => setIsCreating(false)}
      />
    </div>
  );
}

function BackgroundImage() {
  const t = useTranslations();
  const background = useFloorPlanBackground();

  return (
    <div className="flex items-center justify-center bg-[#CCCDC8] h-40">
      {background.data && (
        <img
          src={background.data}
          alt={t("floor-plan-v2.table-column-preview")}
          className="h-full"
        />
      )}
    </div>
  );
}

const KpiAligner = styled.div`
  .kpi-component {
    margin: 0;
  }
`;
