export type FeatureAccessFlags = {
  calendarPageLimitedSync?: boolean;
  canAccessEnergyPage?: boolean;
  canAccessIndoorClimatePage?: boolean;
  canAccessFloorPlanPage?: boolean;
  canAccessFloorPlanPageV2?: boolean;
  canAccessSDPage?: boolean;
  canAccessSetPointsPage?: boolean;
  canAccessReportsPage?: boolean;
  canAccessWastePage?: boolean;
  canAccessComponentsPage?: boolean;
  canAccessAlarms2Page?: boolean;
  canAccessAnomalyPage?: boolean;
  canAccessAlarmsMenu?: boolean;
};

export type FeatureFlag = keyof FeatureAccessFlags;

// Added a new feature-flag? please add it with a sane default-value here:
export const defaultFeatureFlags: FeatureAccessFlags = {
  calendarPageLimitedSync: false,
  canAccessEnergyPage: true,
  canAccessIndoorClimatePage: true,
  canAccessFloorPlanPage: true,
  canAccessFloorPlanPageV2: false,
  canAccessSDPage: true,
  canAccessSetPointsPage: true,
  canAccessReportsPage: true,
  canAccessWastePage: true,
  canAccessAlarms2Page: false,
  canAccessAnomalyPage: false,
  canAccessAlarmsMenu: false,
};
