"use client";
import {
  useTranslations,
  IntlProvider,
  useMessages,
  useLocale,
} from "use-intl";
import { useUserLanguage } from "./useUserLanguage";

declare global {
  type Messages = typeof import("./messages/en.json");
  type IntlMessages = Messages; // NOSONAR
  type IntlLocale = "en" | "no";
}

export type TranslationFunction = ReturnType<typeof useTranslations<never>>;
export type MessageKey = Parameters<TranslationFunction>[0];

const getValue = (
  obj: {
    // eslint-disable-next-line
    [key: string]: any;
  },
  path: string,
) => path.split(".").reduce((acc, c) => acc && acc[c], obj);

const useTranslationsWithFallback = () => {
  const messages = useMessages();
  const t = useTranslations();

  const fallback = (
    [key, fallbackValue = ""]: [MessageKey] | [MessageKey, string],
    // eslint-disable-next-line
    ...args: any[]
  ): string => {
    const translation = getValue(messages, key);

    if (!translation) {
      return fallbackValue;
    }

    return t(key, ...args);
  };

  return Object.assign(t, {
    fallback,
  });
};

export {
  useTranslations,
  IntlProvider,
  useTranslationsWithFallback,
  useLocale,
  useUserLanguage,
};
