import { RcFile } from "antd/es/upload";
import { deleteObject, getStorage, ref } from "firebase/storage";
import useSWRMutation from "swr/mutation";
import { collection, doc, setDoc } from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { message } from "antd";
import { useTranslations } from "@properate/translations";
import { FileUploadResponse } from "@cognite/sdk";
import axios from "axios";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { FloorPlan } from "../types";
import {
  getImageDimensions,
  useImageOptimizer,
} from "./useFloorPlanBackgroundUpdate";

export function useFloorPlanCreate() {
  const t = useTranslations();
  const building = useCurrentBuilding();
  const { client: cogniteClient } = useCogniteClient();
  const { optimizeImage } = useImageOptimizer();

  type Extra = {
    arg: {
      name: string;
      subBuilding: string;
      background: {
        file: RcFile;
      };
    };
  };

  return useSWRMutation(
    ["floor-plan"],
    async (_, extra: Extra) => {
      const fullPath = `floor-plans/${extra.arg.background.file.uid}`;
      const bgRef = ref(getStorage(), fullPath);

      const dimensions = await getImageDimensions(extra.arg.background.file);
      const optimizedFile = await optimizeImage(extra.arg.background.file);

      const upload = (await cogniteClient.files.upload({
        name: extra.arg.background.file.name,
        assetIds: [building.id],
        dataSetId: building.dataSetId,
        mimeType: extra.arg.background.file.type,
        source: "properate",
        labels: [
          {
            externalId: "internal_schema_background",
          },
        ],
        metadata: {
          width: String(dimensions.width),
          height: String(dimensions.height),
        },
      })) as FileUploadResponse;

      await axios.put(upload.uploadUrl, optimizedFile);

      try {
        await setDoc(doc(collection(browserFirestore, "floor-plans")), {
          name: extra.arg.name,
          subBuilding: extra.arg.subBuilding || null,
          background: {
            cogniteFileId: upload.id,
            mimeType: extra.arg.background.file.type,
            width: dimensions.width,
            height: dimensions.height,
          },
          pins: [],
          selectedSensors: [],
        } as Omit<FloorPlan, "snapshotId">);
      } catch (error) {
        await deleteObject(bgRef);
        throw error;
      }
    },
    {
      onSuccess() {
        message.success(t("floor-plan-v2.messages.created"));
      },
      onError() {
        message.error(t("floor-plan-v2.messages.created-failed"));
      },
    },
  );
}
