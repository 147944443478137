import { EditOutlined } from "@ant-design/icons";
import { useUser } from "@properate/auth";
import { Button, Form } from "antd";
import { useState, useEffect } from "react";
import { ExternalId } from "@cognite/sdk";
import {
  formatSubBuildingFromExternalId,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { useTranslations } from "@properate/translations";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { MeterSelectionModal } from "@/components/MeterSelectionModal/MeterSelectionModal";
import { Meter, MeterType } from "@/components/MeterSelectionModal/types";
import { findAvailableTimeseriesExternalId } from "../../helpers/Utils";
const FormItem = Form.Item;

interface Props {
  meter: Meter;
  onChange: (meter: Meter) => void;
}

export const bbByMeterType: Record<MeterType, string> = {
  energy: "OE",
  water: "RF",
};

const getBuildingFromExternalId = (externalId: string) => {
  return externalId.split("+")[0].replace("TS_", "AH_");
};

function MeterSelectionNodeContent({ meter, onChange }: Props) {
  const t = useTranslations();
  const { client } = useCogniteClient();
  const user = useUser();
  const [isMeterSelectionModalVisible, setIsMeterSelectionModalVisible] =
    useState(false);
  const [externalIdLocal, setExternalIdLocal] = useState<ExternalId | null>(
    null,
  );

  useEffect(() => {
    const updateExternalIdLocal = async () => {
      if (
        meter.externalId !== null &&
        meter.building ===
          getBuildingFromExternalId(meter.externalId.externalId) &&
        meter.subBuilding ===
          formatSubBuildingFromExternalId(meter.externalId.externalId) &&
        meter.system ===
          getSystemCodeFromExternalId(meter.externalId.externalId)
      )
        setExternalIdLocal(meter.externalId);
      else if (
        meter.building !== null &&
        meter.subBuilding !== null &&
        meter.system !== null
      ) {
        setExternalIdLocal(
          await findAvailableTimeseriesExternalId(
            client,
            `${meter.building.replace("AH_", "TS_")}+${meter.subBuilding}=${
              meter.system
            }`,
            bbByMeterType[meter.meterType],
          ),
        );
      } else {
        setExternalIdLocal(null);
      }
    };
    updateExternalIdLocal();
  }, [
    client,
    meter.building,
    meter.externalId,
    meter.meterType,
    meter.subBuilding,
    meter.system,
  ]);

  const handleOk = (meter: Meter) => {
    onChange(meter);
    setIsMeterSelectionModalVisible(false);
  };

  return (
    <>
      <FormItem label="Id" required>
        {externalIdLocal !== null && <>{externalIdLocal.externalId}</>}
        <Button
          onClick={() => setIsMeterSelectionModalVisible(true)}
          disabled={user.isViewer}
        >
          {externalIdLocal !== null ? (
            <EditOutlined />
          ) : (
            t("calculation-flow.select-meter")
          )}
        </Button>
      </FormItem>
      <MeterSelectionModal
        open={isMeterSelectionModalVisible}
        meter={meter}
        onOk={handleOk}
        onHide={() => setIsMeterSelectionModalVisible(false)}
        descriptionHidden
      />
    </>
  );
}

export default MeterSelectionNodeContent;
