import {
  AccordionActionType,
  AccordionEntry,
  SidebarActionType,
  useAccordionActions,
  useAccordionValues,
  useSidebarActions,
} from "@properate/ui";
import { useEffect, useMemo } from "react";
import { Button, Tooltip } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { NotesSidebarViewState, useNotes } from "@/features/notes";
import { ReactComponent as IconMessagePlus } from "../../../pages/common/icons/message-plus.svg";
function makeFilterId(ids: number[]) {
  return `notes-for-writable-page-calcualtion-flow - ${ids.join("-")}`;
}

export const CalculationFlowNotesColumn = ({ ids }: { ids: number[] }) => {
  const accordionDispatch = useAccordionActions();
  const sidebarDispatch = useSidebarActions();
  const { filters } = useAccordionValues();
  const { notes } = useNotes();
  const filterId = makeFilterId(ids);
  const isFiltering = filters.get(filterId)?.active ?? false;
  const t = useTranslations();

  const currentAssetNotes = useMemo(() => {
    return notes
      ? notes
          .filter((note) => {
            return ids.some((id) => note.assetIds.includes(id));
          })
          .map(({ id }) => id)
      : [];
  }, [notes, ids]);

  useEffect(() => {
    if (filterId) {
      accordionDispatch({
        type: AccordionActionType.addFilter,
        filterId,
        filterFunction: (entry: AccordionEntry) =>
          currentAssetNotes.some((id) => id === entry.uniqueId),
        enable: false,
      });
    }
    return () => {
      accordionDispatch({
        type: AccordionActionType.removeFilter,
        filterId,
      });
    };
  }, [accordionDispatch, filterId, currentAssetNotes]);

  const emptyNotesForCurrentAsset = currentAssetNotes.length === 0;

  function handleToggleFilter(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();

    if (emptyNotesForCurrentAsset) {
      return;
    }
    if (isFiltering) {
      accordionDispatch({
        type: AccordionActionType.disableFilter,
        filterId,
      });
    } else {
      sidebarDispatch({ type: SidebarActionType.open });
      accordionDispatch({
        type: AccordionActionType.enableFilter,
        filterId,
        disableOthers: true,
      });
    }
  }
  function handleToggleAddNote(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    sidebarDispatch({ type: SidebarActionType.open });
    sidebarDispatch({
      type: SidebarActionType.mergeData,
      data: {
        viewState: NotesSidebarViewState.create,
        note: {
          assetIds: ids,
        },
      },
    });
  }

  return (
    <Tooltip
      title={
        emptyNotesForCurrentAsset
          ? t("notes.new-note-button")
          : t("notes.show-notes-button")
      }
    >
      <div>
        {emptyNotesForCurrentAsset ? (
          <Button
            icon={<IconMessagePlus aria-hidden />}
            shape="circle"
            className={isFiltering ? "bg-info-bg border-info text-info-fg" : ""}
            onClick={handleToggleAddNote}
          />
        ) : (
          <Button
            icon={<MessageOutlined aria-hidden />}
            shape="circle"
            className={isFiltering ? "bg-info-bg border-info text-info-fg" : ""}
            onClick={handleToggleFilter}
          />
        )}
      </div>
    </Tooltip>
  );
};
