import {
  AlarmKPI,
  BuildingActivityKPI,
  BuildingArealKPI,
  CertificationKPI,
  EnergyKPI,
  IndoorClimateKPI,
  KPI,
  TasksKPIs,
  WaterKPI,
  WeatherKPI,
} from "./types";

export interface KPIConfiguration {
  asOption: {
    label: string;
  };
  metadata: {
    unit: string;
    fractionDigits: number;
    calculate: (
      kpiValues: Record<string, number | undefined>,
      usableFloorArea?: number,
    ) => number | null;
  };
}

interface GroupedKPIConfiguration extends Omit<KPIConfiguration, "asOption"> {
  asOption: KPIConfiguration["asOption"] & {
    groupLabel: string;
    shortLabel: string;
  };
}

export const energyKPIs = [
  "consumptionThisYear",
  "consumptionThisYearPerSquareMeter",
  "consumptionLast365Days",
  "consumptionLast365DaysPerSquareMeter",
  "consumptionLast30Days",
  "consumptionLast30DaysPerSquareMeter",
  "maxHourlyConsumptionLast30Days",
  "maxHourlyConsumptionLast7Days",
  "percentageEnergyUseInWorkHoursLastWeek",
  "percentageEnergyUseInWorkHoursLast30Days",
] as const;

export const indoorClimateKPIs = [
  "maxCo2",
  "maxHumidity",
  "maxRadon",
  "maxTemperature",
  "maxVoc",
  "meanCo2",
  "meanHumidity",
  "meanRadon",
  "meanTemperature",
  "meanVoc",
  "minCo2",
  "minHumidity",
  "minRadon",
  "minTemperature",
  "minVoc",
  "ppsCo2",
  "ppdCo2",
] as const;

export const waterKPIs = [
  "waterConsumptionThisYear",
  "waterConsumptionThisYearPerLiter",
  "waterConsumptionThisYearCubicPerSquareMeter",
  "waterConsumptionThisYearLiterPerSquareMeter",
  "waterConsumptionLast365Days",
  "waterConsumptionLast365DaysPerLiter",
  "waterConsumptionLast365DaysCubicPerSquareMeter",
  "waterConsumptionLast365DaysLiterPerSquareMeter",
  "waterMaxHourlyConsumptionLast7DaysLiterPerHour",
  "waterMaxHourlyConsumptionLast7Days",
] as const;

export const buildingActivityKPIs = ["motion"] as const;

export const weatherKPIs = [
  "temperature",
  "maxWindSpeed",
  "relativeHumidity",
] as const;

export const alarmKPIs = [
  "alarms",
  "errorAlarms",
  "warningAlarms",
  "infoAlarms",
] as const;

export const certificationKPIs = [
  "certifications",
  "WELL Certified™ Core",
  "WELL Certified™",
  "ISO14001",
  "Miljøfyrtårn",
  "BREEAM",
  "BREEAM In-Use",
] as const;

export const buildingArealKPIs = ["area"] as const;

export const tasksKPIs = ["openTasks", "overdueTasks"] as const;

export const allKPIs = [
  ...energyKPIs,
  ...indoorClimateKPIs,
  ...waterKPIs,
  ...buildingActivityKPIs,
  ...weatherKPIs,
  ...alarmKPIs,
  ...certificationKPIs,
  ...tasksKPIs,
  ...buildingArealKPIs,
];

function isRealNumber(value?: number): value is number {
  return typeof value === "number" && !Number.isNaN(value);
}

function isDivisibleNumber(value?: number): value is number {
  return isRealNumber(value) && value !== 0;
}

function getFieldValue(
  kpiValues: Record<string, number | undefined>,
  field: string,
) {
  const value = kpiValues[field];
  return isRealNumber(value) ? value : null;
}

export function getKPIConfiguration(
  kpi: Extract<KPI, AlarmKPI | CertificationKPI>,
): Omit<KPIConfiguration, "metadata">;
export function getKPIConfiguration(
  kpi: Exclude<KPI, AlarmKPI | CertificationKPI>,
): KPIConfiguration;
export function getKPIConfiguration(
  kpi: KPI,
): KPIConfiguration | Omit<KPIConfiguration, "metadata">;
export function getKPIConfiguration(
  kpi: KPI,
): KPIConfiguration | Omit<KPIConfiguration, "metadata"> {
  if (energyKPIs.some((energyKPI) => energyKPI === kpi)) {
    return energyKPIConfiguration[kpi as EnergyKPI];
  }
  if (indoorClimateKPIs.some((indoorClimateKPI) => indoorClimateKPI === kpi)) {
    return indoorClimateKPIConfiguration[kpi as IndoorClimateKPI];
  }
  if (waterKPIs.some((waterKPI) => waterKPI === kpi)) {
    return waterKPIConfiguration[kpi as WaterKPI];
  }
  if (
    buildingActivityKPIs.some(
      (buildingActivityKPI) => buildingActivityKPI === kpi,
    )
  ) {
    return buildingActivityKPIConfiguration[kpi as BuildingActivityKPI];
  }
  if (buildingArealKPIs.some((buildingArealKPI) => buildingArealKPI === kpi)) {
    return buildingArealKPIConfiguration[kpi as BuildingArealKPI];
  }
  if (weatherKPIs.some((weatherKPI) => weatherKPI === kpi)) {
    return weatherKPIConfiguration[kpi as WeatherKPI];
  }
  if (alarmKPIs.some((alarmKPI) => alarmKPI === kpi)) {
    return alarmKPIConfiguration[kpi as AlarmKPI];
  }
  if (certificationKPIs.some((certificationKPI) => certificationKPI === kpi)) {
    return certificationKPIConfiguration[kpi as CertificationKPI];
  }
  if (tasksKPIs.some((tasksKPI) => tasksKPI === kpi)) {
    return tasksKPIConfiguration[kpi as TasksKPIs];
  }
  throw new Error(`No KPI configuration exists for KPI ${kpi}`);
}

export function kpiExists(kpi: KPI) {
  try {
    getKPIConfiguration(kpi);
    return true;
  } catch (error) {
    return false;
  }
}

export const energyKPIConfiguration: Record<
  EnergyKPI,
  GroupedKPIConfiguration
> = {
  consumptionThisYear: {
    asOption: {
      groupLabel: "consumption",
      shortLabel: "this-year",
      label: "consumption-this-year",
    },
    metadata: {
      unit: "kWh",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "consumptionThisYear"),
    },
  },
  consumptionThisYearPerSquareMeter: {
    asOption: {
      groupLabel: "consumption",
      shortLabel: "meter-square",
      label: "consumption-this-year-meter-square",
    },
    metadata: {
      unit: "kWh",
      fractionDigits: 0,
      calculate: (kpiValues, usableFloorArea) => {
        const { consumptionThisYear } = kpiValues;
        if (
          isDivisibleNumber(usableFloorArea) &&
          isRealNumber(consumptionThisYear)
        ) {
          return consumptionThisYear / usableFloorArea;
        }
        return null;
      },
    },
  },
  consumptionLast365Days: {
    asOption: {
      groupLabel: "consumption-last",
      shortLabel: "consumption-last-12-mnd",
      label: "consumption-last-12-mnd",
    },
    metadata: {
      unit: "kWh",
      fractionDigits: 0,
      calculate: (kpiValues) =>
        getFieldValue(kpiValues, "consumptionLast365Days"),
    },
  },
  consumptionLast365DaysPerSquareMeter: {
    asOption: {
      groupLabel: "consumption-last",
      shortLabel: "consumption-last-12-mnd-meter-square",
      label: "consumption-last-12-mnd-meter-square",
    },
    metadata: {
      unit: "kWh",
      fractionDigits: 0,
      calculate: (kpiValues, usableFloorArea) => {
        const { consumptionLast365Days } = kpiValues;
        if (
          isDivisibleNumber(usableFloorArea) &&
          isRealNumber(consumptionLast365Days)
        ) {
          return consumptionLast365Days / usableFloorArea;
        }
        return null;
      },
    },
  },
  consumptionLast30Days: {
    asOption: {
      groupLabel: "consumption-last",
      shortLabel: "last-30-days",
      label: "consumption-last-30-days",
    },
    metadata: {
      unit: "kWh",
      fractionDigits: 0,
      calculate: (kpiValues) =>
        getFieldValue(kpiValues, "consumptionLast30Days"),
    },
  },
  consumptionLast30DaysPerSquareMeter: {
    asOption: {
      groupLabel: "consumption-last",
      shortLabel: "consumption-last-30-days-meter-square",
      label: "consumption-last-30-days-meter-square",
    },
    metadata: {
      unit: "kWh",
      fractionDigits: 0,
      calculate: (kpiValues, usableFloorArea) => {
        const { consumptionLast30Days } = kpiValues;
        if (
          isDivisibleNumber(usableFloorArea) &&
          isRealNumber(consumptionLast30Days)
        ) {
          return consumptionLast30Days / usableFloorArea;
        }
        return null;
      },
    },
  },
  maxHourlyConsumptionLast30Days: {
    asOption: {
      groupLabel: "max-hourly-consumption-last",
      shortLabel: "last-30-days",
      label: "max-hourly-consumption-last-30-days",
    },
    metadata: {
      unit: "kWh",
      fractionDigits: 0,
      calculate: (kpiValues) =>
        getFieldValue(kpiValues, "maxHourlyConsumptionLast30Days"),
    },
  },
  maxHourlyConsumptionLast7Days: {
    asOption: {
      groupLabel: "max-hourly-consumption-last",
      shortLabel: "max-hourly-consumption-last-7-days",
      label: "max-hourly-consumption-last-7-days",
    },
    metadata: {
      unit: "kWh",
      fractionDigits: 0,
      calculate: (kpiValues) =>
        getFieldValue(kpiValues, "maxHourlyConsumptionLast7Days"),
    },
  },
  percentageEnergyUseInWorkHoursLastWeek: {
    asOption: {
      groupLabel: "percentage-energy-use-in-working-hours-last",
      shortLabel: "week",
      label: "percentage-energy-use-in-working-hours-last-week",
    },
    metadata: {
      unit: "%",
      fractionDigits: 0,
      calculate: (kpiValues) => {
        const { percentageEnergyUseInWorkHoursLastWeek } = kpiValues;
        if (isRealNumber(percentageEnergyUseInWorkHoursLastWeek)) {
          return Math.round(percentageEnergyUseInWorkHoursLastWeek * 100);
        }
        return null;
      },
    },
  },
  percentageEnergyUseInWorkHoursLast30Days: {
    asOption: {
      groupLabel: "percentage-energy-use-in-working-hours-last",
      shortLabel: "last-30-days",
      label: "percentage-energy-use-in-working-hours-last-30-days",
    },
    metadata: {
      unit: "%",
      fractionDigits: 0,
      calculate: (kpiValues) => {
        const { percentageEnergyUseInWorkHoursLast30Days } = kpiValues;
        if (isRealNumber(percentageEnergyUseInWorkHoursLast30Days)) {
          return Math.round(percentageEnergyUseInWorkHoursLast30Days * 100);
        }
        return null;
      },
    },
  },
};

export const indoorClimateKPIConfiguration: Record<
  IndoorClimateKPI,
  GroupedKPIConfiguration
> = {
  maxTemperature: {
    asOption: {
      groupLabel: "indoor-temperature",
      shortLabel: "max",
      label: "max-indoor-temperature",
    },
    metadata: {
      unit: "°C",
      fractionDigits: 1,
      calculate: (kpiValues) => getFieldValue(kpiValues, "maxTemperature"),
    },
  },
  minTemperature: {
    asOption: {
      groupLabel: "indoor-temperature",
      shortLabel: "min",
      label: "min-indoor-temperature",
    },
    metadata: {
      unit: "°C",
      fractionDigits: 1,
      calculate: (kpiValues) => getFieldValue(kpiValues, "minTemperature"),
    },
  },
  meanTemperature: {
    asOption: {
      groupLabel: "indoor-temperature",
      shortLabel: "mean",
      label: "mean-indoor-temperature",
    },
    metadata: {
      unit: "°C",
      fractionDigits: 1,
      calculate: (kpiValues) => getFieldValue(kpiValues, "meanTemperature"),
    },
  },
  maxHumidity: {
    asOption: {
      groupLabel: "RH%",
      shortLabel: "max",
      label: "max-RH%",
    },
    metadata: {
      unit: "%",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "maxHumidity"),
    },
  },
  meanHumidity: {
    asOption: {
      groupLabel: "RH%",
      shortLabel: "mean",
      label: "mean-RH%",
    },
    metadata: {
      unit: "%",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "meanHumidity"),
    },
  },
  minHumidity: {
    asOption: {
      groupLabel: "RH%",
      shortLabel: "min",
      label: "min-RH%",
    },
    metadata: {
      unit: "%",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "minHumidity"),
    },
  },
  maxVoc: {
    asOption: {
      groupLabel: "VOC",
      shortLabel: "max",
      label: "max-VOC",
    },
    metadata: {
      unit: "ppm",
      fractionDigits: 1,
      calculate: (kpiValues) => getFieldValue(kpiValues, "maxVoc"),
    },
  },
  meanVoc: {
    asOption: {
      groupLabel: "VOC",
      shortLabel: "mean",
      label: "mean-VOC",
    },
    metadata: {
      unit: "ppm",
      fractionDigits: 1,
      calculate: (kpiValues) => getFieldValue(kpiValues, "meanVoc"),
    },
  },
  minVoc: {
    asOption: {
      groupLabel: "VOC",
      shortLabel: "min",
      label: "min-VOC",
    },
    metadata: {
      unit: "ppm",
      fractionDigits: 1,
      calculate: (kpiValues) => getFieldValue(kpiValues, "minVoc"),
    },
  },
  maxCo2: {
    asOption: {
      groupLabel: "co2",
      shortLabel: "max",
      label: "max-CO2",
    },
    metadata: {
      unit: "ppm",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "maxCo2"),
    },
  },
  meanCo2: {
    asOption: {
      groupLabel: "co2",
      shortLabel: "mean",
      label: "mean-CO2",
    },
    metadata: {
      unit: "ppm",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "meanCo2"),
    },
  },
  minCo2: {
    asOption: {
      groupLabel: "co2",
      shortLabel: "min",
      label: "min-CO2",
    },
    metadata: {
      unit: "ppm",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "minCo2"),
    },
  },
  maxRadon: {
    asOption: {
      groupLabel: "radon",
      shortLabel: "max",
      label: "max-radon",
    },
    metadata: {
      unit: "ppm",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "maxRadon"),
    },
  },
  meanRadon: {
    asOption: {
      groupLabel: "radon",
      shortLabel: "mean",
      label: "mean-radon",
    },
    metadata: {
      unit: "ppm",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "meanRadon"),
    },
  },
  minRadon: {
    asOption: {
      groupLabel: "radon",
      shortLabel: "min",
      label: "min-radon",
    },
    metadata: {
      unit: "ppm",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "minRadon"),
    },
  },
  ppsCo2: {
    asOption: {
      groupLabel: "percentage-of-people-CO2",
      shortLabel: "satisfied",
      label: "PPS",
    },
    metadata: {
      unit: "%",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "ppsCo2"),
    },
  },
  ppdCo2: {
    asOption: {
      groupLabel: "percentage-of-people-CO2",
      shortLabel: "dissatisfied",
      label: "PPD",
    },
    metadata: {
      unit: "%",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "ppdCo2"),
    },
  },
};

const LITERS_IN_CUBIC_METER = 1000;
const SECONDS_IN_HOUR = 3600;

export const waterKPIConfiguration: Record<WaterKPI, GroupedKPIConfiguration> =
  {
    waterConsumptionThisYear: {
      asOption: {
        groupLabel: "water-consumption",
        shortLabel: "this-year-cubic-meter",
        label: "this-year-cubic-meter",
      },
      metadata: {
        unit: "m³",
        fractionDigits: 1,
        calculate: (kpiValues) => {
          const { waterConsumptionThisYear } = kpiValues;
          if (isRealNumber(waterConsumptionThisYear)) {
            return waterConsumptionThisYear * SECONDS_IN_HOUR;
          }
          return null;
        },
      },
    },
    waterConsumptionThisYearPerLiter: {
      asOption: {
        groupLabel: "water-consumption",
        shortLabel: "this-year-liter",
        label: "this-year-liter",
      },
      metadata: {
        unit: "l",
        fractionDigits: 1,
        calculate: (kpiValues) => {
          const { waterConsumptionThisYear } = kpiValues;
          if (isRealNumber(waterConsumptionThisYear)) {
            return (
              waterConsumptionThisYear * LITERS_IN_CUBIC_METER * SECONDS_IN_HOUR
            );
          }
          return null;
        },
      },
    },
    waterConsumptionThisYearCubicPerSquareMeter: {
      asOption: {
        groupLabel: "water-consumption",
        shortLabel: "meter-cubic-per-meter-square",
        label: "meter-cubic-per-meter-square",
      },
      metadata: {
        unit: "m³/m²",
        fractionDigits: 3,
        calculate: (kpiValues, usableFloorArea) => {
          const { waterConsumptionThisYear } = kpiValues;
          if (
            isDivisibleNumber(usableFloorArea) &&
            isRealNumber(waterConsumptionThisYear)
          ) {
            return (
              (waterConsumptionThisYear * SECONDS_IN_HOUR) / usableFloorArea
            );
          }
          return null;
        },
      },
    },
    waterConsumptionThisYearLiterPerSquareMeter: {
      asOption: {
        groupLabel: "water-consumption",
        shortLabel: "this-year-liter-per-meter-square",
        label: "this-year-liter-per-meter-square",
      },
      metadata: {
        unit: "i år l/m²",
        fractionDigits: 1,
        calculate: (kpiValues, usableFloorArea) => {
          const { waterConsumptionThisYear } = kpiValues;
          if (
            isDivisibleNumber(usableFloorArea) &&
            isRealNumber(waterConsumptionThisYear)
          ) {
            return (
              (waterConsumptionThisYear *
                LITERS_IN_CUBIC_METER *
                SECONDS_IN_HOUR) /
              usableFloorArea
            );
          }
          return null;
        },
      },
    },
    waterConsumptionLast365Days: {
      asOption: {
        groupLabel: "water-consumption-last",
        shortLabel: "12-mnd-cubic-meter",
        label: "12-mnd-cubic-meter",
      },
      metadata: {
        unit: "m³",
        fractionDigits: 1,
        calculate: (kpiValues) => {
          const { waterConsumptionLast365Days } = kpiValues;
          if (isRealNumber(waterConsumptionLast365Days)) {
            return waterConsumptionLast365Days * SECONDS_IN_HOUR;
          }
          return null;
        },
      },
    },
    waterConsumptionLast365DaysPerLiter: {
      asOption: {
        groupLabel: "water-consumption-last",
        shortLabel: "12-mnd-per-liter",
        label: "12-mnd-per-liter",
      },
      metadata: {
        unit: "l",
        fractionDigits: 1,
        calculate: (kpiValues) => {
          const { waterConsumptionLast365Days } = kpiValues;
          if (isRealNumber(waterConsumptionLast365Days)) {
            return (
              waterConsumptionLast365Days *
              LITERS_IN_CUBIC_METER *
              SECONDS_IN_HOUR
            );
          }
          return null;
        },
      },
    },
    waterConsumptionLast365DaysCubicPerSquareMeter: {
      asOption: {
        groupLabel: "water-consumption-last",
        shortLabel: "12-mnd-cubic-meter-per-square-meter",
        label: "12-mnd-cubic-meter-per-square-meter",
      },
      metadata: {
        unit: "m³/m²",
        fractionDigits: 3,
        calculate: (kpiValues, usableFloorArea) => {
          const { waterConsumptionLast365Days } = kpiValues;
          if (
            isDivisibleNumber(usableFloorArea) &&
            isRealNumber(waterConsumptionLast365Days)
          ) {
            return (
              (waterConsumptionLast365Days * SECONDS_IN_HOUR) / usableFloorArea
            );
          }
          return null;
        },
      },
    },
    waterConsumptionLast365DaysLiterPerSquareMeter: {
      asOption: {
        groupLabel: "water-consumption-last",
        shortLabel: "12-mnd-liter-per-square-meter",
        label: "12-mnd-liter-per-square-meter",
      },
      metadata: {
        unit: "l/m²",
        fractionDigits: 1,
        calculate: (kpiValues, usableFloorArea) => {
          const { waterConsumptionLast365Days } = kpiValues;
          if (
            isDivisibleNumber(usableFloorArea) &&
            isRealNumber(waterConsumptionLast365Days)
          ) {
            return (
              (waterConsumptionLast365Days *
                LITERS_IN_CUBIC_METER *
                SECONDS_IN_HOUR) /
              usableFloorArea
            );
          }
          return null;
        },
      },
    },
    waterMaxHourlyConsumptionLast7DaysLiterPerHour: {
      asOption: {
        groupLabel: "water-max-hourly-consumption-last",
        shortLabel: "7-days-liter-per-hour",
        label: "7-days-liter-per-hour",
      },
      metadata: {
        unit: "l/h",
        fractionDigits: 1,
        calculate: (kpiValues) => {
          const { waterMaxHourlyConsumptionLast7Days } = kpiValues;
          if (isRealNumber(waterMaxHourlyConsumptionLast7Days)) {
            return (
              waterMaxHourlyConsumptionLast7Days *
              LITERS_IN_CUBIC_METER *
              SECONDS_IN_HOUR
            );
          }
          return null;
        },
      },
    },
    waterMaxHourlyConsumptionLast7Days: {
      asOption: {
        groupLabel: "water-max-hourly-consumption-last",
        shortLabel: "7-days-cubic-meter-per-hour",
        label: "7-days-cubic-meter-per-hour",
      },
      metadata: {
        unit: "m³/h",
        fractionDigits: 1,
        calculate: (kpiValues) => {
          const { waterMaxHourlyConsumptionLast7Days } = kpiValues;
          if (isRealNumber(waterMaxHourlyConsumptionLast7Days)) {
            return waterMaxHourlyConsumptionLast7Days * SECONDS_IN_HOUR;
          }
          return null;
        },
      },
    },
  };

export const buildingActivityKPIConfiguration: Record<
  BuildingActivityKPI,
  KPIConfiguration
> = {
  motion: {
    asOption: {
      label: "building-activity",
    },
    metadata: {
      unit: "%",
      fractionDigits: 1,
      calculate: (kpiValues) => getFieldValue(kpiValues, "motion"),
    },
  },
};

export const buildingArealKPIConfiguration: Record<
  BuildingArealKPI,
  KPIConfiguration
> = {
  area: {
    asOption: {
      label: "area",
    },
    metadata: {
      unit: "m²",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "area"),
    },
  },
};

export const weatherKPIConfiguration: Record<WeatherKPI, KPIConfiguration> = {
  temperature: {
    asOption: {
      label: "outside-temperature",
    },
    metadata: {
      unit: "°C",
      fractionDigits: 1,
      calculate: (kpiValues) => getFieldValue(kpiValues, "temperature"),
    },
  },
  maxWindSpeed: {
    asOption: {
      label: "max-wind",
    },
    metadata: {
      unit: "m/s",
      fractionDigits: 1,
      calculate: (kpiValues) => getFieldValue(kpiValues, "maxWindSpeed"),
    },
  },
  relativeHumidity: {
    asOption: {
      label: "humidity",
    },
    metadata: {
      unit: "%",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "relativeHumidity"),
    },
  },
};

export const alarmKPIConfiguration: Record<
  AlarmKPI,
  Omit<KPIConfiguration, "metadata">
> = {
  alarms: {
    asOption: {
      label: "alarm",
    },
  },
  errorAlarms: {
    asOption: {
      label: "error-alarm",
    },
  },
  warningAlarms: {
    asOption: {
      label: "warning-alarm",
    },
  },
  infoAlarms: {
    asOption: {
      label: "info-alarm",
    },
  },
};

export const certificationKPIConfiguration: Record<
  CertificationKPI,
  Omit<KPIConfiguration, "metadata">
> = {
  certifications: {
    asOption: {
      label: "energy-label",
    },
  },
  "WELL Certified™ Core": {
    asOption: {
      label: "well-certified-core",
    },
  },
  "WELL Certified™": {
    asOption: {
      label: "well-certified",
    },
  },
  ISO14001: {
    asOption: {
      label: "iso14001",
    },
  },
  Miljøfyrtårn: {
    asOption: {
      label: "miljofyrtarn",
    },
  },
  BREEAM: {
    asOption: {
      label: "breem",
    },
  },
  "BREEAM In-Use": {
    asOption: {
      label: "breem-in-use",
    },
  },
};

export const tasksKPIConfiguration: Record<TasksKPIs, KPIConfiguration> = {
  openTasks: {
    asOption: {
      label: "open-tasks",
    },
    metadata: {
      unit: "",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "openTasks"),
    },
  },
  overdueTasks: {
    asOption: {
      label: "due-tasks",
    },
    metadata: {
      unit: "",
      fractionDigits: 0,
      calculate: (kpiValues) => getFieldValue(kpiValues, "overdueTasks"),
    },
  },
};
