import { useState, useEffect, ChangeEvent, useMemo } from "react";
import { App, Button, Space, Input } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import { useTranslations } from "@properate/translations";
import { Datapoints, Timeseries } from "@cognite/sdk";
import { PageHeader } from "@ant-design/pro-layout";
import { sortString, ToggleSidebarButton } from "@properate/ui";
import { useUser } from "@properate/auth";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { CompactContent } from "@/components/CompactContent";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import GraphModal from "@/pages/common/SchemaGraph";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { GraphModalData } from "@/pages/calculationFlow/types";
import { AnalysisCreateRedirectButton } from "@/components/AnalysisCreateRedirectButton/AnalysisCreateRedirectButton";
import { getAnomalyProperties } from "../../../eepApi";

const ESTIMATED_TABLE_HEADER_HEIGHT = 45;

export function AnomaliesTable() {
  const { notification } = App.useApp();
  const user = useUser();
  const t = useTranslations();

  const { height: windowHeight } = useWindowSize();
  const building = useCurrentBuilding();
  const currentBuildingId = building.id;
  const { client } = useCogniteClient();
  const [loading, setLoading] = useState(true);
  const [anomalyProperties, setAnomalyProperties] = useState<AnomalyProperty[]>(
    [],
  );
  const [query, setQuery] = useState("");
  const [filteredAnomalyProperties, setFilteredAnomalyProperties] = useState<
    AnomalyProperty[]
  >([]);
  const [auxiliaryTimeseriesList, setAuxiliaryTimeseriesList] = useState<
    Timeseries[]
  >([]);
  const [healthTimeseriesList, setHealthTimeseriesList] = useState<
    Timeseries[]
  >([]);
  const [currentHealth, setCurrentHealth] = useState<Datapoints[]>([]);
  const [showTimeseriesData, setShowTimeseriesData] =
    useState<GraphModalData | null>(null);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setQuery(event.target.value);

  const searchWords = useMemo(
    () => query.split(" ").filter((word) => word !== ""),
    [query],
  );

  useEffect(() => {
    async function fetchAnomalyProperties() {
      setLoading(true);
      try {
        if (currentBuildingId) {
          const aps = await getAnomalyProperties(Number(currentBuildingId));
          setAnomalyProperties(aps);

          const healthEids = [
            ...new Set(aps.map((ap) => `${ap.system}-XD000`)),
          ].map((externalId) => {
            return { externalId };
          });

          const healthTs =
            healthEids.length > 0
              ? await client.timeseries.retrieve(healthEids)
              : [];
          setHealthTimeseriesList(healthTs);

          const latestHealth =
            healthEids.length > 0
              ? await client.datapoints.retrieveLatest(healthEids)
              : [];
          setCurrentHealth(latestHealth);

          const auxiliaryEids = [
            ...new Set(aps.map((ap) => ap.metadata.coupled_features).flat()),
          ].map((externalId) => {
            return { externalId };
          });
          const auxTs =
            auxiliaryEids.length > 0
              ? await client.timeseries.retrieve(auxiliaryEids)
              : [];
          setAuxiliaryTimeseriesList(auxTs);
        }
      } catch {
        notification.error({
          message: t("anomaly.cant-load-anomaly-properties"),
        });
      } finally {
        setLoading(false);
      }
    }
    fetchAnomalyProperties();
  }, [
    client.datapoints,
    client.timeseries,
    currentBuildingId,
    notification,
    t,
  ]);

  useEffect(() => {
    if (searchWords.length === 0)
      setFilteredAnomalyProperties(anomalyProperties);
    else {
      setFilteredAnomalyProperties(
        anomalyProperties.filter((anomalyProperty) => {
          return searchWords.some(
            (query) =>
              anomalyProperty.system
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              anomalyProperty.algorithm
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              anomalyProperty.metadata.coupled_features.some(
                (externalId) =>
                  externalId?.toLowerCase().includes(query.toLowerCase()),
              ),
          );
        }),
      );
    }
  }, [anomalyProperties, searchWords]);

  return (
    <>
      <PageHeader
        title={t("anomaly.title")}
        extra={
          <Space direction="horizontal">
            <Input
              placeholder={t("anomaly.search-placeholder")}
              onChange={handleSearch}
              allowClear
            />
            <ToggleSidebarButton
              hiddenWhenSidebarVisible
              sidebarHiddenContent={t("notes.show-notes-button")}
            />
          </Space>
        }
      />
      <CompactContent>
        <TableWithoutDefaultSort
          pagination={false}
          dataSource={filteredAnomalyProperties}
          rowKey="id"
          scroll={{
            y:
              windowHeight - PAGE_LAYOUT_HEIGHT - ESTIMATED_TABLE_HEADER_HEIGHT,
            x: "100%",
          }}
          columns={[
            {
              title: t("anomaly.table.system"),
              width: 200,
              sorter: ({ system: systemOne }, { system: systemTwo }) =>
                sortString(systemOne, systemTwo),
              render: (_, { system }) => (
                <ProperateHighlighter
                  searchWords={searchWords}
                  textToHighlight={system}
                  title={system}
                />
              ),
            },
            {
              title: t("anomaly.table.algorithm"),
              width: 50,
              sorter: (
                { algorithm: algorithmnOne },
                { algorithm: algorithmTwo },
              ) => sortString(algorithmnOne, algorithmTwo),
              render: (_, { algorithm }) => (
                <ProperateHighlighter
                  searchWords={searchWords}
                  textToHighlight={algorithm}
                  title={algorithm}
                />
              ),
            },
            {
              title: t("anomaly.table.monitoredTimeseries"),
              key: "metadata",
              align: "center",
              width: 250,
              render: (_, { system, metadata }) => {
                const text = metadata.coupled_features
                  .map((eid) => eid.substring(system.length + 1))
                  .toString();

                return (
                  <ProperateHighlighter
                    searchWords={searchWords}
                    textToHighlight={text}
                    title={text}
                  />
                );
              },
            },
            {
              title: t("anomaly.table.currentHealth"),
              key: "status",
              align: "center",
              width: 50,
              render: (_, { system }) => {
                const externalId = `${system}-XD000`;
                const dp = currentHealth.find(
                  (dps) => dps.externalId === externalId,
                );
                return <>{dp?.datapoints[0].value || "-"}</>;
              },
            },
            {
              title: t("calculation-flow.table.actions"),
              width: 200,
              render: (_, { system, metadata }) => {
                const externalId = `${system}-XD000`;
                const healthTs = healthTimeseriesList.find(
                  (ts) => ts.externalId === externalId,
                );
                const auxTs = auxiliaryTimeseriesList.filter(
                  (ts) =>
                    ts.externalId !== undefined &&
                    metadata.coupled_features.includes(ts.externalId),
                );

                return (
                  <Space>
                    <Button
                      icon={<LineChartOutlined />}
                      disabled={healthTs === undefined}
                      onClick={async (event) => {
                        event.stopPropagation();
                        if (healthTs !== undefined)
                          setShowTimeseriesData({ id: healthTs.id, unit: "" });
                      }}
                    />
                    {healthTs && (
                      <AnalysisCreateRedirectButton
                        buildingId={building.id}
                        timeseries={healthTs}
                        auxiliaryTimeseries={auxTs}
                        userEmail={user.email}
                      />
                    )}
                  </Space>
                );
              },
            },
          ]}
          loading={loading}
        />
        {building.dataSetId && (
          <NotesSidebar
            noteSource={NoteSource.WEB_VIRTUAL_SENSORS}
            assetFilterMode={NotesAssetFilterMode.AssetList}
            idSet={
              new Set(
                healthTimeseriesList
                  .filter((ts) => ts.assetId !== undefined)
                  .map((ts) => ts.assetId as number),
              )
            }
            buildings={[{ id: building.dataSetId, name: building.name }]}
          />
        )}
      </CompactContent>
      {showTimeseriesData && (
        <GraphModal
          timeseriesInfo={showTimeseriesData}
          setTimeseriesInfo={setShowTimeseriesData}
          hide={() => setShowTimeseriesData(null)}
          expanded
          editable
          showDocuments={false}
          buildingId={currentBuildingId}
        />
      )}
    </>
  );
}
